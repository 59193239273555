import React, { useEffect, useRef, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { useWeb3React } from "@web3-react/core";
import { Button, Col, Divider, Input, Row, Select, Space, Spin, Switch, Tabs, Tooltip } from "antd";
import BigNumber from "bignumber.js";
import { Form, Formik } from "formik";
import moment from "moment";
import Web3 from "web3";
import { CSVLink } from "react-csv";

import { FORMAT_DATE_TIME, NFT_FORMAT, STAKING_MECHANISM_TYPE, TYPE_INPUT, TYPE_OF_ANT_DESIGN } from "common/constant";
import FormItem from "components/FormItem";
import showMessage from "components/Message";
import ModalComponent from "components/Modal";
import ModalConfirm from "components/ModalConfirm";
import NumberFormat from "components/NumberFormat";
import TableComponent from "components/Table";
import { selectIsLoading, selectListRewardActivity, selectTotalNft } from "redux/nft/selectors";
import { getListNftRewardsStart, getRewardActivityStart } from "redux/nft/slice";
import { handleSetNetworkPool, poolDeleteRequest, poolDetailRequest, poolUpdateStatusRequest } from "redux/pool/slice";
import { checkNftBalanceApi, createNftTransaction, getListNftRewardsApi, updateNftTransaction } from "services/nft";
import { getListUserByPoolId } from "services/pool";
import { removeLetter } from "utils";
import BaseWalletService from "utils/baseWallet";
import { useAppSelector } from "utils/customHooks";

import StopPool from "../Action/StopPool";
import { columnsPoolNftDetail, columnsRewardsActivity } from "../column";

import GradientLoading from "resources/images/gradient-loader.png";
import IconPop from "resources/images/IconPop";
import IconQuestion from "resources/images/IconQuestion";
import IconRefresh from "resources/images/IconRefresh";
import IconSearch from "resources/images/IconSearch";
import IconViewTransaction from "resources/images/IconViewTransaction";
import { requestSupportNetwork } from "utils/setupNetwork";

const { Option } = Select;
const { TabPane } = Tabs;

const POOL_DETAIL_TABS = {
  NFT_REWARD: "1",
  REWARD_ACTIVITY: "2",
};

const PoolDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { library, account, chainId } = useWeb3React();
  const { t } = useTranslation();
  const wallet = new BaseWalletService().getInstance();

  const [visible, setVisible] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [listUser, setListUser] = useState(``);
  const [isWithdrawModalVisible, setIsWithdrawModalVisible] = useState(false);
  const [isDepositModalVisible, setIsDepositModalVisible] = useState(false);
  const [selectedNft, setSelectedNft] = useState({
    name: "",
    imageUrl: "",
    address: "",
    tokenId: "",
    type: null,
    id: "",
  });
  const [isLoadingVisible, setIsLoadingVisible] = useState(false);
  const [mapListNftReward, setMapNftListReward] = useState([]);
  const [nftBalance, setNftBalance] = useState(0);
  const [searchData, setSearchData] = useState({
    pageSize: -1,
    type: null,
    keyword: "",
    sortField: null,
    sortType: 1,
    inPool: null,
  });
  const [initialValue, setInitialValue] = useState({});
  const [withdrawBalance, setWithdrawBalance] = useState(0);
  const [transactionType, setTransactionType] = useState(1);
  const [tabType, setTabType] = useState(POOL_DETAIL_TABS.NFT_REWARD);
  const [isAdmin, setIsAdmin] = useState(false);
  const [listNftRewards, setListNftRewards] = useState([]);
  const { id } = useParams();

  const downloadRef = useRef(null);

  const poolDetail = useAppSelector((state) => state.pool.poolDetail);
  const loadingPoolDetail = useAppSelector((state) => state.pool.loadingPoolDetail);
  const total = useAppSelector(selectTotalNft);
  const chains = useAppSelector((state) => state.auth.chains);

  const poolAddress = useMemo(() => {
    if (poolDetail.networkType) {
      const networkSelected = chains.find((item) => item.chainId === poolDetail.networkType);
      return networkSelected?.poolContractAddress;
    }
    return "";
  }, [chains, poolDetail]);

  // const listNftRewards = useAppSelector(selectListNftInPool);
  const listRewardActivity = useSelector(selectListRewardActivity);
  const isLoading = useAppSelector(selectIsLoading);
  const [statusChangeShow, setStatusChangeShow] = useState({ id: "", value: false });
  const [visibleCofirmChangeShow, setVisibleCofirmChangeShow] = useState(false);
  const [visibleSubmitting, setVisibleSubmitting] = useState(false);
  const loadingUpdateStatus = useAppSelector((state) => state.pool.loadingUpdateStatus);
  const listChain = useAppSelector((state) => state.auth.chains);

  const handleConfirmChangeShowPool = () => {
    dispatch(poolUpdateStatusRequest({ id: statusChangeShow.id, callbackSuccess: callbackShowHideSuccess }));
  };
  const callbackShowHideSuccess = () => {
    setVisibleCofirmChangeShow(!visibleCofirmChangeShow);
    setIsLoadingVisible(false);
    dispatch(poolDetailRequest({ data: { id } }));
    handleGetListNftRewards(id);
  };

  const handleGetListNftRewards = async (poolId = null) => {
    const { keyword, type, pageSize, sortField, sortType } = searchData;
    if (poolId) {
      const res = await getListNftRewardsApi({
        keyword: keyword.trim(),
        type,
        limit: pageSize,
        sortField: sortField || "tier",
        sortType,
        inPool: poolId,
      });
      if (!res?.meta?.code) {
        const { items } = res;
        setListNftRewards(items);
      }
    }
  };

  const handleGetListRewardActivity = (poolId = null) => {
    const { keyword, pageSize, sortField, sortType } = searchData;
    if (poolId) {
      dispatch(
        getRewardActivityStart({
          keyword: keyword.trim(),
          limit: pageSize,
          sortField,
          sortType,
          poolId,
        })
      );
    }
  };

  useEffect(() => {
    setSearchData({ ...searchData, inPool: id });
    dispatch(poolDetailRequest({ data: { id } }));
  }, [id]);

  useEffect(() => {
    if (listUser) {
      downloadRef.current.link.click();
    }
  }, [id, listUser]);

  useEffect(() => {
    if (id) {
      if (tabType === POOL_DETAIL_TABS.NFT_REWARD) handleGetListNftRewards(id);
      else handleGetListRewardActivity(id);
    }
  }, [id, searchData.pageSize, searchData.type, searchData.sortField, searchData.sortType, tabType]);

  useEffect(() => {
    if (tabType === POOL_DETAIL_TABS.REWARD_ACTIVITY) {
      setSearchData({
        pageSize: -1,
        keyword: "",
        sortField: null,
        sortType: 1,
        poolId: null,
      });
    } else
      setSearchData({
        pageSize: -1,
        type: null,
        keyword: "",
        sortField: null,
        sortType: 1,
        inPool: null,
      });
  }, [id, tabType]);

  useEffect(() => {
    const getIsAdmin = async () => {
      const isAdminValue = await wallet.isAdmin({ poolAddress: poolAddress, library, account });
      setIsAdmin(isAdminValue);
    };
    if (account && library && poolAddress) {
      getIsAdmin();
    }
  }, [account, library, poolAddress]);

  useEffect(() => {
    if (poolDetail.networkType !== chainId) {
      dispatch(handleSetNetworkPool({ network: poolDetail.networkType }));
      switchNetwork();
    }

    return () => {
      dispatch(handleSetNetworkPool({ network: "" }));
    };
  }, [chainId, poolDetail.networkType]);

  const renderListRewardActivity = () => {
    return listRewardActivity.map((item, index) => ({
      ...item,
      index: index + 1,
      userAddress: item?.user?.networkAddress,
    }));
  };

  const renderListNftRewards = async (listNftRewards) => {
    let newListNftRewards = [];
    if (listNftRewards.length > 0 && poolAddress)
      newListNftRewards = await Promise.all(
        listNftRewards.map(async (item, index) => {
          const isApproved = await wallet.isApprovalForAll({
            contractAddress: item.contractAddress,
            currentAccount: account,
            library,
            poolAddress: poolAddress,
            callback: () => {},
          });
          return {
            ...item,
            index: index + 1,
            totalDeposited: item?.detail?.totalDeposited,
            totalClaim: item?.detail?.totalClaim,
            tier: item?.detail?.tier,
            isApproved,
          };
        })
      );
    setMapNftListReward(newListNftRewards);
  };

  useEffect(() => {
    if (account && poolAddress) {
      renderListNftRewards(listNftRewards);
    }
  }, [listNftRewards, account, poolAddress, chainId]);

  useEffect(() => {
    const getNftBalance = async () => {
      if (selectedNft.address && selectedNft.tokenId && selectedNft.type && account) {
        if (selectedNft.type === NFT_FORMAT.ERC_721) {
          const balance = await wallet.getNftERC721Balance(library, selectedNft.address, selectedNft.tokenId, account);
          setNftBalance(balance);
        } else {
          const balance = await wallet.getNftERC1155Balance(library, selectedNft.address, selectedNft.tokenId, account);
          setNftBalance(balance);
        }
      }
    };
    getNftBalance();
  }, [selectedNft, account]);

  useEffect(() => {
    if (isWithdrawModalVisible) {
      setInitialValue({
        address: selectedNft.address,
        tokenId: selectedNft.tokenId,
        recipientAddress: "",
        amount: null,
      });
    } else
      setInitialValue({
        amount: null,
      });
  }, [selectedNft]);

  const networkSelected = useMemo(() => {
    const chain = chains.find((item) => item.chainId === poolDetail.networkType);

    return chain;
  }, [chains, poolDetail]);

  const handleApproveNft = async (contractAddress) => {
    if (isAdmin && poolAddress) {
      setVisibleSubmitting(true);
      await wallet.setApprovalForAll({
        contractAddress,
        currentAccount: account,
        library,
        poolAddress: poolAddress,
        callbackSuccess: () => {
          setVisibleSubmitting(false);
          showMessage(TYPE_OF_ANT_DESIGN.SUCCESS, "Approve NFT successfully");
          renderListNftRewards(listNftRewards);
        },
        callbackError: () => {
          setVisibleSubmitting(false);
          showMessage(TYPE_OF_ANT_DESIGN.ERROR, "Fail to approve NFT");
        },
      });
    } else showMessage(TYPE_OF_ANT_DESIGN.ERROR, "message.notAdmin");
  };

  const renderDuration = (value) => {
    let data = "Flexible";
    if (value) data = `${value} days`;
    return data;
  };

  const rendertagStatus = () => {
    let color = "";
    let text = "Draft";
    if (poolDetail.isDraft) {
      color = "#A0A3BD";
      text = "Draft";
    } else if (poolDetail.isPublish && poolDetail.status === 1) {
      color = "#009332";
      text = "Live";
    } else {
      if (poolDetail.status === 3) {
        color = "#de4b12";
        text = "Finished";
      } else {
        color = "#FED42A";
        text = "Waiting for public";
      }
    }

    return { color, text };
  };

  const renderPoolMechanism = () => {
    if (poolDetail.modelType === STAKING_MECHANISM_TYPE.LINEAR) {
      return "Linear";
    } else return "Allocation";
  };

  const redirectUpdatePool = () => {
    history.push({ pathname: `/pool-update/${poolDetail?._id}`, state: { id: poolDetail?._id } });
  };

  const handleDeletePool = () => {
    setVisible(true);
  };

  const handleDelete = () => {
    dispatch(
      poolDeleteRequest({
        id,
        callbackSuccess: () => {
          showMessage(TYPE_OF_ANT_DESIGN.SUCCESS, "message.S5");
          history.push("/pool");
        },
      })
    );
  };

  const typePool = (poolDetail) => {
    const data = poolDetail?.currencyName;
    return data || "N/A";
  };

  const onDownload = async () => {
    setIsDownloading(true);
    const res = await getListUserByPoolId(id);
    setIsDownloading(false);
    setListUser(`${res}`);
  };

  const toggleWithdrawModal = ({ name, imageUrl, address, tokenId, type, id, balance }) => {
    if (!isWithdrawModalVisible) {
      setIsWithdrawModalVisible(!isWithdrawModalVisible);
      setSelectedNft({ name, imageUrl, address, tokenId, type, id });
      setWithdrawBalance(balance);
    } else {
      setSelectedNft({ name: "", imageUrl: "", address: "", tokenId: "", type: null, id: "" });
      setIsWithdrawModalVisible(!isWithdrawModalVisible);
    }
  };

  const toggleDepositModal = ({ name, imageUrl, address, tokenId, type, id }) => {
    if (!isDepositModalVisible) {
      setIsDepositModalVisible(!isDepositModalVisible);
      setSelectedNft({ name, imageUrl, address, tokenId, type, id });
    } else {
      setSelectedNft({ name: "", imageUrl: "", address: "", tokenId: "", type: null, id: "" });
      setIsDepositModalVisible(!isDepositModalVisible);
    }
  };

  const validateAddress = (value) => {
    let error;
    if (!value) error = t("message.E2");
    else {
      const isAddress = Web3.utils.isAddress(value);
      if (!isAddress) error = t("message.E2_1");
    }
    return error;
  };

  const validateAmount = (value) => {
    let error;
    if (!value) error = t("message.E2");
    else if (parseInt(value) === 0) error = t("message.E6_1");
    else {
      if (isDepositModalVisible && parseInt(value) > nftBalance) error = t("message.E7_1", { balance: nftBalance });
      if (isWithdrawModalVisible && parseInt(value) > withdrawBalance)
        error = t("message.E7_1", { balance: withdrawBalance });
    }
    return error;
  };

  const renderRewardFund = (rewardFund) => {
    return new BigNumber(rewardFund).dividedBy(Math.pow(10, 18)).toNumber();
  };

  const onGoBack = () => {
    history.push("/pool");
  };

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      if (tabType === POOL_DETAIL_TABS.NFT_REWARD) handleGetListNftRewards(id);
      else handleGetListRewardActivity(id);
    }
  };

  const onChangeInput = (e) => {
    setSearchData({ ...searchData, keyword: e.target.value });
  };

  const onBlurInput = (e) => {
    setSearchData({ ...searchData, keyword: e.target.value.trim() });
  };

  const onClickSearch = () => {
    if (tabType === POOL_DETAIL_TABS.NFT_REWARD) handleGetListNftRewards(id);
    else handleGetListRewardActivity(id);
  };

  const onChangeSelectFormat = (value) => {
    setSearchData({ ...searchData, type: value });
  };

  const onChangeTable = (pagination, filter, sorter, extra) => {
    let { order, field } = sorter;

    let data = "";
    if (order) {
      switch (order) {
        case "ascend":
          data = 1;
          break;
        case "descend":
          data = -1;
          break;
      }
    } else data = "";
    setSearchData({ ...searchData, sortField: field, sortType: data });
  };

  const onChangeTab = (value) => {
    setTabType(value);
  };

  const setMaxNftBalance = (setFieldValue) => {
    if (isDepositModalVisible) {
      setFieldValue("amount", nftBalance);
    } else setFieldValue("amount", withdrawBalance);
  };

  const onChangeAmount = (e, setFieldValue) => {
    // setFieldValue("amount", removeLetter(e.target.value));
    if (isDepositModalVisible) {
      e.target.value.trim() > nftBalance
        ? setFieldValue("amount", nftBalance)
        : setFieldValue("amount", removeLetter(e.target.value));
    } else {
      e.target.value.trim() > withdrawBalance
        ? setFieldValue("amount", withdrawBalance)
        : setFieldValue("amount", removeLetter(e.target.value));
    }
  };

  const handleBlurAmount = (e, setFieldValue) => {
    if (isDepositModalVisible) {
      e.target.value.trim() > nftBalance
        ? setFieldValue("amount", nftBalance)
        : setFieldValue("amount", removeLetter(e.target.value));
    } else {
      e.target.value.trim() > withdrawBalance
        ? setFieldValue("amount", withdrawBalance)
        : setFieldValue("amount", removeLetter(e.target.value));
    }
  };

  const onResetSearch = () => {
    setSearchData({
      ...searchData,
      type: null,
      keyword: "",
    });

    if (tabType === POOL_DETAIL_TABS.NFT_REWARD) {
      if (!searchData.type)
        dispatch(
          getListNftRewardsStart({
            keyword: "",
            type: searchData.type,
            limit: searchData.pageSize,
            sortField: searchData.sortField,
            sortType: searchData.sortType,
            inPool: id,
          })
        );
    } else {
      dispatch(
        getRewardActivityStart({
          keyword: "",
          limit: searchData.pageSize,
          sortField: searchData.sortField,
          sortType: searchData.sortType,
          poolId: id,
        })
      );
    }
  };

  const callbackSuccess = (type) => {
    setIsLoadingVisible(false);
    if (type === 1) {
      showMessage(TYPE_OF_ANT_DESIGN.SUCCESS, "message.S3");
    } else showMessage(TYPE_OF_ANT_DESIGN.SUCCESS, "message.S4");
    dispatch(poolDetailRequest({ data: { id } }));
    handleGetListNftRewards(id);
  };
  const callbackProcessing = async (transactionId, txid) => {
    const res = await updateNftTransaction({
      transactionId,
      txid,
    });
  };

  const switchNetwork = async () => {
    const networkSupports = chains.reduce(
      (object, item) => ({
        ...object,
        [item.chainId]: {
          chainId: `0x${item.chainId.toString(16)}`,
          chainName: item?.chainName,
          nativeCurrency: item?.nativeCurrency,
          rpcUrls: item?.rpcUrls,
          blockExplorerUrls: [item?.blockExplorerUrls],
        },
      }),
      {}
    );
    // check user choose which network then use id network.
    await requestSupportNetwork("metamask", networkSupports, poolDetail.networkType);
  };

  const handleSubmit = async (values) => {
    setIsLoadingVisible(true);
    setIsWithdrawModalVisible(false);
    setIsDepositModalVisible(false);
    if (isDepositModalVisible) {
      setTransactionType(1);
      try {
        const res = await checkNftBalanceApi({
          address: account,
          nftId: selectedNft.id,
          poolId: id,
          type: 1,
          amount: parseInt(values.amount),
        });
        if (!res?.meta?.code) {
          const createTxRes = await createNftTransaction({
            address: account,
            nftId: selectedNft.id,
            poolId: id,
            type: 1,
            amount: parseInt(values.amount),
            networkType: parseInt(poolDetail?.networkType),
          });
          if (!createTxRes?.meta?.code) {
            if (selectedNft.type === NFT_FORMAT.ERC_721) {
              await wallet.depositNFT721({
                library,
                contractAddress: selectedNft.address,
                account,
                tokenId: selectedNft.tokenId,
                poolId: id,
                internalTx: createTxRes._id,
                poolAddress,
                callbackSuccess: () => callbackSuccess(1),
                callbackProcessing: (hash) => callbackProcessing(createTxRes._id, hash),
                callbackError: () => {
                  setIsDepositModalVisible(false);
                  setIsLoadingVisible(false);
                  showMessage(TYPE_OF_ANT_DESIGN.ERROR, "message.E8_1");
                },
              });
            } else {
              await wallet.depositNFT1155({
                library,
                contractAddress: selectedNft.address,
                account,
                tokenId: selectedNft.tokenId,
                poolId: id,
                amount: parseInt(values.amount),
                internalTx: createTxRes._id,
                poolAddress,
                callbackSuccess: () => callbackSuccess(1),
                callbackProcessing: (hash) => callbackProcessing(createTxRes._id, hash),
                callbackError: () => {
                  setIsDepositModalVisible(false);
                  setIsLoadingVisible(false);
                  showMessage(TYPE_OF_ANT_DESIGN.ERROR, "message.E8_1");
                },
              });
            }
          } else {
            setIsLoadingVisible(false);
          }
        } else {
          setIsLoadingVisible(false);
        }
      } catch (error) {
        showMessage(TYPE_OF_ANT_DESIGN.ERROR, "message.E8_1");
      }
    } else {
      setTransactionType(2);
      try {
        const res = await checkNftBalanceApi({
          address: account,
          nftId: selectedNft.id,
          poolId: id,
          type: 2,
          amount: parseInt(values.amount),
        });
        if (!res?.meta?.code) {
          const createTxRes = await createNftTransaction({
            address: account,
            nftId: selectedNft.id,
            poolId: id,
            type: 2,
            amount: parseInt(values.amount),
            networkType: parseInt(poolDetail?.networkType),
          });
          if (!createTxRes?.meta?.code) {
            if (selectedNft.type === NFT_FORMAT.ERC_721) {
              await wallet.withdrawNFT721({
                library,
                contractAddress: selectedNft.address,
                account,
                tokenId: selectedNft.tokenId,
                poolId: id,
                internalTx: createTxRes._id,
                recipientAddress: values.recipientAddress,
                poolAddress,
                callbackSuccess: () => callbackSuccess(2),
                callbackProcessing: (hash) => callbackProcessing(createTxRes._id, hash),
                callbackError: () => {
                  setIsWithdrawModalVisible(false);
                  setIsLoadingVisible(false);
                  showMessage(TYPE_OF_ANT_DESIGN.ERROR, "message.E9_1");
                },
              });
            } else {
              await wallet.withdrawNFT1155({
                library,
                contractAddress: selectedNft.address,
                account,
                tokenId: selectedNft.tokenId,
                poolId: id,
                amount: parseInt(values.amount),
                internalTx: createTxRes._id,
                recipientAddress: values.recipientAddress,
                poolAddress,
                callbackSuccess: () => callbackSuccess(2),
                callbackProcessing: (hash) => callbackProcessing(createTxRes._id, hash),
                callbackError: () => {
                  setIsWithdrawModalVisible(false);
                  setIsLoadingVisible(false);
                  showMessage(TYPE_OF_ANT_DESIGN.ERROR, "message.E9_1");
                },
              });
            }
          } else {
            setIsLoadingVisible(false);
          }
        } else {
          setIsLoadingVisible(false);
        }
      } catch (error) {
        showMessage(TYPE_OF_ANT_DESIGN.ERROR, "message.E9_1");
      }
    }
  };

  const handleChangeShowPool = (id, check) => {
    setStatusChangeShow({ id, value: check });
    setVisibleCofirmChangeShow(!visibleCofirmChangeShow);
  };

  const renderNetwork = useMemo(() => {
    const currentNetwork = listChain?.find((chain) => chain?.chainId === poolDetail?.networkType);
    return currentNetwork ? (
      <>
        <img src={require(`resources/svg/${currentNetwork?.icon}`)} />
        &nbsp;
        <b className="mgb-0px">{currentNetwork?.chainName}</b>
      </>
    ) : (
      ""
    );
  }, [listChain, poolDetail]);

  return (
    <div className={`pool-detail`}>
      <div className="pool-detail__header">
        <div className="pool-detail__header__left">
          <IconPop className="cursor-pointer" onClick={onGoBack} />
          <h2 className="pool-detail__title">Pool detail</h2>
        </div>
        <div className="pool-detail__header__right">
          <div className="switch-button">
            <p>Hide/Show Pool</p>
            <Tooltip title={poolDetail.isDraft ? "Draft" : poolDetail.isShow ? "Public" : "Hidden"}>
              <Switch
                disabled={poolDetail.isDraft}
                checked={poolDetail.isShow}
                onChange={(e) => handleChangeShowPool(poolDetail?._id, e)}
              />
            </Tooltip>
          </div>
          <CSVLink filename={`${poolDetail.name}.csv`} data={listUser} ref={downloadRef} />
          <Button className="download-button" onClick={onDownload} loading={isDownloading}>
            Download list users
          </Button>
          {poolDetail.status !== 3 && (
            <Button className="edit-pool" onClick={redirectUpdatePool}>
              Edit
            </Button>
          )}
          {poolDetail.isDraft && (
            <Button className="delete-pool" onClick={handleDeletePool}>
              Delete
            </Button>
          )}
        </div>
      </div>
      {loadingPoolDetail && (
        <div className="loading-pool-detail">
          <Spin size="large" />
        </div>
      )}
      <div className="pool-detail__body">
        <div className="pool-name">
          <Tooltip title={poolDetail.name} placement="topLeft">
            <p>{poolDetail.name}</p>
          </Tooltip>{" "}
          <div className="status">
            Pool Mechanism:&nbsp;{" "}
            <p>
              <b>{renderPoolMechanism()}</b>
            </p>
          </div>
          <div className="status">
            Status:&nbsp; <p style={{ color: rendertagStatus().color }}> {rendertagStatus().text}</p>
          </div>
          <div className="status">Network:&nbsp; {renderNetwork}</div>
        </div>
        <Divider />
        <div className="date-wrap">
          <p className="date-property">
            <span className="title">Created date: </span>{" "}
            {poolDetail && moment(poolDetail.createdAt).format(FORMAT_DATE_TIME)}
          </p>
          <p className="date-property">
            <span className="title">Start date: </span>{" "}
            {poolDetail.isDraft ? "N/A" : poolDetail && moment(poolDetail.startDate).format(FORMAT_DATE_TIME)}
          </p>
          <p className="date-property">
            <span className="title">End date: </span>
            {poolDetail && moment(poolDetail.endDate).format(FORMAT_DATE_TIME)}
          </p>
          <p className="date-property">
            <span className="title">End for staking: </span>
            {poolDetail && moment(poolDetail.endStakeDate).format(FORMAT_DATE_TIME)}
          </p>
        </div>
        <Divider />

        <div className="info-wrap">
          <div className="item">
            <label>Stake Token</label>
            <p className="content">{typePool(poolDetail)}</p>
          </div>
          <div className="duration item">
            <label>Duration</label>
            <p className="content">{poolDetail && renderDuration(poolDetail.duration)}</p>
          </div>
          <div className="item">
            <label>Total Rewards Funds</label>
            <p className="content reward-usd">
              {poolDetail && (
                <NumberFormat
                  thousandSeparator
                  value={poolDetail.totalRewardFundUsd}
                  displayType="text"
                  prefix={"$"}
                  decimalScale={2}
                />
              )}
            </p>
            <p className="content">
              {poolDetail && (
                <NumberFormat
                  thousandSeparator
                  value={renderRewardFund(poolDetail.totalRewardFund)}
                  displayType="text"
                  decimalScale={2}
                  suffix={` ${poolDetail.currencyNameReward}`}
                />
              )}
            </p>
          </div>
          <div className="item">
            <label>Total Rewards Claimed</label>
            <p className="content reward-usd">
              {poolDetail && (
                <NumberFormat
                  thousandSeparator
                  value={poolDetail.totalRewardClaimedUsd}
                  displayType="text"
                  prefix={"$"}
                  decimalScale={2}
                />
              )}
            </p>
            <p className="content">
              {poolDetail && (
                <NumberFormat
                  thousandSeparator
                  value={renderRewardFund(poolDetail.totalRewardClaimed)}
                  displayType="text"
                  decimalScale={2}
                  suffix={` ${poolDetail.currencyNameReward}`}
                />
              )}
            </p>
          </div>
          <div className="item">
            <label>Available Rewards Funds</label>
            <p className="content reward-usd">
              {poolDetail && (
                <NumberFormat
                  thousandSeparator
                  value={poolDetail.availableRewardFundUsd}
                  displayType="text"
                  prefix={"$"}
                  decimalScale={2}
                />
              )}
            </p>
            <p className="content">
              {poolDetail && (
                <NumberFormat
                  thousandSeparator
                  value={renderRewardFund(poolDetail.availableRewardFund)}
                  displayType="text"
                  decimalScale={2}
                  suffix={` ${poolDetail.currencyNameReward}`}
                />
              )}
            </p>
          </div>
          <div className="apr item">
            <label>APR</label>
            <p className="content">
              <Tooltip title={`${poolDetail && poolDetail.apr ? poolDetail.apr : 0}%`}>
                {poolDetail && poolDetail.apr ? poolDetail.apr : 0}%
              </Tooltip>
            </p>
          </div>
          <div className="number-of-user item">
            <label>Number of users</label>
            <p className="content">{poolDetail && poolDetail.numberOfUser}</p>
          </div>
          <div className="item">
            <label>Minimum Staking Amount</label>
            <p className="content">
              {poolDetail && (
                <NumberFormat
                  thousandSeparator
                  value={poolDetail.minimumAmountStakeUsd}
                  displayType="text"
                  decimalScale={2}
                  prefix="$"
                />
              )}
            </p>
            <p className="content">
              {poolDetail && (
                <NumberFormat
                  thousandSeparator
                  value={poolDetail.minimumAmountStake}
                  displayType="text"
                  suffix={` ${poolDetail.currencyName}`}
                  decimalScale={2}
                />
              )}
            </p>
          </div>
          <div className="item">
            <label>Maximum Total Value Locked</label>
            <p className="content">
              {poolDetail && (
                <NumberFormat
                  thousandSeparator
                  value={poolDetail.totalMaxLockedUsd}
                  displayType="text"
                  decimalScale={2}
                  prefix="$"
                />
              )}
            </p>
            <p className="content">
              {poolDetail && (
                <NumberFormat
                  thousandSeparator
                  value={poolDetail.totalMaxLocked}
                  displayType="text"
                  suffix={` ${poolDetail.currencyName}`}
                  decimalScale={2}
                />
              )}
            </p>
          </div>
          <div className="item">
            <label>Total Value Locked</label>
            <p className="content">
              {poolDetail && (
                <NumberFormat
                  thousandSeparator
                  value={poolDetail.totalValueLockedUsd}
                  displayType="text"
                  prefix={"$"}
                  decimalScale={2}
                />
              )}
            </p>
            <p className="content">
              {poolDetail && (
                <NumberFormat
                  thousandSeparator
                  value={renderRewardFund(poolDetail.totalValueLocked)}
                  displayType="text"
                  suffix={` ${poolDetail.currencyName}`}
                  decimalScale={2}
                />
              )}
            </p>
          </div>
          {poolDetail?.rewardType === 2 && (
            <>
              <div className="item">
                <label>Total NFT Fund</label>
                <p className="content">{poolDetail?.totalDeposited} NFT</p>
              </div>
              <div className="item">
                <label>Total NFT Claimed</label>
                <p className="content">{poolDetail?.totalNftClaimed} NFT</p>
              </div>
            </>
          )}
        </div>
        {poolDetail.modelType === STAKING_MECHANISM_TYPE.LINEAR && (
          <div className="item">
            <Space align="center">
              <h2 className="reward-rule__title">Reward rate</h2>
              <Tooltip title="The Reward Rate will represent the user's reward ratio.">
                <IconQuestion />
              </Tooltip>
            </Space>
            <p className="content">
              1 {poolDetail.currencyName} = {poolDetail.rate} {poolDetail.currencyNameReward}
            </p>
          </div>
        )}
        {poolDetail?.rewardType === 2 && (
          <>
            <div className="reward-rule">
              <Space align="center">
                <h2 className="reward-rule__title">Reward Rule</h2>
                <Tooltip title="The staking range determines which NFT will be able to random.">
                  <IconQuestion />
                </Tooltip>
              </Space>
              <Row gutter={[0, 20]}>
                <Col className="tier" span={6}>
                  Tier 1: {poolDetail.tier1} {poolDetail.currencyName} - {poolDetail.tier2} {poolDetail.currencyName}
                </Col>
                <Col className="tier" span={6}>
                  Tier 2: {poolDetail.tier2} {poolDetail.currencyName} - {poolDetail.tier3} {poolDetail.currencyName}
                </Col>
                <Col className="tier" span={6}>
                  Tier 3: {poolDetail.tier3} {poolDetail.currencyName} - {poolDetail.tier4} {poolDetail.currencyName}
                </Col>
                <Col className="tier" span={6}>
                  Tier 4: {poolDetail.tier4} {poolDetail.currencyName} - {poolDetail.maxStakeTier}{" "}
                  {poolDetail.currencyName}
                </Col>
                <Col span={24}>
                  <div className="number-of-user item">
                    <label>Number of NFT rewards per day </label>
                    <p className="content">
                      {poolDetail.nftRewardPerDay ? (
                        <NumberFormat thousandSeparator value={poolDetail.nftRewardPerDay} displayType="text" />
                      ) : (
                        "Unlimited"
                      )}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>

            <Tabs defaultActiveKey={POOL_DETAIL_TABS.NFT_REWARD} onChange={onChangeTab}>
              <TabPane tab="NFT Rewards" key={POOL_DETAIL_TABS.NFT_REWARD}>
                <div className="list-nft">
                  <div className="search">
                    <Input
                      className="search-input"
                      placeholder="Search by NFT name, contract address, token ID"
                      onKeyDown={handleSearch}
                      value={searchData.keyword}
                      onChange={onChangeInput}
                      suffix={<IconSearch onClick={onClickSearch} />}
                      maxLength={256}
                      onBlur={onBlurInput}
                    />
                    <Select
                      placeholder="Select NFT format"
                      className="search-select"
                      onChange={onChangeSelectFormat}
                      value={searchData.type}
                    >
                      <Option value={NFT_FORMAT.ALL}>All</Option>
                      <Option value={NFT_FORMAT.ERC_721}>ERC-721</Option>
                      <Option value={NFT_FORMAT.ERC_1155}>ERC-1155</Option>
                    </Select>
                    <IconRefresh className="refresh-icon" onClick={onResetSearch} />
                  </div>
                  <Space direction="vertical" size={8} align="end">
                    <span className="list-nft__total">
                      Total Added NFT:&nbsp;
                      {poolDetail.totalAdded}
                    </span>
                    <span className="list-nft__total">Total Deposited:&nbsp;{poolDetail.totalDeposited}</span>
                  </Space>
                </div>
                <TableComponent
                  columns={columnsPoolNftDetail({
                    toggleWithdrawModal,
                    toggleDepositModal,
                    handleApproveNft,
                    isDraft: poolDetail.isDraft,
                    isAdmin,
                  })}
                  loading={isLoading}
                  dataSource={mapListNftReward}
                  showPagination={false}
                  scroll={{ x: 1200 }}
                  onChangeTable={onChangeTable}
                  rowKey={(record) => record._id}
                />
              </TabPane>
              <TabPane tab="Rewards Activity" key={POOL_DETAIL_TABS.REWARD_ACTIVITY}>
                <div className="list-nft">
                  <div className="search">
                    <Input
                      className="search-input"
                      placeholder="Search by user address wallet, NFT name, contract address, token ID"
                      onKeyDown={handleSearch}
                      value={searchData.keyword}
                      onChange={onChangeInput}
                      suffix={<IconSearch onClick={onClickSearch} />}
                      maxLength={256}
                      onBlur={onBlurInput}
                    />
                    <IconRefresh className="refresh-icon" onClick={onResetSearch} />
                  </div>
                  <span className="list-nft__total">Total Rewarded: {total}</span>
                </div>
                <TableComponent
                  columns={columnsRewardsActivity()}
                  loading={isLoading}
                  dataSource={listRewardActivity.length ? renderListRewardActivity() : []}
                  showPagination={false}
                  scroll={{ y: 270, x: 1020 }}
                  onChangeTable={onChangeTable}
                  rowKey={(record) => record._id}
                />
              </TabPane>
            </Tabs>
          </>
        )}

        <div className="contract">
          <label>Contract Address</label>
          <span className="content">
            {!poolDetail.isDraft ? (
              <>
                {poolDetail.contractAddress}{" "}
                <a
                  className="content"
                  href={`${networkSelected?.blockExplorerUrls?.[0]}/address/${poolDetail?.contractAddress}`}
                  target="_blank"
                >
                  <IconViewTransaction />
                </a>
              </>
            ) : (
              "N/A"
            )}
          </span>
        </div>
        {poolDetail.status === 1 && !poolDetail.isDraft && <StopPool pool={poolDetail} id={id} />}
        <ModalConfirm
          visible={visible}
          title="Are you sure?"
          content={"You won’t able to revert it. Are you sure to delete this pool? "}
          onCancel={() => setVisible(false)}
          onConfirm={handleDelete}
          cancelText={"Cancel"}
          confirmText={"Delete"}
        />
        <ModalComponent
          showCloseIcon={false}
          visible={isDepositModalVisible || isWithdrawModalVisible}
          title={isWithdrawModalVisible ? "Withdraw NFT" : "Deposit NFT"}
          onClose={isWithdrawModalVisible ? toggleWithdrawModal : toggleDepositModal}
        >
          <div className="withdraw-nft-modal">
            <img src={selectedNft?.imageUrl} alt="nft" />
            <Tooltip title={selectedNft?.name}>
              <span className="withdraw-nft-modal__name textEllipsis">{selectedNft?.name}</span>
            </Tooltip>
            <div className="withdraw-nft-modal__form">
              <Formik
                enableReinitialize={true}
                initialValues={initialValue}
                className="form-create-pool"
                onSubmit={(values) => handleSubmit(values)}
              >
                {({ values, setFieldValue, handleBlur, errors }) => (
                  <Form>
                    {isWithdrawModalVisible && (
                      <>
                        <FormItem
                          name="address"
                          typeInput={TYPE_INPUT.TEXT}
                          required
                          disabled
                          label="Contract Address"
                          className="form-item__input full-width"
                          onBlur={handleBlur}
                        />
                        <FormItem
                          name="tokenId"
                          typeInput={TYPE_INPUT.TEXT}
                          required
                          disabled
                          label="Token ID"
                          className="form-item__input full-width"
                          onBlur={handleBlur}
                        />
                        <FormItem
                          name="recipientAddress"
                          placeholder="Enter recipient's wallet address"
                          typeInput={TYPE_INPUT.TEXT}
                          label="Recipient's Wallet Address"
                          className="form-item__input full-width"
                          validate={validateAddress}
                          onBlur={handleBlur}
                        />
                      </>
                    )}
                    <FormItem
                      name="amount"
                      placeholder="0"
                      typeInput={TYPE_INPUT.TEXT}
                      label={
                        <div className="amount">
                          <span>Amount</span>
                          <span>Balance: {isDepositModalVisible ? nftBalance ?? 0 : withdrawBalance ?? 0}</span>
                        </div>
                      }
                      className="form-item__input full-width"
                      maxLength={256}
                      onBlur={(e) => handleBlurAmount(e, setFieldValue)}
                      suffix={
                        <Button className="button-max" onClick={() => setMaxNftBalance(setFieldValue)}>
                          Max
                        </Button>
                      }
                      validate={validateAmount}
                      onChange={(e) => onChangeAmount(e, setFieldValue)}
                    />

                    <div className="withdraw-nft-modal__action">
                      <Button
                        className="button-cancel"
                        onClick={isWithdrawModalVisible ? toggleWithdrawModal : toggleDepositModal}
                      >
                        Cancel
                      </Button>
                      <Button className="button-withdraw" htmlType="submit">
                        {isWithdrawModalVisible ? "Withdraw" : "Deposit"}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </ModalComponent>
        <ModalComponent visible={visibleSubmitting} showCloseIcon={false} title={"Approve NFT"}>
          <div className="get-metadata">
            <img src={GradientLoading} className="rotating" alt="loader" />
            <p className="get-metadata__content">Please wait for contract confirmation</p>
          </div>
        </ModalComponent>
        <ModalComponent
          visible={isLoadingVisible}
          showCloseIcon={false}
          title={transactionType === 1 ? "Deposit NFT" : "Withdraw NFT"}
        >
          <div className="get-metadata">
            <img src={GradientLoading} className="rotating" alt="loader" />
            <p className="get-metadata__content">Please wait for contract confirmation</p>
          </div>
        </ModalComponent>
        <ModalConfirm
          visible={visibleCofirmChangeShow}
          title="Are you sure?"
          content={
            statusChangeShow.value
              ? "This pool will be shown on the users' web interface. Are you sure to enable it?"
              : "This pool will be hidden from the users' web interface. Are you sure to disable it?"
          }
          onConfirm={handleConfirmChangeShowPool}
          onCancel={handleChangeShowPool}
          cancelText={"Cancel"}
          confirmText={"Confirm"}
          loadingConfirm={loadingUpdateStatus}
          showCloseIcon={true}
        />
      </div>
    </div>
  );
};

export default PoolDetail;
