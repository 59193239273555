import React from "react";
import { Route, Redirect } from "react-router";
import { useAppSelector } from "../../utils/customHooks";

function PrivateRoute(props: any) {
  const { component: Component, roles, ...rest } = props;
  const token = useAppSelector((state) => state.auth.currentUser.token);

  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (!token) {
          return <Redirect to="/login" />;
        }

        return <Component {...props} />;
      }}
    />
  );
}

export default PrivateRoute;
