import * as React from "react";
import { SVGProps } from "react";

const IconAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.99998 1.33337C4.31998 1.33337 1.33331 4.32004 1.33331 8.00004C1.33331 11.68 4.31998 14.6667 7.99998 14.6667C11.68 14.6667 14.6666 11.68 14.6666 8.00004C14.6666 4.32004 11.68 1.33337 7.99998 1.33337ZM11.3333 8.66671H8.66665V11.3334H7.33331V8.66671H4.66665V7.33337H7.33331V4.66671H8.66665V7.33337H11.3333V8.66671Z"
      fill="white"
    />
  </svg>
);

export default IconAdd;
