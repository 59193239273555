import React from 'react'

const IconRewardManagement = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.4 22.2857V12H19.2V22.2857H4.8V12H1.6V22.2857H0V5.14286H1.6V10.2857H22.4V5.14286H24V22.2857H22.4ZM12.8 12V15.4286H11.2V12H9.6V17.1429H14.4V12H12.8ZM20.8 1.71429H22.4V5.14286H20.8V1.71429ZM17.6 5.14286H19.2V8.57143H4.8V5.14286H6.4V1.71429H3.2V0H20.8V1.71429H17.6V5.14286ZM1.6 5.14286V1.71429H3.2V5.14286H1.6ZM22.4 24H19.2V22.2857H22.4V24ZM4.8 24H1.6V22.2857H4.8V24Z" fill="#757575" />
    </svg>

  )
}

export default IconRewardManagement
