import React, { Suspense, useState, useEffect } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Route, Router, Switch } from "react-router-dom";
import { ConfigProvider } from "antd";
import { Web3ReactProvider } from "@web3-react/core";

import LoadingComponent from "components/Loading";
import { PublicLayout, PrivateLayout } from "pages/layout";
import NotFound from "pages/notFound";
import { privateRoutes, publicRoutes } from "Routes";
import { browserHistory } from "utils/history";
import { getLibrary } from "./utils/getLibrary";

import enUS from "antd/lib/locale/en_US";
// import zhCN from "antd/lib/locale/zh_CN";
import jaJP from "antd/lib/locale/ja_JP";

import "antd/dist/antd.css";
import "./App.css";
import "./sass/_app.scss";

function App() {
  const { t, i18n } = useTranslation();

  const [locale, setLocale] = useState("en");

  useEffect(() => {
    switch (i18n.language) {
      case "en":
        setLocale(enUS);
        break;

      case "jp":
        setLocale(jaJP);
        break;

      default:
        setLocale(enUS);
        break;
    }
  }, [i18n.language]);

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <div className="App bg-app">
        <ConfigProvider autoInsertSpaceInButton={false} locale={locale}>
          <Router history={browserHistory}>
            <Suspense fallback={<LoadingComponent />}>
              <Switch>
                <Route path={publicRoutes.map((route) => route.path)} component={PublicLayout} />
                <Route path={privateRoutes(t).map((route) => route.path)} component={PrivateLayout} />
                <Route path="*" component={NotFound} />
              </Switch>
            </Suspense>
          </Router>
        </ConfigProvider>
      </div>
    </Web3ReactProvider>
  );
}

export default withTranslation()(App);
