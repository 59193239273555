import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { TFunction } from "i18next";
import { useSelector } from 'react-redux';
import { useWeb3React } from '@web3-react/core';

import IconLogout from "resources/images/IconLogout";
import { useAppDispatch } from "utils/customHooks";
import { connectWalletStart, logout } from "redux/auth/slice";
import { convertAddressWallet } from 'utils';

const { Item } = Menu;

const UserDropdown = (t: TFunction, currentUser: any, history: any, deactivate: any, hanhdleOnPopup: any) => {

  const dispatch = useAppDispatch();
  const { account, active } = useWeb3React();

  const address = useSelector((state: any) => state.auth.walletAddress);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleChangePass = () => {
    history.push('/change-password');
  }

  const handleDisconnect = () => {
    if (address) {
      deactivate();
      dispatch(connectWalletStart({ address: '' }));
      localStorage.removeItem('walletconnect');
    }
  }

  return (
    <div className="user-dropdown">
      {/* <div className="user-dropdown__profile">
        <div className="user-dropdown__left">
          <Avatar src={currentUser.avatarUrl} alt={currentUser.username}>
            {currentUser.username}
          </Avatar>
        </div>
        <div className="user-dropdown__right">
          <div className="username">{currentUser.username}</div>
          <div className="email">{currentUser.email}</div>
        </div>
      </div> */}
      <Menu>
        {
          account && <Item key="0" >
            {convertAddressWallet(account, 5)}
          </Item>
        }
        <Item key="1" onClick={handleChangePass}>
          {t("header.txt_changePass")}
        </Item>
        <Item key="2" onClick={address ? handleDisconnect : hanhdleOnPopup}>
          {address ? t("header.txt_disconnect_wallet") : t("header.txt_connectWallet")}
        </Item>
        <Item key="3" icon={<IconLogout />} onClick={handleLogout}>
          {t("header.text_logout")}
        </Item>
      </Menu>

    </div>
  );
};

export default UserDropdown;
