export const selectNftMetadata = (state) => state.nft.nftMetadata;

export const selectIsLoading = (state) => state.nft.isLoading;

export const selectListNftRewards = (state) => state.nft.listNftRewards;

export const selectTotalNft = (state) => state.nft.total;

export const selectNftDetail = (state) => state.nft.nftDetail;

export const selectListRewardActivity = (state) => state.nft.listRewardActivity;

export const selectListPoolOfNft = (state) => state.nft.listPoolOfNft;

export const selectListNftInPool = (state) => state.nft.listNftInPool;
