import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "antd";
import { useHistory } from "react-router";

import TableComponent from "components/Table";
import { columns } from "./column";
import { getListUserRequest } from "redux/user/slice";
import { selectedDataSearchUser, selectedUser, selectedLoadingList } from "redux/user/slected";
import IconSearch from "resources/images/IconSearch";

const User = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectedUser);
  const dataSearch = useSelector(selectedDataSearchUser);
  const loadingListUser = useSelector(selectedLoadingList);

  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState("");

  const [dataSearchUser, setDataSearchUser] = useState({
    page: 0,
    sortField: "",
    sortOrder: "",
  });

  useEffect(() => {
    setDataSearchUser({
      ...dataSearchUser,
      ...dataSearch,
      page: dataSearch.offset ? dataSearch.offset / limit : dataSearchUser.page,
    });
    setKeyword(!keyword ? (dataSearch.keyword ? dataSearch.keyword : "") : keyword);
  }, []);

  useEffect(() => {
    dispatch(
      getListUserRequest({
        data: {
          keyword: !keyword ? (dataSearchUser.keyword ? dataSearchUser.keyword : "") : keyword,
          limit,
          offset: dataSearchUser.page * limit,
          sortField: dataSearchUser.sortOrder ? dataSearchUser.sortField : "",
          sortType: dataSearchUser.sortOrder,
        },
      })
    );
  }, [dataSearchUser]);

  const handleNameSearch = (data) => {
    setKeyword(data.target.value);
  };

  const handleSearch = (data) => {
    if (data.key === "Enter") {
      setDataSearchUser({ ...dataSearchUser, page: 0 });
      setKeyword(keyword.trim());
      dispatch(
        getListUserRequest({
          data: {
            keyword: keyword,
            limit,
            offset: dataSearchUser.page * limit,
          },
        })
      );
    }
  };

  const handleChangeBlur = () => {
    setKeyword(keyword.trim());
  };

  const listUser = () => {
    return user?.records && user?.records.map((e, index) => ({ ...e, index: limit * dataSearchUser.page + index + 1 }));
  };

  const onChangePagination = (page, pageSize) => {
    setDataSearchUser({ ...dataSearchUser, page: page - 1 });
  };

  const onChangeTable = (pagination, filter, sorter, extra) => {
    let { order, field } = sorter;
    let data = "";
    if (order) {
      switch (order) {
        case "ascend":
          data = 1;
          break;
        case "descend":
          data = -1;
          break;
      }
    } else data = "";

    setDataSearchUser({ ...dataSearchUser, sortField: field, sortOrder: data });
  };

  return (
    <div className="user-management-wrap">
      <div className="search">
        <Input
          placeholder="Search"
          value={keyword}
          onKeyDown={handleSearch}
          onChange={handleNameSearch}
          suffix={<IconSearch />}
          maxLength={256}
          onBlur={handleChangeBlur}
        />
      </div>
      <TableComponent
        showPagination
        dataSource={listUser(user?.records)}
        rowKey={(e) => e._id}
        columns={columns({ history })}
        current={dataSearchUser.page + 1}
        onChangePagination={onChangePagination}
        total={user.total}
        onChangeTable={onChangeTable}
        loading={loadingListUser}
      />
    </div>
  );
};

export default User;
