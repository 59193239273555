import api from "./api";

export const getCurrency = (params: any) => api.get("/currency", params);

export const updateCurrency = ({ id, exchangeRate }: { id: string; exchangeRate: number }) =>
  api.put(`/currency/${id}`, { exchangeRate });

export const addToken = (params: any) => api.post(`/currency`, params);

export const getTokenDetail = (params: any) => api.get(`/currency/${params.id}`);

export const deleteTokenAPI = (params: any) => api.delete(`/currency/${params.id}`);
