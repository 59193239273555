import api from "./api";

export const listUserAPI = (payload: any) => api.get("/user", payload);

export const userDetailAPI = (payload: any) => api.get(`/user/${payload.id}`);

export const dashboardAPI = (payload: any) => api.get("/user/admin/dashboard", payload);

export const activitylogsAPI = (payload: any) => api.get("/activity-log/detail", payload);

export const blockAndUnblockUser = (id: string) => api.put(`/user/${id}/block`);
