import { Tooltip } from "antd";
import NumberFormat from "components/NumberFormat";
import React from "react";

export const columnListPoolInNft = () => {
  return [
    {
      title: "Pool Name",
      dataIndex: "name",
      key: "name",
      ellipsis: {
        showTitle: false,
      },
      render: (value, record) => (
        <Tooltip title={record.pool.name} placement="topLeft">
          {record.pool.name}
        </Tooltip>
      ),
    },
    {
      title: "Total Deposited",
      dataIndex: "totalDeposited",
      key: "totalDeposited",
      sorter: true,
      render: (value) => <NumberFormat value={value} thousandSeparator displayType="text" />,
    },
    {
      title: "Total Claimed",
      dataIndex: "totalClaim",
      key: "totalClaim",
      sorter: true,
      render: (value) => <NumberFormat value={value} thousandSeparator displayType="text" />,
    },
  ];
};
