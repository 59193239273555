import * as React from "react";
import { SVGProps } from "react";

const IconQuestion = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99996 1.66699C14.6023 1.66699 18.3333 5.39795 18.3333 10.0003C18.3333 14.6027 14.6023 18.3337 9.99996 18.3337C5.39759 18.3337 1.66663 14.6027 1.66663 10.0003C1.66663 5.39795 5.39759 1.66699 9.99996 1.66699ZM9.99991 3.33361C6.31802 3.33361 3.33325 6.31838 3.33325 10.0003C3.33325 13.6822 6.31802 16.6669 9.99991 16.6669C13.6818 16.6669 16.6666 13.6822 16.6666 10.0003C16.6666 6.31838 13.6818 3.33361 9.99991 3.33361ZM9.99996 13.3336C10.4602 13.3336 10.8333 13.7067 10.8333 14.1669C10.8333 14.6272 10.4602 15.0003 9.99996 15.0003C9.53972 15.0003 9.16663 14.6272 9.16663 14.1669C9.16663 13.7067 9.53972 13.3336 9.99996 13.3336ZM9.99996 5.00037C11.8409 5.00037 13.3333 6.49275 13.3333 8.3337C13.3333 9.82938 12.3482 11.095 10.9915 11.5171L10.8333 11.562V11.667C10.8333 12.1273 10.4602 12.5004 9.99996 12.5004C9.5726 12.5004 9.22037 12.1787 9.17223 11.7642L9.16663 11.667V10.8337C9.16663 10.4063 9.48833 10.0541 9.90278 10.006L10.1243 9.9958C10.9867 9.93217 11.6666 9.21234 11.6666 8.3337C11.6666 7.41323 10.9204 6.66704 9.99996 6.66704C9.07948 6.66704 8.33329 7.41323 8.33329 8.3337C8.33329 8.79394 7.9602 9.16704 7.49996 9.16704C7.03972 9.16704 6.66663 8.79394 6.66663 8.3337C6.66663 6.49275 8.15901 5.00037 9.99996 5.00037Z"
      fill="#E100A2"
    />
  </svg>
);

export default IconQuestion;
