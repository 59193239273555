import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import jp from "./locales/jp/defaults.json";
import en from "./locales/en/defaults.json";
import ko from "./locales/ko/defaults.json";
import cn from "./locales/cn/defaults.json";

declare global {
  interface Window {
    i18n: any;
  }
}

const resources = {
  en: {
    translation: en,
  },
  jp: {
    translation: jp,
  },
  ko: {
    translation: ko,
  },
  cn: {
    translation: cn,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: getCurrentLanguage(),
    fallbackLng: getCurrentLanguage(),
    nsSeparator: false,
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag"],
      lookupQuerystring: "lang",
      lookupCookie: "i18n",
      lookupLocalStorage: "language",
      caches: ["localStorage", "cookie"],
    },
  });

export default i18n;

if (typeof window !== "undefined") {
  window.i18n = i18n;
}

export function getCurrentLanguage() {
  return localStorage.getItem("language") || "en";
}
