import * as React from "react";

function IconArrowDown(props: any) {
  return (
    <svg width={10} height={6} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.207.793a1 1 0 00-1.414 0L5 3.586 2.207.793A1 1 0 00.793 2.207l3.5 3.5a1 1 0 001.414 0l3.5-3.5a1 1 0 000-1.414z"
        fill="#D94036"
      />
    </svg>
  );
}

export default IconArrowDown;
