import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ReactPlayer from "react-player";
import { Prompt } from "react-router-dom";
import { Button, Col, Image, Row } from "antd";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import Web3 from "web3";
import { isEqual } from "lodash";

import { useAppSelector } from "utils/customHooks";
import { getNftMetadataApi, registerNftApi } from "services/nft";
import { NFT_FORMAT_OPTIONS, TYPE_INPUT, TYPE_OF_ANT_DESIGN } from "common/constant";
import FormItem from "components/FormItem";
import ModalComponent from "components/Modal";
import ModalConfirm from "components/ModalConfirm";
import showMessage from "components/Message";

import IconPop from "resources/images/IconPop";
import IconImagePlaceholder from "resources/images/IconImagePlaceholder";
import GradientLoading from "resources/images/gradient-loader.png";
import { deleteKeyNullOfObject } from "utils";

const FormObserver = ({ callback, dataCompare, condition }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    if (!condition || !dataCompare) return;
    if (!isEqual(values, dataCompare)) {
      callback();
    }
  }, [values, condition, dataCompare]);

  return null;
};

const RegisterNft = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const formRef = useRef(null);

  const listChain = useAppSelector((state) => state.auth.chains);

  const [isGetMetadataModalVisible, setIsGetMetadataModalVisible] = useState(false);
  const [isCautionModalVisible, setIsCautionModalVisible] = useState(false);
  const [isLoadingRegister, setIsLoadingRegister] = useState(false);
  const [nftMetadata, setNftMetadata] = useState(null);
  const [isImageError, setIsImageError] = useState(false);
  const [initialValues, setInitialValues] = useState({
    contractAddress: "",
    tokenId: "",
    type: null,
    chainId: null,
  });
  const [dataForGetMetadata, setDataForGetMetadata] = useState(null);

  const validationSchema = Yup.object().shape({
    contractAddress: Yup.string().required(t("message.E2")),
    tokenId: Yup.string().required(t("message.E2")),
    type: Yup.mixed().required(t("message.E2")),
    chainId: Yup.mixed().required(t("message.E2")),
  });

  const validateAddress = (value) => {
    let error;
    const isAddress = Web3.utils.isAddress(value);
    if (!isAddress) error = t("message.E2_1");
    return error;
  };

  const onChangeAddress = (e, setFieldValue) => {
    setFieldValue("contractAddress", e.target.value.trim());
  };

  const onChangeTokenId = (e, setFieldValue) => {
    setFieldValue("tokenId", e.target.value.trim());
  };

  const handleGetMetadata = async (values) => {
    if (nftMetadata) {
      try {
        if (isImageError) {
          showMessage(TYPE_OF_ANT_DESIGN.ERROR, "Can not register this NFT because the NFT image is undefined");
          return;
        }
        setIsLoadingRegister(true);
        const res = await registerNftApi(values);
        if (!res?.meta?.code) {
          showMessage(TYPE_OF_ANT_DESIGN.SUCCESS, "message.S1");
          history.push(`/nft-detail/${res._id}`);
        }
        setIsLoadingRegister(false);
      } catch (error) {
        showMessage(TYPE_OF_ANT_DESIGN.ERROR, "Failed to register NFT");
      }
    } else {
      setIsGetMetadataModalVisible(true);
      const data = {
        contractAddress: values.contractAddress.trim(),
        tokenId: values.tokenId.trim(),
        type: values.type,
        chainId: values?.chainId,
      };
      const res = await getNftMetadataApi(data);
      if (!res?.meta?.code) {
        setNftMetadata({
          name: res.name,
          collectionName: res.collectionName,
          imageUrl: res.imageUrl,
          imageUrlM: res.imageUrlM,
        });
        setDataForGetMetadata(data);
      }
      setIsGetMetadataModalVisible(false);
    }
  };

  const toggleCautionModal = () => {
    setIsCautionModalVisible(!isCautionModalVisible);
  };

  const handleGoBack = () => {
    if (formRef.current.dirty) {
      toggleCautionModal();
    } else history.push("/nft");
  };

  const onGoBack = () => {
    history.push("/nft");
  };

  return (
    <div className="register-nft">
      <div className="register-nft__header">
        <IconPop className="register-nft__pop cursor-pointer" onClick={handleGoBack} />
        <h2 className="register-nft__title">Register NFT Rewards</h2>
      </div>
      <div className="register-nft__body">
        <Row gutter={48}>
          <Col span={12}>
            <div className="register-nft__form">
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                className="form-create-pool"
                onSubmit={(values) => handleGetMetadata(values)}
                innerRef={formRef}
              >
                {({ values, setFieldValue, handleBlur, resetForm }) => (
                  <Form>
                    <FormObserver
                      callback={() => {
                        setNftMetadata(null);
                        setDataForGetMetadata(null);
                      }}
                      dataCompare={dataForGetMetadata}
                      condition={nftMetadata}
                    />
                    <Prompt
                      when={
                        Object.keys(deleteKeyNullOfObject(values)).length > 0 &&
                        !isLoadingRegister &&
                        !isCautionModalVisible
                      }
                      message="If you leave this page, process will be disrupted and data will be lost"
                    />
                    <FormItem
                      name="chainId"
                      placeholder="Network"
                      typeInput={TYPE_INPUT.SELECT}
                      required
                      label="Network"
                      className="select"
                      options={listChain?.map((chain) => {
                        return {
                          name: chain?.chainName,
                          value: chain?.chainId,
                        };
                      })}
                      onBlur={handleBlur}
                      getPopupContainer={(trigger) => trigger.parentElement}
                    />
                    <FormItem
                      name="type"
                      placeholder="Select NFT format"
                      typeInput={TYPE_INPUT.SELECT}
                      required
                      label="NFT Format"
                      className="select"
                      options={NFT_FORMAT_OPTIONS}
                      onBlur={handleBlur}
                      getPopupContainer={(trigger) => trigger.parentElement}
                    />
                    <FormItem
                      name="contractAddress"
                      typeInput={TYPE_INPUT.TEXT}
                      placeholder="Enter contract address"
                      required
                      label="Contract Address"
                      className="form-item__input full-width"
                      validate={validateAddress}
                      onBlur={handleBlur}
                      onChange={(e) => onChangeAddress(e, setFieldValue)}
                    />
                    <FormItem
                      name="tokenId"
                      typeInput={TYPE_INPUT.TEXT}
                      placeholder="Enter token ID"
                      required
                      label="Enter token ID"
                      className="form-item__input full-width"
                      onBlur={handleBlur}
                      onChange={(e) => onChangeTokenId(e, setFieldValue)}
                    />
                    {nftMetadata && (
                      <>
                        <FormItem
                          name="name"
                          typeInput={TYPE_INPUT.TEXT}
                          label="Name"
                          value={nftMetadata.name}
                          disabled
                          className="form-item__input full-width"
                        />
                        <FormItem
                          name="collection"
                          typeInput={TYPE_INPUT.TEXT}
                          disabled
                          value={nftMetadata.collectionName || "N/A"}
                          label="Collection"
                          className="form-item__input full-width"
                        />
                      </>
                    )}
                    <div className="register-nft__action">
                      <Button className="register-nft__discard" onClick={nftMetadata ? onGoBack : resetForm}>
                        Discard
                      </Button>
                      <Button className="register-nft__get" htmlType="submit" loading={isLoadingRegister}>
                        {nftMetadata ? "Register" : "Get Metadata"}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
          <Col span={12} className="register-nft__preview">
            <div>
              <h3 className="register-nft__preview__title">Preview</h3>
              <div className="register-nft__preview__image">
                {nftMetadata ? (
                  nftMetadata.imageUrlM ? (
                    <ReactPlayer
                      url={nftMetadata.imageUrl}
                      light={nftMetadata.imageUrlM}
                      controls
                      style={{ borderRadius: "5px" }}
                      playing
                    />
                  ) : (
                    <Image
                      preview
                      src={nftMetadata?.imageUrl}
                      alt="nft-preview"
                      onError={() => setIsImageError(true)}
                    />
                  )
                ) : (
                  <IconImagePlaceholder />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <ModalComponent
        visible={isGetMetadataModalVisible}
        showCloseIcon={true}
        title="Getting Metadata"
        onClose={() => setIsGetMetadataModalVisible(false)}
      >
        <div className="get-metadata">
          <img src={GradientLoading} className="rotating" alt="loader" />
          <p className="get-metadata__content">
            The metadata is being update. Please wait until the end of the process. Leaving this means process
            cancellation.
          </p>
        </div>
      </ModalComponent>
      <ModalConfirm
        visible={isCautionModalVisible}
        showCloseIcon={true}
        title="Caution"
        content="If you leave this page, process will be disrupted and data will be lost"
        confirmText="Leave page"
        cancelText="Cancel"
        onCancel={toggleCautionModal}
        onConfirm={() => history.goBack()}
      />
    </div>
  );
};

export default RegisterNft;
