import moment from "moment";
import { intersection } from "lodash-es";
import { FORMAT_DATE, FORMAT_DATE_FULL, FORMAT_MINUTE_SECOND, VALUE_NONE } from "../common/constant";
import { range } from "lodash";
import Web3 from "web3";
import BigNumber from "bignumber.js";

BigNumber.config({
  EXPONENTIAL_AT: 100,
});
declare global {
  interface Window {
    ethereum: any;
  }
}

export function formatDate(date: moment.MomentInput, type = "") {
  return moment(date).format(type || FORMAT_DATE);
}

export function formatDateFull(date: moment.MomentInput, type = "") {
  return date ? moment(date).format(type || FORMAT_DATE_FULL) : VALUE_NONE;
}

export function getStartDateTimestamp(value: string) {
  return moment(value, FORMAT_DATE).startOf("days").utc().valueOf();
}

export function getEndDateTimestamp(value: string) {
  return moment(value, FORMAT_DATE).endOf("days").utc().valueOf();
}

export function dateNowNoSpecial() {
  return moment(new Date()).format("YYYYMMDDHHmmss");
}

export const getDurationSecondFromNow = (timeUnix: moment.MomentInput) => {
  const now = moment(new Date()); //now
  const b = moment(timeUnix);
  return now.diff(b, "seconds");
};

export const formatTimeNumber = (number: number) => {
  return `0${number}`.slice(-2);
};

export const getTimeDistance2Dates = (endTime: any, startTime: any) => {
  const ms = moment(endTime).diff(moment(startTime));
  const d = moment.duration(ms);
  const floorHour = Math.floor(d.asHours());
  const hour = formatTimeNumber(floorHour);
  const s = hour + moment.utc(ms).format(FORMAT_MINUTE_SECOND);
  return s;
};

export const getDurationFromTimestamp = (time: any) => {
  if (time === 0) return VALUE_NONE;
  const d = moment.duration(time);
  const floorHour = Math.floor(d.asHours());
  const hour = formatTimeNumber(floorHour);
  const s = hour + moment.utc(time).format(FORMAT_MINUTE_SECOND);
  return s;
};

export const changeTable = (setFilters: (arg0: any) => void, filters: any) => ({
  handleChangePage: (page: any) => {
    setFilters({ ...filters, page });
  },
  handleChangeRowsPerPage: (pageSize: any) => {
    setFilters({
      ...filters,
      page: 0,
      pageSize,
    });
  },
  handleFilters: (data: any) => {
    setFilters({
      ...filters,
      ...data,
    });
  },
  handleSearch: (data: any) => {
    setFilters({
      ...filters,
      ...data,
    });
  },
});

export const compareShallowObject = (object1: any, object2: any) => {
  return JSON.stringify(object1) === JSON.stringify(object2);
};

export function getBase64(img: Blob, callback: (arg0: string | ArrayBuffer | null) => any) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

export const isFile = (input: any) => "File" in window && input instanceof File;

export function isArrayWithLength(arr: string | any[]) {
  return Array.isArray(arr) && arr.length;
}

export function getAllowedRoutes(routes: any, role: string) {
  return routes.filter(({ permission }: any) => {
    if (!permission) return true;
    else if (!isArrayWithLength(permission)) return true;
    else return intersection(permission, [role]).length;
  });
}

export function passwordStrength(pw: string): number {
  return (
    (/.{8,}/.test(pw) ? 1 : 0) /* at least 8 characters */ *
    ((/[a-z]/.test(pw) ? 1 : 0) /* a lower letter */ +
      (/[A-Z]/.test(pw) ? 1 : 0) /* a upper letter */ +
      (/\d/.test(pw) ? 1 : 0) /* a digit */ +
      (/[^A-Za-z0-9]/.test(pw) ? 1 : 0)) /* a special character */
  );
}

export function getExtension(filename: string) {
  const parts = filename?.split(".") || [];
  return parts[parts?.length - 1];
}

export function getFileName(filename: string) {
  return filename && filename.substring(0, filename.lastIndexOf("."));
}
export const getOrderType = (order: string) => (order === "ascend" && 1) || (order === "descend" && -1) || "";

export const numberWithCommas = (number: number) => {
  return number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const removeFalsyElement = (data: any) =>
  Object.keys(data)
    .filter((key) => data[key] || data[key] === 0)
    .reduce((a, key) => ({ ...a, [key]: data[key] }), {});

export const convertAddress = (data: string, minLength: number = 10) => {
  if (data && data.length > minLength) {
    const first = data.slice(0, 6);
    const last = data.slice(data.length - 4, data.length);
    return `${first}...${last}`;
  }
  return data;
};

export const formatBigNumber = (value: number, prefix = "") => {
  const oneMillion = Math.pow(10, 6);
  const onBillion = Math.pow(10, 9);

  if (!value) {
    return VALUE_NONE;
  }

  if (value >= onBillion) {
    const beforeDecimal = Math.floor(value / onBillion);
    const afterDecimal = value % onBillion;

    return `${prefix}${numberWithCommas(beforeDecimal)}B${afterDecimal ? "+" : ""}`;
  }

  if (value >= oneMillion) {
    const beforeDecimal = Math.floor(value / oneMillion);
    const afterDecimal = value % oneMillion;

    return `${prefix}${numberWithCommas(beforeDecimal)}M${afterDecimal ? "+" : ""}`;
  }

  return `${prefix}${numberWithCommas(value)}`;
};

export function detectEtherObject() {
  let ethereum;
  if (typeof window !== "undefined") {
    ethereum = window?.ethereum;
  }

  return ethereum;
}

export const handleBlurField = (setFieldValue: any, field: string, handleBlur: any) => (e: any) => {
  handleBlur(e);
  if (e.target.value) {
    setFieldValue(field, e.target.value.trim());
  }
};

export const convertAddressWallet = (value: string, num = 6) => {
  if (value) {
    return `${value.slice(0, num)}...${value.slice(-4)}`;
  }
  return "";
};

export const convertPrice = (value: any, coinDecimal: number) => {
  return new BigNumber(value).multipliedBy(new BigNumber(Math.pow(10, coinDecimal))).toString();
};

export const removeLetter = (value: string) => {
  if (value) return value.replace(/\D+/g, "");
  else return value;
};

export const getLengthObject = (data: any) => {
  if (typeof data === "object" && data !== null) {
    return Object.keys(data).length;
  }
  return 0;
};

export const limitMaxLengthNumber = (maxLength: number) => (inputObj: any) => {
  const { value } = inputObj;
  const integerPath = (value || "").split(".")[0];
  return integerPath.length <= maxLength;
};

export const modifyTokenForSelect = (arr: any[]) => {
  const data = arr
    .map((e: any) => ({
      title: e.name,
      value: e.key,
      chainId: e.chainId,
      chainName: e.chainName,
      _id: e._id,
    }))
    ?.reduce((res: any, token: any) => {
      const { title, value, _id, chainId, chainName } = token;

      res[chainId] = [
        ...(res[chainId] || []),
        {
          value: value,
          title,
          _id,
          chainId,
          chainName,
        },
      ];

      return res;
    }, {});

  const result = Object.keys(data).map((e: string) => {
    if (data[e].length)
      return {
        title: data[e][0]?.chainName,
        value: e,
        children: [...data[e]].map((e) => ({ title: e.title?.toUpperCase(), value: e.value?.toUpperCase() })),
      };
    return {
      title: data[e][0]?.chainName,
      value: e,
    };
  });

  return result;
};

export const disabledStartTime = (current: any, dateTime: any) => {
  const now = moment(dateTime).startOf("day");
  const hourNow = moment(dateTime).hours();
  const minuteNow = moment(dateTime).minutes();

  const newStartDate = moment(current).startOf("day");
  const houStartDate = moment(current).hours();

  let disabledHours: any[] = [];
  let disabledMinutes: any[] = [];

  if (newStartDate.isSame(now)) {
    disabledHours = range(0, 24).slice(0, minuteNow === 59 ? hourNow - 1 : hourNow);
    if (houStartDate === hourNow) {
      disabledMinutes = range(0, 60).slice(0, minuteNow);
    }
  }

  return {
    disabledHours: () => disabledHours,
    disabledMinutes: () => disabledMinutes,
  };
};

export const selectActiveProvider = async (rpcs: any) => {
  for (let i = 0; i < rpcs.length; i++) {
    try {
      const blockNumber = await new Web3(rpcs[i]).eth.getBlockNumber();
      if (blockNumber) return rpcs[i];
    } catch (error) {
      console.log(`Provider ${i} not available!`);
    }
  }
};

export const numberToRound = (num: number, fix: number, percent?: boolean) => {
  return [
    percent
      ? Number(num)
          .toFixed(fix)
          .replace(/(\.0+|0+)$/, "")
      : parseFloat(Number(num).toFixed(fix)),
    "",
  ];
};

export const getTimeDiffSeconds = (dateTime: any, dateTime_: any) => {
  let data = 0;
  data = dateTime && dateTime_ && dateTime.diff(dateTime_, "seconds");
  return data;
};

export const deleteKeyNullOfObject = (object: any) => {
  let objectClone = { ...object };
  Object.keys(object).map((e) => {
    if (!objectClone[e]) delete objectClone[e];
  });
  return objectClone;
};
