import React, { useMemo } from "react";
import { Typography, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { CHAIN_INFO } from "common/constant";
import { useAppSelector } from "utils/customHooks";

const { Paragraph } = Typography;

const NetworkNotice = ({}) => {
  const networkCreatePool = useAppSelector((state) => state.pool.network);
  const chains = useAppSelector((state) => state.auth.chains);

  const networkSelected = useMemo(() => {
    const chain = chains.find((item) => item.chainId === networkCreatePool);

    return chain;
  }, [chains, networkCreatePool]);

  const antIcon = <LoadingOutlined spin />;

  return (
    <div className="network_notice">
      <Spin indicator={antIcon} />
      <Paragraph className="title">Wrong Network</Paragraph>
      <span className="desc">
        Please change network on your wallet to
        <br />
        <a>
          {networkSelected
            ? networkSelected.chainName
            : process.env.REACT_APP_IS_DEV
            ? CHAIN_INFO.BSC_TESTNET.name
            : CHAIN_INFO.BSC.name}
        </a>
      </span>
    </div>
  );
};

export default NetworkNotice;
