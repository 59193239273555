import moment from "moment";

export const HTTP_RESPONSE = {
  OK: 200,
  ERROR_CODE_401: 401,
};

export const RESPONSE_STATUS = {
  SUCCESS: true,
  ERROR: false,
};

export const SECOND_IN_DAY = 24 * 60 * 60;

export const TYPE_OF_ANT_DESIGN = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  IMG_DONE: "done",
};

export const TYPE_NOTIFICATION = {
  P1: "P1",
  P2: "P2",
  P3: "P3",
  P4: "P4",
  P5: "P5",
};

export const MAX_LENGTH_INPUT = 256;
export const MAX_LENGTH_TEXTAREA = 1000;
export const MAX_LENGTH_PASSWORD = 256;
export const MAX_LENGTH_INPUT_RESET = 10;

export const VALUE_NONE = "--";

export const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!-/:-@[-`{-~])[\w\W\d\s!-/:-@[-`{-~]{6,}$/;

//
export const SORT_ORDER = {
  ASCEND: 1,
  DESCEND: -1,
};

export const TYPE_INPUT = {
  TEXT: "TEXT",
  TEXTAREA: "TEXTAREA",
  PASSWORD: "PASSWORD",
  SELECT: "SELECT",
  CHECKBOX: "CHECKBOX",
  NUMBER: "NUMBER",
  SEARCH: "SEARCH",
  SELECT_INFINITY_SCROLL: "SELECT_INFINITY_SCROLL",
  SWITCH: "SWITCH",
  DATE: "DATE",
  RADIO: "RADIO",
  TREE_SELECT: "TREE_SELECT",
};

export const PARAMS_TABLE = {
  limit: 10,
  offset: 0,
};

const PAGE_SIZE_DEFAULT = 10;
const PAGE_SIZE_OPTIONS = ["10", "20", "50"];
const INPUT_LENGTH = 256;

const STEP_FORGOT_PASSWORD = {
  CONFIRM_OTP_CODE: "CONFIRM_OTP_CODE",
  SET_NEW_PASSWORD: "SET_NEW_PASSWORD",
  SUCCESS: "SUCCESS",
};

const USER_STATUS = {
  PENDING: 0,
  ACTIVE: 1,
  BLOCKED: 2,
};

export const USER_ROLE = {
  USER: 1,
  ARTIST: 2,
  ADMIN: 3,
};

const FORMAT_DATE = "DD/MM/YYYY";
const FORMAT_DATE_Y_M_D = "YYYY/MM/DD";
const FORMAT_DATE_FULL = "DD/MM/YYYY HH:mm:ss";
const FORMAT_MINUTE_SECOND = ":mm:ss";

const DECIMAL_SCALE_NUMBER = 8;

const MINUTE_TO_TIMESTAMP = 60 * 1000;

const MAX_VALUE_COMMISSION_RATE = 100;

export {
  FORMAT_DATE_Y_M_D,
  PAGE_SIZE_DEFAULT,
  PAGE_SIZE_OPTIONS,
  FORMAT_DATE,
  FORMAT_DATE_FULL,
  FORMAT_MINUTE_SECOND,
  INPUT_LENGTH,
  STEP_FORGOT_PASSWORD,
  USER_STATUS,
  DECIMAL_SCALE_NUMBER,
  MINUTE_TO_TIMESTAMP,
  MAX_VALUE_COMMISSION_RATE,
};

export const DATA_SEARCH_NFT_STAKING_STATUS = [
  {
    name: "Live",
    value: 1,
  },
  {
    name: "Finished",
    value: 3,
  },
  {
    name: "Waiting for public",
    value: 2,
  },
  {
    name: "Draft",
    value: 4,
  },
];

export const POOL_REWARD_TYPE = {
  token: 1,
  nft: 2,
};

export const NFT_FORMAT = {
  ALL: "",
  ERC_721: 1,
  ERC_1155: 2,
};

export const NFT_FORMAT_BY_VALUE = {
  1: "ERC-721",
  2: "ERC-1155",
};

export const NFT_FORMAT_OPTIONS = [
  { name: "ERC-721", value: NFT_FORMAT.ERC_721 },
  { name: "ERC-1155", value: NFT_FORMAT.ERC_1155 },
];

export const NFT_STATUS_BY_VALUE = {
  1: "Registered",
  2: "Deposited",
  3: "Out Of Stock",
};

export const NFT_STATUS = {
  REGISTERED: 1,
  DEPOSITED: 2,
  OUT_OF_STOCK: 3,
};

export const NFT_TIER = {
  TIER_1: 1,
  TIER_2: 2,
  TIER_3: 3,
  TIER_4: 4,
};

export const DEFAULT_SEARCH_STATUS = 0;

export const LIST_NETWORK_RPC_MAINNET = {
  56: "https://bsc-dataseed.binance.org/",
  137: "https://rpc-mainnet.maticvigil.com/",
  1: "https://mainnet.infura.io/v3/50f6131e95134c0fba1a009b561a31d9",
};

export const METAMASK_DEEPLINK = "https://metamask.io/download";

export const BRIDGE_URL = "https://pancakeswap.bridge.walletconnect.org";

export const NETWORK_URL_BSC = "https://bsc-dataseed.binance.org/";

export const KEY_WALLETCONNECT = "walletconnect";

export const erc721Collection = "0x25722372aBEE915AC4e82345890c794bEf49A72f";

export const signatureUtils = "0xF56EF2f172ACe191b5ac4E7684457944eBa72097";

export const INIT_UNIT_256 = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

export const TYPE_ADD_TOKEN = [
  {
    name: "Tokens that have been listed on multiple platforms",
    value: 1,
  },
  {
    name: "Tokens that have not been listed yet",
    value: 2,
  },
];

export const TOKEN_LIST_STATUS = {
  LISTED_ON_MULTIPLE_PLATFORM: 1,
  NOT_LISTED_ON_MULTIPLE_PLATFORM: 2,
};

export const TOKEN_TYPE = {
  LP_TOKEN: 0,
  SINGLE_TOKEN: 1,
};

export const STAKING_MECHANISM = [
  { name: "Allocation", value: 1 },
  { name: "Linear", value: 2 },
];

export const STAKING_MECHANISM_TYPE = {
  LINEAR: 2,
  ALLOCATION: 1,
};

export const DATA_PUBLISH = [
  {
    name: "Not now",
    value: "notNow",
  },
  {
    name: "Now",
    value: "now",
  },
  {
    name: "Pick a date",
    value: "date",
  },
];

export const NUMBER_OF_NFT_REWARD_PER_DAY = [
  { name: "Unlimited", value: 0 },
  { name: "Limited", value: 1 },
];

export const PUBLISH_TEXT = {
  NOT_NOW: "notNow",
  NOW: "now",
  DATE: "date",
};

export const DATA_DURATION = [
  {
    name: "Flexible",
    value: 0,
  },
  // production delete 1 day.
  // {
  //   name: "1 day",
  //   value: 1,
  // },
  // {
  //   name: "14.4 minus",
  //   value: 0.01,
  // },
  {
    name: "90 days",
    value: 90,
  },
  {
    name: "180 days",
    value: 180,
  },
  {
    name: "360 days",
    value: 360,
  },
];

export const SECONDS_IN_A_YEAR = 31556926;

export const BSCMainnetChainId = "0x38";
export const BSCMainnetChainIdDec = 56;
export const BSCTestnetChainId = "0x61";
export const BSCTestnetChainIdDec = 97;
export const EtherMainnetChainIdDec = 1;

export const EtherTestnetChainId = "0x4";
export const EtherTestnetChainIdDec = 4;

export const PolygonMainnetChainIdDec = 137;
export const PolygonTestnetChainId = "0x13881";
export const PolygonTestnetChainIdDec = 80001;

export const CHAIN_INFO = {
  BSC: {
    name: "Binance Smart Chain",
    value: BSCMainnetChainIdDec,
    valueString: BSCMainnetChainIdDec.toString(),
    textWarning: "Binance Smart Chain - Mainnet",
    url: "https://bscscan.com",
    suffixToken: "BEP-20",
    explorerName: "BSCscan",
  },
  BSC_TESTNET: {
    name: "Binance Smart Chain Testnet",
    value: BSCTestnetChainIdDec,
    valueString: BSCTestnetChainIdDec.toString(),
    textWarning: "Binance Smart Chain - Testnet",
    url: "https://testnet.bscscan.com",
    suffixToken: "BEP-20",
    explorerName: "BSCscan",
  },
  ETHER: {
    name: "Ethereum",
    value: EtherMainnetChainIdDec,
    valueString: EtherMainnetChainIdDec.toString(),
    textWarning: "Ethereum - Mainnet",
    url: "https://etherscan.io",
    suffixToken: "ERC-20",
    explorerName: "Etherscan",
  },
  ETHER_TESTNET: {
    name: "Ethereum Testnet (Rinkeby)",
    value: EtherTestnetChainIdDec,
    valueString: EtherTestnetChainIdDec.toString(),

    textWarning: "Ethereum (Rinkeby) - Testnet",
    url: "https://rinkeby.etherscan.io",
    suffixToken: "ERC-20",
    explorerName: "Etherscan",
  },
  POLYGON: {
    name: "Polygon",
    value: PolygonMainnetChainIdDec,
    valueString: PolygonMainnetChainIdDec.toString(),
    textWarning: "Polygon - Mainnet",
    url: "https://polygonscan.com",
    suffixToken: "ERC-20",
    explorerName: "Polygonscan",
  },
  POLYGON_TESTNET: {
    name: "Polygon Mumbai Testnet",
    value: PolygonTestnetChainIdDec,
    valueString: PolygonTestnetChainIdDec.toString(),
    textWarning: "Polygon Mumbai - Testnet",
    url: "https://mumbai.polygonscan.com",
    suffixToken: "ERC-20",
    explorerName: "Polygonscan",
  },
};

export const FORMAT_DATE_TIME = "DD/MM/YYYY HH:mm:ss";
export const FORMAT_DATE_TIME_API = "YYYY-MM-DD HH:mm:ss";

export const ACTION_NAME = {
  1: "Stake",
  2: "Unstake",
  3: "Claim",
};

export const NETWORK_DEFAULT = process.env.REACT_APP_IS_DEV ? 97 : 56;

export const ID_COINGECKO_CHAIN_ID = {
  1: "ethereum",
  56: "binance-smart-chain",
  137: "polygon-pos",
};

export const W_MATIC = "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270";
export const W_ETH = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
export const SUPPORT_CHAIN_MAINNET = {
  MATIC: 137,
  ETH: 1,
  BSC: 56,
};
export const TIME_SECONDS_IN_DAY = 86400;

export const STAKING_DATE = {
  start: moment().set("hour", 21).set("minute", 0).set("second", 0).toISOString(),
  end: moment().endOf("day").toISOString(),
};
