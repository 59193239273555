import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listNftRewards: [],
  listRewardActivity: [],
  listPoolOfNft: [],
  listNftInPool: [],
  nftMetadata: null,
  nftDetail: null,
  isLoading: false,
  total: 0,
};

const nftSlice = createSlice({
  name: "nft",
  initialState,
  reducers: {
    getNftMetaDataStart: (state, action) => {
      state.isLoading = true;
    },
    getNftMetaDataSuccess: (state, action) => {
      state.nftMetadata = action.payload;
      state.isLoading = false;
    },
    getNftMetaDataFail: (state, action) => {
      state.isLoading = false;
    },
    getListNftRewardsStart: (state, action) => {
      state.isLoading = true;
    },
    getListNftRewardsSuccess: (state, action) => {
      const { items, total } = action.payload;
      state.listNftRewards = items;
      state.total = total;
      state.isLoading = false;
    },
    getListNftRewardsFail: (state, action) => {
      state.isLoading = false;
    },
    getNftDetailStart: (state, action) => {
      state.isLoading = true;
    },
    getNftDetailSuccess: (state, action) => {
      state.nftDetail = action.payload;
      state.isLoading = false;
    },
    getNftDetailFail: (state, action) => {
      state.isLoading = false;
    },
    getRewardActivityStart: (state, action) => {
      state.isLoading = true;
    },
    getRewardActivitySuccess: (state, action) => {
      const { items, total } = action.payload;
      state.listRewardActivity = items;
      state.total = total;
      state.isLoading = false;
    },
    getRewardActivityFail: (state, action) => {
      state.isLoading = false;
    },
    getListPoolOfNftStart: (state, action) => {
      state.isLoading = true;
    },
    getListPoolOfNftSuccess: (state, action) => {
      const { items } = action.payload;
      state.listPoolOfNft = items;
      state.isLoading = false;
    },
    getListPoolOfNftFail: (state, action) => {
      state.isLoading = false;
    },
    getListNftInPoolStart: (state, action) => {
      state.isLoading = true;
    },
    getListNftInPoolSuccess: (state, action) => {
      const { items } = action.payload;
      state.listNftInPool = items;
      state.isLoading = false;
    },
    getListNftInPoolFail: (state, action) => {
      state.isLoading = false;
    },
  },
});

export const {
  getNftMetaDataStart,
  getNftMetaDataSuccess,
  getNftMetaDataFail,
  getListNftRewardsStart,
  getListNftRewardsSuccess,
  getListNftRewardsFail,
  getNftDetailStart,
  getNftDetailSuccess,
  getNftDetailFail,
  getRewardActivityStart,
  getRewardActivitySuccess,
  getRewardActivityFail,
  getListPoolOfNftStart,
  getListPoolOfNftSuccess,
  getListPoolOfNftFail,
  getListNftInPoolStart,
  getListNftInPoolSuccess,
  getListNftInPoolFail,
} = nftSlice.actions;

export default nftSlice.reducer;
