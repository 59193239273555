import api from "./api";

export const getNftMetadataApi = (payload: { contractAddress: string; tokenId: string; type: number }) =>
  api.post("/nft/get-nft-info", payload);

export const registerNftApi = (payload: { contractAddress: string; tokenId: string; type: number }) =>
  api.post("/nft/register", payload);

export const getListNftRewardsApi = (payload: {
  keyword?: string;
  status?: string;
  type?: string;
  chainId?: string;
  limit?: number;
  offset?: number;
  sortField?: string;
  sortType?: number;
}) => api.get("/nft", payload);

export const getNftDetailByIdApi = (id: string) => api.get(`/nft/${id}`);

export const deleteNftByIdApi = (id: string) => api.delete(`/nft/${id}/delete`);

export const checkNftBalanceApi = (payload: {
  address: string;
  nftId: string;
  poolId: string;
  type: number;
  amount: number;
}) => api.post("/nft/check-balance-nft", payload);

export const createNftTransaction = (payload: {
  address: string;
  nftId: string;
  poolId: string;
  type: number;
  amount: number;
  txid: string;
  networkType: number;
}) => api.post("/nft/nft-transaction", payload);

export const updateNftTransaction = (payload: { transactionId: string; txid: string }) =>
  api.put("/nft/put-nft-transaction", payload);

export const getRewardActivityApi = (payload: {
  keyword: string;
  limit?: number;
  offset?: number;
  sortField?: string;
  sortType?: number;
  poolId: string;
}) => api.get("/activity-log/reward-list", payload);

export const getPoolListOfNftApi = (payload: {
  id: string;
  keyword?: string;
  status?: string;
  type?: string;
  limit?: number;
  offset?: number;
  sortField?: string;
  sortType?: number;
}) => api.get(`/nft/${payload.id}/pool`, payload);
