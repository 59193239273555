import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  getListNftRewardsApi,
  getNftDetailByIdApi,
  getNftMetadataApi,
  getRewardActivityApi,
  getPoolListOfNftApi,
} from "services/nft";
import {
  getListNftRewardsFail,
  getListNftRewardsStart,
  getListNftRewardsSuccess,
  getNftDetailFail,
  getNftDetailStart,
  getNftDetailSuccess,
  getNftMetaDataFail,
  getNftMetaDataStart,
  getNftMetaDataSuccess,
  getRewardActivityFail,
  getRewardActivityStart,
  getRewardActivitySuccess,
  getListPoolOfNftFail,
  getListPoolOfNftStart,
  getListPoolOfNftSuccess,
  getListNftInPoolSuccess,
  getListNftInPoolFail,
  getListNftInPoolStart,
} from "./slice";
import { deleteKeyNullOfObject } from 'utils';

function* getNftMetaDataSaga(action) {
  const { payload } = action;
  try {
    const res = yield call(getNftMetadataApi, payload);
    if (!res?.meta?.code) {
      yield put(getNftMetaDataSuccess(res));
    } else yield put(getNftMetaDataFail());
  } catch (error) {
    yield put(getNftMetaDataFail());
  }
}

function* getListNftRewardsSaga(action) {
  const { payload } = action;
  const convertParams = deleteKeyNullOfObject(payload);
  try {
    const res = yield call(getListNftRewardsApi, convertParams);
    if (!res?.meta?.code) {
      const { total, items } = res;
      yield put(getListNftRewardsSuccess({ items, total }));
    } else {
      yield put(getListNftRewardsFail());
    }
  } catch (error) {
    yield put(getListNftRewardsFail());
  }
}

function* getListNftInPoolSaga(action) {
  const { payload } = action;
  try {
    const res = yield call(getListNftRewardsApi, payload);
    if (!res?.meta?.code) {
      const { total, items } = res;
      yield put(getListNftInPoolSuccess({ items }));
    } else {
      yield put(getListNftInPoolFail());
    }
  } catch (error) {
    yield put(getListNftInPoolFail());
  }
}

function* getNftDetailByIdSaga(action) {
  const { payload } = action;
  try {
    const res = yield call(getNftDetailByIdApi, payload.id);
    if (!res?.meta?.code) {
      yield put(getNftDetailSuccess(res));
    } else yield put(getNftDetailFail());
  } catch (error) {
    yield put(getNftDetailFail());
  }
}

function* getListRewardActivitySaga(action) {
  const { payload } = action;
  try {
    const res = yield call(getRewardActivityApi, payload);
    if (!res?.meta?.code) {
      const { total, items } = res;
      yield put(getRewardActivitySuccess({ items, total }));
    } else {
      yield put(getRewardActivityFail());
    }
  } catch (error) {
    yield put(getRewardActivityFail());
  }
}
function* getListPoolOfNftSaga(action) {
  const { payload } = action;
  try {
    const res = yield call(getPoolListOfNftApi, payload);
    if (!res?.meta?.code) {
      const { total, items } = res;
      yield put(getListPoolOfNftSuccess({ items }));
    } else {
      yield put(getListPoolOfNftFail());
    }
  } catch (error) {
    yield put(getListPoolOfNftFail());
  }
}

export default function* watchNftSaga() {
  yield takeLatest(getNftMetaDataStart, getNftMetaDataSaga);
  yield takeLatest(getListNftRewardsStart, getListNftRewardsSaga);
  yield takeLatest(getNftDetailStart, getNftDetailByIdSaga);
  yield takeLatest(getRewardActivityStart, getListRewardActivitySaga);
  yield takeLatest(getListPoolOfNftStart, getListPoolOfNftSaga);
  yield takeLatest(getListNftInPoolStart, getListNftInPoolSaga);
}
