import * as React from "react";

function IconSearch(props: any) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.421 13.6a6.667 6.667 0 111.178-1.178l4.49 4.489a.833.833 0 11-1.178 1.178L12.42 13.6zm.912-5.267a5 5 0 11-10 0 5 5 0 0110 0z"
        fill="#D94036"
        opacity={0.7}
      />
    </svg>
  );
}

export default IconSearch;
