import { combineReducers } from "redux";
import authReducer from "./auth/slice";
import notificationSlice from "./notification/slice";
import userReducer from "./user/slice";
import poolReducer from "./pool/slice";
import nftReducer from "./nft/slice";
import tokenReducer from "./token/slice";

export default combineReducers({
  auth: authReducer,
  notification: notificationSlice,
  user: userReducer,
  pool: poolReducer,
  nft: nftReducer,
  token: tokenReducer,
});
