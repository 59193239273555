import * as React from "react";

function IconArrowRight(props: any) {
  return (
    <svg width={6} height={10} viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.793.793a1 1 0 000 1.414L3.586 5 .793 7.793a1 1 0 001.414 1.414l3.5-3.5a1 1 0 000-1.414l-3.5-3.5a1 1 0 00-1.414 0z"
        fill="#D94036"
      />
    </svg>
  );
}

export default IconArrowRight;
