import React from "react";

const IconUserLogin = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 16.9995V13.2661" stroke="#82858A" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="square" />
      <path
        d="M7.18229 10.644L2.19143 13.1934C1.45714 13.5673 1 14.2857 1 15.0665V16.9999H17V15.0665C17 14.2857 16.5429 13.5673 15.8086 13.1934L10.8177 10.644"
        stroke="#82858A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M4.42853 5.26675C4.42853 2.91044 6.47538 1 8.99996 1C11.5245 1 13.5714 2.91044 13.5714 5.26675C13.5714 8.46681 11.5245 11.1335 8.99996 11.1335C6.47538 11.1335 4.42853 8.46681 4.42853 5.26675Z"
        stroke="#82858A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M4.45038 4.84657C5.11838 5.11325 5.89038 5.26685 6.71438 5.26685C8.21209 5.26685 9.53781 4.76017 10.3715 3.98096C11.1012 4.76017 12.2607 5.26685 13.5715 5.26685"
        stroke="#82858A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default IconUserLogin;
