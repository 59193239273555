import { put, takeLatest } from "redux-saga/effects";
import { addToken, deleteTokenAPI, getTokenDetail } from "services/token";
import {
  handelAddTokenRequest,
  handelAddTokenResponse,
  handelGetTokenDetailRequest,
  handelGetTokenDetailResponse,
  tokenDeleteRequest,
} from "./slice";

function* addTokenSaga({ payload }) {
  const { data, callbackSuccess, callbackError } = payload;

  try {
    const response = yield addToken(data);
    if (!response?.meta?.code) {
      callbackSuccess(response._id);
      yield put(handelAddTokenResponse(response.data));
    } else {
      callbackError(response.meta.msg);
      yield put(handelAddTokenResponse({}));
    }
  } catch (error) {
    console.error(error);
    yield put(handelAddTokenResponse({}));
  } finally {
    // yield put(handelAddTokenResponse({}));
  }
}

function* getTokenDetailSaga({ payload }) {
  const { data } = payload;

  try {
    const response = yield getTokenDetail(data);
    if (response?.meta?.code === 0) {
      yield put(handelGetTokenDetailResponse(response.data));
    }
  } catch (error) {
    console.error(error);
    yield put(handelGetTokenDetailResponse({}));
  } finally {
    // yield put(handelGetTokenDetailResponse({}));
  }
}

function* tokenDeleteSaga({ payload }) {
  const { id, callbackSuccess, callbackError } = payload;

  try {
    const response = yield deleteTokenAPI({ id });
    if (response.meta.code === 0) {
      callbackSuccess();
    } else callbackError();
  } catch (error) {
    console.error(error);
    callbackError();
  } finally {
    // callbackFinish();
    // yield call(callback);
  }
}

function* watchToken() {
  yield takeLatest(handelAddTokenRequest, addTokenSaga);
  yield takeLatest(handelGetTokenDetailRequest, getTokenDetailSaga);
  yield takeLatest(tokenDeleteRequest, tokenDeleteSaga);
}

export default watchToken;
