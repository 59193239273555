import { Badge, Button, Dropdown, Select } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { Header } from "antd/lib/layout/layout";
import { withRouter, useHistory } from "react-router-dom";

// import i18n, { getCurrentLanguage } from "i18n/i18n";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWeb3React } from "@web3-react/core";
import { useDispatch } from "react-redux";

// import IconArrowDown from "resources/images/IconArrowDown";
import { useAppSelector } from "utils/customHooks";
// import { languages } from "utils/languages";
import UserDropdown from "./UserDropdown";
import ImageAvatr from "resources/images/icon-avatar.png";
import { useConnectWallet } from "common/customHook";
import { connectWalletStart } from "redux/auth/slice";
import ModalComponent from "components/Modal";
import NetworkNotice from "./NetworkNotice";
import { BSCTestnetChainIdDec, BSCMainnetChainId, BSCMainnetChainIdDec } from "common/constant";
import { walletConnect } from "common/connectors";
import ConnectWallet from "components/ConnectWallet";
import { connectWalletModalRequest } from "redux/user/slice";

const HeaderCommon: FC<any> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { account, active, deactivate, chainId } = useWeb3React();
  const { connectInjected, connectWalletConnect } = useConnectWallet();
  const [visibleWrongNetwork, setVisibleWrongNetwork] = useState(false);
  const dispatch = useDispatch();

  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const visible = useAppSelector((state: any) => state.user.visibleConnectWallet);
  const chains = useAppSelector((state: any) => state.auth.chains);
  const walletAddress = useAppSelector((state: any) => state.auth.walletAddress);
  const networkCreatePool = useAppSelector((state) => state.pool.network);

  useEffect(() => {
    if (account && active) {
      dispatch(connectWalletStart({ address: account }));
    }
  }, [account, active]);

  useEffect(() => {
    checkConnectWallet();
  }, [active]);

  const checkConnectWallet = async () => {
    let checkCOnnect = await localStorage.getItem("walletconnect");
    if (checkCOnnect) {
      setTimeout(() => connectWalletConnect(), 1500);
    } else if (walletAddress) connectInjected();
  };

  // check chainId
  useEffect(() => {
    const chainSupports = networkCreatePool ? [networkCreatePool] : chains.map((item: any) => item.chainId);

    if (chainId && chainSupports.includes(chainId)) {
      if (account) {
        dispatch(connectWalletStart({ address: account }));
        setVisibleWrongNetwork(false);
      }
    } else {
      if (!chainId || !walletAddress) return;
      else {
        if (account) setVisibleWrongNetwork(true);
        else handleDisconnect();
      }
    }
  }, [account, chainId, chains, networkCreatePool]);

  useEffect((): any => {
    walletConnect.on("Web3ReactDeactivate", () => {
      handleDisconnect();
    });

    return () => walletConnect.removeListener("Web3ReactDeactivate", () => console.log("Web3ReactDeactivate"));
  }, []);

  useEffect(() => {
    if (account && active) {
      dispatch(connectWalletModalRequest({ data: false }));
    }
  }, [account, active]);

  const handleDisconnect = () => {
    if (walletAddress) {
      deactivate();
      dispatch(connectWalletStart({ address: "" }));
      localStorage.removeItem("walletconnect");
    }
  };

  const hanhdleOnPopup = () => {
    dispatch(connectWalletModalRequest({ data: null }));
  };

  return (
    <Header className="header">
      <div className="header__right">
        {/* <Dropdown overlay={ListNotification(t)} trigger={["click"]} className="notification-dropdown">
          <Badge count={unreadCount} overflowCount={99}>
            <IconBell />
          </Badge>
        </Dropdown> */}

        <Dropdown
          overlay={UserDropdown(t, currentUser, history, deactivate, hanhdleOnPopup)}
          trigger={["click"]}
          className="header__profile"
        >
          <Button>
            <Avatar src={ImageAvatr} alt={currentUser.username}>
              {currentUser.username}
            </Avatar>
            <div className="username" title={currentUser.username}>
              {/* {currentUser.username} */}
              Admin
            </div>
          </Button>
        </Dropdown>
      </div>
      <ModalComponent visible={visibleWrongNetwork} wrapClassName="model-metamask_notfound" showCloseIcon={false}>
        <NetworkNotice />
      </ModalComponent>

      <ModalComponent visible={visible} onClose={hanhdleOnPopup} maskClosable showCloseIcon={false} width={460}>
        <ConnectWallet hanhdleOnPopup={hanhdleOnPopup} />
      </ModalComponent>
    </Header>
  );
};
export default withRouter(HeaderCommon);
