import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tokenDetail: {},
  loading: false,
};

export const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    handelAddTokenRequest: (state, action) => {
      return { ...state, loading: true };
    },
    handelAddTokenResponse: (state, action) => ({
      ...state,
      loading: false,
    }),
    handelGetTokenDetailRequest: (state, action) => {
      return { ...state, loading: true };
    },
    handelGetTokenDetailResponse: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        loading: false,
        tokenDetail: { ...payload },
      };
    },
    tokenDeleteRequest: (state, action) => ({
      ...state,
    }),

    tokenDeleteResponse: (state, action) => {
      const { payload } = action;

      return { ...state };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  handelAddTokenRequest,
  handelAddTokenResponse,
  handelGetTokenDetailRequest,
  handelGetTokenDetailResponse,
  tokenDeleteRequest,
  tokenDeleteResponse,
} = tokenSlice.actions;

export default tokenSlice.reducer;
