import React, { useState, useEffect } from "react";
import { Button, Input, Select } from "antd";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";

import IconSearch from "resources/images/IconSearch";
import TableComponent from "components/Table";
import IconPlus from "resources/images/IconPlus";
import { DEFAULT_SEARCH_STATUS, POOL_REWARD_TYPE } from "common/constant";
import { columnsPoolManagement, columnsPoolNftManagement } from "./column";
import { getListPoolRequest, poolUpdateStatusRequest } from "redux/pool/slice";
import { useAppDispatch, useAppSelector } from "utils/customHooks";
import ModalConfirm from "components/ModalConfirm";
import { connectWalletModalRequest } from "redux/user/slice";
import classNames from "classnames";
import IconRefresh from "resources/images/IconRefresh";
import IconAdd from "resources/images/IconAdd";
import Item from "antd/lib/list/Item";

const { Option } = Select;

const PoolManagement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();
  const { account, active } = useWeb3React();
  const [visibleCofirm, setVisibleCofirm] = useState(false);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState("");
  const [visibleCofirmChangeShow, setVisibleCofirmChangeShow] = useState(false);
  const [statusChangeShow, setStatusChangeShow] = useState({ id: "", value: false });

  const listChain = useAppSelector((state) => state.auth.chains);

  const [dataSearchPool, setDataSearchPool] = useState({
    statusNftStaking: null,
    page: 0,
    chainId: null,
    sortField: "",
    sortOrder: "",
    rewardType: POOL_REWARD_TYPE.token,
    modelType: null,
  });

  const pool = useAppSelector((state) => state.pool.pool);
  const loadingUpdateStatus = useAppSelector((state) => state.pool.loadingUpdateStatus);
  const loadingListPool = useAppSelector((state) => state.pool.loadingListPool);

  const handleRedirectCreatePool = () => {
    if (account && active) history.push("/pool-create");
    else dispatch(connectWalletModalRequest({ data: null }));
  };

  const handleChangeShowPool = (id, check) => {
    setStatusChangeShow({ id, value: check });
    setVisibleCofirmChangeShow(!visibleCofirmChangeShow);
  };

  const handleConfirmChangeShowPool = () => {
    dispatch(poolUpdateStatusRequest({ id: statusChangeShow.id, callbackSuccess: callbackSuccess }));
  };

  const callbackSuccess = () => {
    setVisibleCofirmChangeShow(!visibleCofirmChangeShow);
    dispatch(
      getListPoolRequest({
        data: {
          keyword: !keyword ? (dataSearchPool.keyword ? dataSearchPool.keyword : "") : keyword,
          status: dataSearchPool.statusNftStaking,
          limit,
          chainId: dataSearchPool?.chainId,
          offset: dataSearchPool.page * limit,
          sortField: dataSearchPool.sortOrder ? dataSearchPool.sortField : "",
          sortType: dataSearchPool.sortOrder,
          rewardType: dataSearchPool.rewardType,
        },
      })
    );
  };

  // useEffect(() => {
  //   setDataSearchPool({
  //     ...dataSearchPool,
  //     statusNftStaking: dataSearch.status ? dataSearch.status : DEFAULT_SEARCH_STATUS,
  //     page: dataSearch.offset ? dataSearch.offset / limit : dataSearchPool.page,
  //   });
  //   setKeyword(!keyword ? (dataSearch.keyword ? dataSearch.keyword : "") : keyword);
  // }, []);

  useEffect(() => {
    dispatch(
      getListPoolRequest({
        data: {
          keyword: !keyword ? (dataSearchPool.keyword ? dataSearchPool.keyword : "") : keyword,
          status: dataSearchPool.statusNftStaking,
          limit,
          chainId: dataSearchPool?.chainId,
          offset: dataSearchPool.page * limit,
          sortField: dataSearchPool.sortOrder ? dataSearchPool.sortField : "",
          sortType: dataSearchPool.sortOrder,
          rewardType: dataSearchPool.rewardType,
          modelType: dataSearchPool.modelType,
        },
      })
    );
  }, [dataSearchPool]);

  const handleChangeKeywork = (e) => {
    setKeyword(e.target.value);
  };

  const onSelectPoolType = (poolType) => {
    setDataSearchPool({ ...dataSearchPool, rewardType: poolType });
  };

  const handleSearch = (data) => {
    if (data.key === "Enter") {
      setDataSearchPool({ ...dataSearchPool, page: 0 });
      setKeyword(keyword.trim());

      dispatch(
        getListPoolRequest({
          data: {
            keyword: keyword,
            status: dataSearchPool.statusNftStaking,
            limit,
            chainId: dataSearchPool?.chainId,
            offset: dataSearchPool.page * limit,
            rewardType: dataSearchPool.rewardType,
          },
        })
      );
    }
  };

  const onClickSearch = () => {
    dispatch(
      getListPoolRequest({
        data: {
          keyword: keyword,
          status: dataSearchPool.statusNftStaking,
          limit,
          chainId: dataSearchPool?.chainId,
          offset: dataSearchPool.page * limit,
          rewardType: dataSearchPool.rewardType,
        },
      })
    );
  };

  const handleChangeBlur = () => {
    setKeyword(keyword.trim());
  };

  const onChangePagination = (page) => {
    setDataSearchPool({ ...dataSearchPool, page: page - 1 });
  };

  const listPool = (data) => {
    return data && dataSearchPool?.rewardType === POOL_REWARD_TYPE.token
      ? data?.map((e, index) => ({ ...e, index: limit * dataSearchPool.page + index + 1 }))
      : data?.map((e, index) => ({
          ...e,
          index: limit * dataSearchPool.page + index + 1,
          totalDeposited: e?.nfts?.totalDeposited,
          totalClaim: e?.nfts?.totalClaim,
        }));
  };

  const onChangeTable = (pagination, filter, sorter, extra) => {
    let { order, field } = sorter;

    let data = "";
    if (order) {
      switch (order) {
        case "ascend":
          data = 1;
          break;
        case "descend":
          data = -1;
          break;
      }
    } else data = "";

    setDataSearchPool({ ...dataSearchPool, sortField: field, sortOrder: data });
  };

  function onChange(value) {
    setDataSearchPool({ ...dataSearchPool, statusNftStaking: value });
  }

  const onResetSearch = () => {
    setDataSearchPool({ ...dataSearchPool, statusNftStaking: DEFAULT_SEARCH_STATUS });
    setKeyword("");
  };

  const onChangeMechanism = (value) => {
    setDataSearchPool({ ...dataSearchPool, modelType: value });
  };

  const onChangeChainId = (value) => {
    setDataSearchPool({ ...dataSearchPool, chainId: value });
  };

  return (
    <div className="pool-management-wrap">
      <div className="pool-type">
        <span
          className={classNames({
            "pool-type__name": true,
            "pool-type__name--active": dataSearchPool.rewardType === POOL_REWARD_TYPE.token,
          })}
          onClick={() => onSelectPoolType(POOL_REWARD_TYPE.token)}
        >
          Token Rewards
        </span>
        <span
          className={classNames({
            "pool-type__name": true,
            "pool-type__name--active": dataSearchPool.rewardType === POOL_REWARD_TYPE.nft,
          })}
          onClick={() => onSelectPoolType(POOL_REWARD_TYPE.nft)}
        >
          NFT Random Rewards
        </span>
      </div>
      <div className="header-pool">
        <div className="search">
          {/* <SwitchComponent
            data={DATA_SEARCH_NFT_STAKING_STATUS}
            checked={statusNftStaking}
            name="statusNftStaking"
            onChange={setStatusNftStaking}
          /> */}
          <Input
            placeholder="Search by pool name"
            onKeyDown={handleSearch}
            value={keyword}
            onChange={handleChangeKeywork}
            suffix={<IconSearch className="cursor-pointer" onClick={onClickSearch} />}
            maxLength={256}
            onBlur={handleChangeBlur}
            className="search-input"
          />
          <Select
            onChange={onChangeMechanism}
            value={dataSearchPool.modelType}
            style={{ width: 196 }}
            className="search-select"
            getPopupContainer={(trigger) => trigger.parentElement}
            placeholder="Select Mechanism"
          >
            <Option value={""}>All</Option>
            <Option value={2}>Linear</Option>
            <Option value={1}>Allocation</Option>
          </Select>
          <Select
            onChange={onChangeChainId}
            value={dataSearchPool.chainId}
            style={{ width: 228 }}
            className="search-select"
            getPopupContainer={(trigger) => trigger.parentElement}
            placeholder="Network"
          >
            <Option value={""}>All Chain</Option>
            {listChain?.map((chain) => {
              return (
                <Option key={chain?.chainId} value={chain?.chainId}>
                  {chain?.chainName}
                </Option>
              );
            })}
          </Select>
          <Select
            onChange={onChange}
            value={dataSearchPool.statusNftStaking}
            style={{ width: 196 }}
            className="search-select"
            getPopupContainer={(trigger) => trigger.parentElement}
            placeholder="Select status"
          >
            <Option value={0}>All pools</Option>
            <Option value={4}>Draft</Option>
            <Option value={2}>Waiting for public</Option>
            <Option value={5}>Public</Option>
            <Option value={1}>Live</Option>
            <Option value={3}>Finished</Option>
          </Select>
          <IconRefresh className="cursor-pointer refresh-icon" onClick={onResetSearch} />
        </div>
        <Button onClick={handleRedirectCreatePool} icon={<IconAdd />}>
          Create Pool
        </Button>
      </div>
      <TableComponent
        showPagination
        dataSource={listPool(pool?.records)}
        rowKey={(e) => e._id}
        onChangePagination={onChangePagination}
        columns={
          dataSearchPool.rewardType === POOL_REWARD_TYPE.token
            ? columnsPoolManagement({ history, handleChangeShowPool, listChain })
            : columnsPoolNftManagement({ history, handleChangeShowPool, listChain })
        }
        total={pool?.total}
        current={dataSearchPool.page + 1}
        onChangeTable={onChangeTable}
        loading={loadingListPool}
      />
      <ModalConfirm
        visible={visibleCofirm}
        title="Error"
        content={"You must connect wallet before creating the pool. Please connect it and try again. "}
        onConfirm={() => setVisibleCofirm(false)}
        cancelText={"Cancel"}
        confirmText={"Close"}
        isErr
      />

      <ModalConfirm
        visible={visibleCofirmChangeShow}
        title="Are you sure?"
        content={
          statusChangeShow.value
            ? "This pool will be shown on the users' web interface. Are you sure to enable it?"
            : "This pool will be hidden from the users' web interface. Are you sure to disable it?"
        }
        onConfirm={handleConfirmChangeShowPool}
        onCancel={handleChangeShowPool}
        cancelText={"Cancel"}
        confirmText={"Confirm"}
        loadingConfirm={loadingUpdateStatus}
        showCloseIcon={true}
      />
    </div>
  );
};

export default PoolManagement;
