import React from "react";

const nodataTable = () => {
  return (
    <svg width="274" height="126" viewBox="0 0 274 126" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M94.6702 0.812744V115.47H87.6926L8.46772 15.8906V115.487H0V0.812744H7.13533L86.1849 100.409V0.812744H94.6702Z"
        fill="#C52B8B"
      />
      <path
        d="M182.661 108.674C173.474 103.591 166.251 96.6398 160.992 87.7868C155.732 78.9337 153.103 69.0605 153.103 58.1326C153.103 47.2219 155.732 37.3314 160.992 28.4957C166.251 19.6427 173.474 12.6916 182.661 7.60807C191.847 2.54179 202.138 0 213.551 0C224.947 0 235.255 2.5072 244.442 7.53891C253.628 12.5706 260.851 19.5043 266.111 28.3401C271.37 37.1931 274 47.1182 274 58.1499C274 69.1816 271.37 79.1239 266.111 87.9597C260.851 96.8127 253.628 103.746 244.442 108.761C235.255 113.793 224.964 116.3 213.551 116.3C202.138 116.3 191.847 113.758 182.661 108.674ZM239.954 102.052C247.808 97.6772 254.014 91.66 258.555 83.9481C263.095 76.2536 265.357 67.6427 265.357 58.1499C265.357 48.6571 263.078 40.0461 258.555 32.3516C254.014 24.6571 247.808 18.6225 239.954 14.2478C232.1 9.8732 223.299 7.69453 213.551 7.69453C203.804 7.69453 194.985 9.8732 187.061 14.2478C179.137 18.6225 172.913 24.6571 168.372 32.3516C163.832 40.0461 161.57 48.6571 161.57 58.1499C161.57 67.6599 163.832 76.2536 168.372 83.9481C172.913 91.6427 179.137 97.6772 187.061 102.052C194.968 106.427 203.804 108.605 213.551 108.605C223.281 108.605 232.082 106.409 239.954 102.052Z"
        fill="#C52B8B"
      />
      <path d="M107.33 5.87988L93.5674 15.926L94.6193 16.1681L107.33 17.8453V5.87988Z" fill="#C52B8B3b" />
      <path
        d="M166.252 1.14185H108.327C106.189 2.04098 90.4278 13.9199 88.7974 15.8565V107.188C88.7974 108.589 89.9544 109.73 91.3745 109.73H166.269C167.689 109.73 168.846 108.589 168.846 107.188V3.68363C168.829 2.28306 167.672 1.14185 166.252 1.14185ZM93.5659 15.9257L107.328 5.8796V17.845L94.6178 16.1851L93.4432 16.0295L93.5659 15.9257ZM165.112 106.064H92.514V19.5914L108.959 21.7528C109.046 21.7701 109.116 21.7701 109.204 21.7701C109.66 21.7701 110.081 21.6145 110.431 21.3205C110.834 20.9747 111.062 20.4733 111.062 19.9372V4.80755H165.13V106.064H165.112Z"
        fill="#C52B8B"
      />
      <path
        d="M118.025 35.1541L115.448 32.8544L118.025 30.5547C118.533 30.1052 118.568 29.3271 118.113 28.8256C117.657 28.3242 116.868 28.2896 116.359 28.7392L113.589 31.2118L110.819 28.7392C110.311 28.2896 109.522 28.3242 109.066 28.8256C108.61 29.3271 108.646 30.1052 109.154 30.5547L111.731 32.8544L109.154 35.1541C108.646 35.6037 108.61 36.3818 109.066 36.8833C109.312 37.1426 109.645 37.2809 109.978 37.2809C110.276 37.2809 110.574 37.1772 110.802 36.9697L113.572 34.4971L116.342 36.9697C116.587 37.1772 116.868 37.2809 117.166 37.2809C117.499 37.2809 117.832 37.1426 118.077 36.8833C118.568 36.3818 118.533 35.6037 118.025 35.1541Z"
        fill="#C52B8B"
      />
      <path
        d="M149.386 34.1679L146.809 31.8682L149.386 29.5685C149.895 29.119 149.93 28.3409 149.474 27.8394C149.018 27.338 148.229 27.3034 147.721 27.753L144.951 30.2256L142.181 27.753C141.672 27.3034 140.883 27.338 140.428 27.8394C139.972 28.3409 140.007 29.119 140.515 29.5685L143.092 31.8682L140.515 34.1679C140.007 34.6175 139.972 35.3956 140.428 35.8971C140.673 36.1564 141.006 36.2947 141.339 36.2947C141.637 36.2947 141.935 36.191 142.163 35.9835L144.933 33.5109L147.703 35.9835C147.949 36.191 148.229 36.2947 148.527 36.2947C148.86 36.2947 149.193 36.1564 149.439 35.8971C149.93 35.3956 149.895 34.6175 149.386 34.1679Z"
        fill="#C52B8B"
      />
      <path
        d="M115.655 67.4865C116.392 68.2473 117.268 68.6104 118.303 68.6104C120.284 68.6104 121.318 67.7458 122.019 65.5326C122.773 63.1291 122.949 59.4807 123.194 54.864C123.247 53.8611 123.299 52.8236 123.352 51.7516C122.913 51.8553 122.458 51.9937 122.019 52.1147C121.02 52.4086 120.091 52.7545 119.197 53.1349C119.232 53.2559 119.267 53.3769 119.267 53.498L119.53 63.8726C119.547 64.547 119.004 65.1003 118.32 65.1176C118.303 65.1176 118.303 65.1176 118.285 65.1176C117.619 65.1176 117.058 64.5988 117.04 63.9245L116.795 54.2415C115.182 55.0888 113.867 55.9533 112.868 56.6623C112.868 59.3424 113.306 65.0657 115.655 67.4865Z"
        fill="#C52B8B"
      />
      <path
        d="M149.351 56.3173C144.495 52.3058 139.34 49.8158 134.063 48.9167C129.821 48.1905 125.49 48.5017 121.195 49.8158C113.937 52.0464 109.326 56.3864 109.134 56.5766C108.643 57.0435 108.625 57.8216 109.098 58.3058C109.344 58.5478 109.659 58.6862 109.993 58.6862C110.15 58.6862 110.308 58.6516 110.466 58.5997C110.501 59.3259 110.571 60.1559 110.676 61.0377C111.167 64.7726 112.237 67.5219 113.85 69.1991C115.059 70.4441 116.567 71.0839 118.285 71.0839C121.371 71.0839 123.352 69.5104 124.368 66.2942C125.21 63.5968 125.403 59.8101 125.648 55.0205C125.701 53.8101 125.771 52.5824 125.841 51.2683C133.415 50.1271 140.76 52.4441 147.738 58.202C148.264 58.6343 149.035 58.5651 149.474 58.0464C149.947 57.5104 149.877 56.7496 149.351 56.3173ZM123.194 54.8648C122.966 59.4815 122.773 63.13 122.019 65.5334C121.318 67.7467 120.284 68.6112 118.303 68.6112C117.251 68.6112 116.392 68.2481 115.655 67.4873C113.306 65.0666 112.868 59.3432 112.885 56.6977C113.885 55.9887 115.199 55.1069 116.812 54.2769L117.058 63.9599C117.075 64.617 117.619 65.153 118.303 65.153C118.32 65.153 118.32 65.153 118.338 65.153C119.021 65.1357 119.565 64.5824 119.547 63.9081L119.284 53.5334C119.284 53.4124 119.249 53.2741 119.214 53.1703C120.091 52.7899 121.037 52.4441 122.037 52.1501C122.475 52.0118 122.931 51.8908 123.369 51.787C123.299 52.8245 123.246 53.8619 123.194 54.8648Z"
        fill="#C52B8B"
      />
      <path d="M172.335 126L82.2932 116.732L87.5001 74.2476L177.542 83.5156L172.335 126Z" fill="#C52B8B3b" />
      <path
        d="M91.5675 54.7098C91.1643 54.1738 90.3929 54.0528 89.8319 54.4505C89.6917 54.5542 86.536 56.8539 83.1875 60.8309C81.2064 63.1652 79.576 65.6032 78.3137 68.0585C76.7359 71.1709 75.7541 74.3352 75.421 77.4649C74.7198 84.0528 76.806 90.4851 81.6096 96.6407C81.6096 96.7444 81.5921 96.8482 81.5921 96.9519C81.5921 99.3035 83.5206 101.206 85.9049 101.206C88.2891 101.206 90.2176 99.3035 90.2176 96.9519C90.2176 94.6003 88.2891 92.6983 85.9049 92.6983C84.6601 92.6983 83.5206 93.217 82.7317 94.0643C78.9098 88.7732 77.2794 83.3093 77.8579 77.7588C78.5416 71.3265 82.1181 65.8971 85.0107 62.4735C88.1839 58.7041 91.252 56.4735 91.2695 56.4562C91.848 56.0239 91.9708 55.2631 91.5675 54.7098Z"
        fill="#C52B8B"
      />
      <path
        d="M182.117 77.4649C181.784 74.3352 180.819 71.1709 179.224 68.0585C177.962 65.6032 176.331 63.1652 174.35 60.8309C170.984 56.8539 167.829 54.5542 167.706 54.4505C167.162 54.0528 166.374 54.1738 165.97 54.7098C165.567 55.2458 165.69 56.0239 166.233 56.4216C166.268 56.4389 169.284 58.6522 172.457 62.3871C175.367 65.8107 178.961 71.2574 179.662 77.707C180.206 82.877 178.838 87.9951 175.543 92.9577C175.069 92.7675 174.543 92.681 174 92.681C171.615 92.681 169.687 94.583 169.687 96.9346C169.687 99.2862 171.615 101.188 174 101.188C176.384 101.188 178.312 99.2862 178.312 96.9346C178.312 96.0182 178.014 95.1536 177.506 94.462C181.188 88.9807 182.73 83.2747 182.117 77.4649Z"
        fill="#C52B8B"
      />
      <path
        d="M110.449 90.8652C112.027 92.577 112.676 94.8075 112.413 97.5741C112.132 100.323 111.08 102.433 109.24 103.885C107.399 105.338 104.752 105.891 101.28 105.563L95.3196 104.992L97.0727 87.2513L103.244 87.8392C106.47 88.1332 108.871 89.1534 110.449 90.8652ZM109.345 97.3493C109.748 93.2859 107.592 91.0381 102.876 90.5885L99.8427 90.2946L98.6506 102.45L102.017 102.779C104.191 102.986 105.909 102.64 107.171 101.724C108.416 100.808 109.152 99.3551 109.345 97.3493Z"
        fill="#C52B8B"
      />
      <path
        d="M118.389 103.107L116.198 106.963L112.954 106.651L122.632 89.6715L125.875 89.9828L132.046 108.484L128.803 108.173L127.4 103.971L118.389 103.107ZM126.419 101.084L123.876 93.4583L119.897 100.461L126.419 101.084Z"
        fill="#C52B8B"
      />
      <path
        d="M141.374 94.2539L139.902 109.245L136.869 108.951L138.341 93.96L132.889 93.4412L133.152 90.7092L147.09 92.0406L146.827 94.7726L141.374 94.2539Z"
        fill="#C52B8B"
      />
      <path
        d="M150.177 106.168L147.986 110.024L144.743 109.712L154.42 92.7325L157.663 93.0438L163.834 111.545L160.591 111.234L159.189 107.032L150.177 106.168ZM158.224 104.145L155.682 96.5193L151.703 103.505L158.224 104.145Z"
        fill="#C52B8B"
      />
    </svg>
  );
};

export default nodataTable;
