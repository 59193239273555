import { useWeb3React } from "@web3-react/core";
import { Button, message } from "antd";
import {
  erc721Collection,
  FORMAT_DATE_TIME_API,
  POOL_REWARD_TYPE,
  PUBLISH_TEXT,
  signatureUtils,
  STAKING_MECHANISM_TYPE,
  TIME_SECONDS_IN_DAY,
  TYPE_OF_ANT_DESIGN,
} from "common/constant";
import LoadingContract from "components/LoadingContract";
import showMessage from "components/Message";
import ModalConfirm from "components/ModalConfirm";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { handleSetNetworkPool, poolUpdateRequest, poolUpdateTxidRequest } from "redux/pool/slice";
import { convertPrice } from "utils";
import BaseWalletService from "utils/baseWallet";
import { useAppSelector } from "utils/customHooks";
import { requestSupportNetwork } from "utils/setupNetwork";

const UpdatePool = ({ poolDetail, values, errors, isDraft, poolType, selectedNftRewards, setIsSelectedNftEmpty }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { account, library, chainId } = useWeb3React();
  const wallet = new BaseWalletService().getInstance();
  const dispatch = useDispatch();
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [visibleLoading, setVisibleLoading] = useState(false);

  const listPoolType = useAppSelector((state) => state.pool.poolTypes);
  const chains = useAppSelector((state) => state.auth.chains);

  const poolAddress = useMemo(() => {
    if (values.network) {
      const networkSelected = chains.find((item) => item.chainId === values.network);
      return networkSelected?.poolContractAddress;
    }
    return "";
  }, [chains, values]);

  const renderCurrency = (type, token, listPoolType) => {
    const stakeCurrency = listPoolType.find((option) => option.chainId === values.network && option.key === type);
    const { name, key, tokenId } = stakeCurrency;
    const rewardCurrency = listPoolType.find((option) => option.chainId === values.network && option.key === token);
    return {
      stakeName: name,
      stakeKey: key,
      rewardName: rewardCurrency?.name,
      rewardKey: rewardCurrency?.key,
      stakeToken: tokenId,
      rewardToken: rewardCurrency?.tokenId,
    };
  };

  const handleTurnOnPopupConfirm = async () => {
    if (!poolDetail.isDraft && !Object.keys(errors).length) {
      setVisibleConfirm(true);
      return;
    }
    if (
      poolType === POOL_REWARD_TYPE.nft &&
      Object.keys(poolDetail).length &&
      poolDetail.isDraft &&
      !selectedNftRewards.length
    ) {
      setIsSelectedNftEmpty(true);
    } else if (
      values.publish !== PUBLISH_TEXT.NOT_NOW &&
      poolType === POOL_REWARD_TYPE.nft &&
      selectedNftRewards.length
    ) {
      const tierArray = new Set(selectedNftRewards.map((nft) => nft?.tier || nft?.detail?.tier));
      let tierMissing = [];
      [1, 2, 3, 4].forEach((tier) => {
        if (!tierArray.has(tier)) tierMissing.push(tier);
      });
      if (tierMissing.join(", ")) {
        showMessage(TYPE_OF_ANT_DESIGN.ERROR, "message.E20_1", { tier: tierMissing.join(", ") });
      } else
        setTimeout(() => {
          if (!Object.keys(errors).length && values.endDate) setVisibleConfirm(true);
        }, 0);
    } else
      setTimeout(() => {
        if (!Object.keys(errors).length && values.endDate) setVisibleConfirm(true);
      }, 0);
  };

  const switchNetwork = async () => {
    const networkSupports = chains.reduce(
      (object, item) => ({
        ...object,
        [item.chainId]: {
          chainId: `0x${item.chainId.toString(16)}`,
          chainName: item?.chainName,
          nativeCurrency: item?.nativeCurrency,
          rpcUrls: item?.rpcUrls,
          blockExplorerUrls: [item?.blockExplorerUrls],
        },
      }),
      {}
    );
    // check user choose which network then use id network.
    await requestSupportNetwork("metamask", networkSupports, values.network);
  };

  const handleCancelUpdate = () => {
    dispatch(handleSetNetworkPool({ network: "" }));
    setVisibleConfirm(false);
  };

  const handleUpdatePool = () => {
    if (values.network !== chainId) {
      dispatch(handleSetNetworkPool({ network: values.network }));
      return switchNetwork();
    }

    setVisibleLoading(true);

    if (!poolDetail.isDraft) {
      handleUpdatePoolContract();
      return;
    }

    if (values && poolDetail?.isDraft && isDraft) {
      callbackUpdateContractSuccess(null, values);
      return;
    }

    if (values && errors && !Object.keys(errors).length && values.endDate) {
      createPool(poolDetail._id, true);
    }
  };

  const handleUpdatePoolContract = () => {
    const data = {
      dataApi: values,
      oldData: {
        minimumAmountStake: poolDetail.minimumAmountStake ? convertPrice(poolDetail.minimumAmountStake, 18) : 0,
      },
      minimumAmountStake: values.minimumAmountStake ? convertPrice(values.minimumAmountStake, 18) : 0,
    };

    wallet.updatePool({
      poolId: poolDetail._id,
      poolData: data,
      library,
      poolAddress: poolAddress,
      currentAccount: account,
      callbackError: onCloseModalContract,
      callbackSuccess: () => callbackUpdateContractSuccess(null, values),
      callbackProcessing: () => {},
    });
  };

  const onCloseModalContract = () => {
    setVisibleLoading(false);
    setVisibleConfirm(false);
    dispatch(handleSetNetworkPool({ network: "" }));
    message.error("An error occurred. Please try again later.");
  };

  const callbackUpdateContractSuccess = (contract, value) => {
    const postCurrency = renderCurrency(value.type, value.token, listPoolType);
    let startDate = null;
    if (poolDetail.isDraft && value.publish !== PUBLISH_TEXT.NOT_NOW) {
      startDate =
        value.publish !== PUBLISH_TEXT.DATE
          ? moment().utc().format(FORMAT_DATE_TIME_API)
          : value.datePublish.utc().format(FORMAT_DATE_TIME_API);
    }
    let nftPoolData = {};
    let linearPoolData = {};
    if (poolType === POOL_REWARD_TYPE.nft) {
      nftPoolData = {
        tier1: value.tier1From,
        tier2: value.tier2From,
        tier3: value.tier3From,
        tier4: value.tier4From,
        maxStakeTier: value.tier4To,
        nftRewardPerDay: value.isLimited ? value.numberOfNftReward : 0,
        nfts: JSON.stringify(
          selectedNftRewards.map((item) => ({ nftId: item._id, tier: item.tier || item?.detail?.tier || 1 }))
        ),
      };
    }
    if (values.stakingMechanism === STAKING_MECHANISM_TYPE.LINEAR) {
      linearPoolData = {
        apr: Number(values.apr),
        rate: Number(values.rewardRate),
      };
    }
    const data = {
      rewardType: poolType,
      poolId: poolDetail._id,
      name: value.name,
      duration: value.duration,
      status: value.publish !== PUBLISH_TEXT.DATE ? 1 : 2,
      type: value.token,
      endStakeDate: value.endStakeDate.utc().format(FORMAT_DATE_TIME_API),
      endDate: value.endDate.clone().utc().format(FORMAT_DATE_TIME_API),
      isPublish: value.publish !== PUBLISH_TEXT.NOW ? false : true,
      totalRewardFunds: value.rewardFund,
      currencyKey: postCurrency.stakeKey,
      currencyName: postCurrency.stakeName,
      currencyKeyReward: postCurrency.rewardKey,
      currencyNameReward: postCurrency.rewardName,
      currencyContract: postCurrency.stakeToken,
      currencyContractReward: postCurrency.rewardToken,
      startDate: startDate,
      isClaim: !value.duration,
      isShow: value.isShow,
      isDraft: value.publish !== PUBLISH_TEXT.NOT_NOW ? false : true,
      modelType: values.stakingMechanism,
      networkType: values.network,
      totalMaxLocked:
        values.stakingMechanism === STAKING_MECHANISM_TYPE.LINEAR
          ? Number(values.maxTotalValueLocked)
          : Number(values.maximumValueLocked),
      minimumAmountStake: values.minimumAmountStake ?? 0,
      ...nftPoolData,
      ...linearPoolData,
    };

    if (contract && contract.hash) data.txId = contract.hash;

    dispatch(poolUpdateRequest({ data, callbackFinish: handleCallbackFinish, callbackSuccess: redirectDetail }));
    dispatch(handleSetNetworkPool({ network: "" }));
  };

  const updateTxId = ({ poolId, txId }) => {
    dispatch(
      poolUpdateTxidRequest({
        data: { poolId, txId },
        callbackSuccess: () => {},
        callbackFinish: () => {},
      })
    );
  };

  const createPool = (poolId) => {
    const { rewardToken, stakeToken } = renderCurrency(values.type, values.token, listPoolType);
    //hard code N1 address
    const startTime = values.publish !== PUBLISH_TEXT.DATE ? moment().unix() : values.datePublish.unix();
    const endTime = values.endDate.unix();
    const endStakeDate = values.endStakeDate.unix();
    const checkDuration = !values.duration
      ? 0
      : process.env.REACT_APP_IS_DEV
      ? values.duration * TIME_SECONDS_IN_DAY
      : values.duration.toString();
    const data = {
      stakeToken,
      rewardToken,
      erc721Collection: erc721Collection,
      signatureUtils: signatureUtils,
      rewardFund: convertPrice(values.rewardFund, 18),
      minimumAmountStake: values.minimumAmountStake ? convertPrice(values.minimumAmountStake, 18) : 0,
      time: [startTime.toString(), endTime.toString(), checkDuration, endStakeDate.toString()],
      dataApi: values,
      isDraft,
      // totalMaxLocked: convertPrice(values.maximumValueLocked, 18),
    };
    let linearData = {};
    if (values.stakingMechanism === STAKING_MECHANISM_TYPE.LINEAR) {
      linearData = {
        apr: values.apr,
        stakingLimit: convertPrice(values.maxTotalValueLocked, 18),
        exchangeRate: values.rewardRate * 100,
      };
    }
    wallet.createPool({
      poolId,
      poolData: data,
      library,
      currentAccount: account,
      poolAddress: poolAddress,
      ...linearData,
      callbackError: onCloseModalContract,
      callbackSuccess: () => {
        callbackUpdateContractSuccess(null, values);
        dispatch(handleSetNetworkPool({ network: "" }));
      },
      callbackProcessing: updateTxId,
    });
  };

  const handleCallbackFinish = (id) => {
    setVisibleLoading(false);
    setVisibleConfirm(false);
  };

  const redirectDetail = (id) => {
    history.push({ pathname: `/pool/${id}`, state: { id } });
  };

  return (
    <div className="update-pool">
      <Button
        htmlType={"submit"}
        className="button button-confirm"
        // disabled={getTimeDiffSeconds(moment(poolDetail.endStakeDate), moment().utc()) < 0}
        onClick={handleTurnOnPopupConfirm}
      >
        Save changes
      </Button>
      <Button
        className="button button-cancel"
        onClick={() => {
          history.goBack();
        }}
      >
        Cancel
      </Button>

      {values && (
        <ModalConfirm
          visible={visibleConfirm}
          title="Confirmation"
          content={
            <p
              dangerouslySetInnerHTML={{
                __html: "The old data is about to be replaced after updating. <br/>Are you sure to update?",
              }}
            ></p>
          }
          onCancel={handleCancelUpdate}
          onConfirm={handleUpdatePool}
          cancelText={"Cancel"}
          confirmText={"Confirm"}
        />
      )}
      <LoadingContract
        visible={visibleLoading}
        title={`Processing pool update`}
        content={`The pool is being updated. Please wait until the end of the process. Leaving this means process cancellation.`}
      />
    </div>
  );
};

export default UpdatePool;
