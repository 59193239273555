import { Modal } from "antd";
import React from "react";

import ImageLoaing from "resources/images/icon-loading.png";
import GradientLoading from "resources/images/gradient-loader.png";

const LoadingContract = ({ visible, content, title }) => {
  return (
    <Modal visible={visible} footer={false} closable={false} className="modal-loading-contract">
      <p className="title">{title}</p>
      <img className="loading-rotate rotating" src={GradientLoading} />
      <p className="content">{content}</p>
    </Modal>
  );
};

export default LoadingContract;
