import React, { useState } from "react";
import { Typography } from "antd";
// import Web3 from 'web3';
import { useTranslation, withTranslation } from "react-i18next";

import ButtonConnectWallet from "../ButtonConnectWallet";
import { useConnectWallet } from "common/customHook";

import icon_metamask from "resources/images/logo_metamask.svg";
import icon_walletconnect from "resources/images/icon_walletconnect.svg";
import icon_right from "resources/images/icon_right.svg";
import ModalComponent from "components/Modal";
import { METAMASK_DEEPLINK } from 'common/constant';
import IconDownload from 'resources/images/IconDownload.tsx';

const { Title, Paragraph, Text } = Typography;
const ethereum = window?.ethereum;

const ConnectWallet = ({ hanhdleOnPopup }) => {
  const { t } = useTranslation();
  const { connectInjected, connectWalletConnect } = useConnectWallet();

  const [visible, setVisible] = useState(false);

  const onWalletConnect = () => {
    connectWalletConnect();
  };

  const onMetamask = () => {
    if (ethereum?.isMetaMask) {
      connectInjected(null, connectSuccess, hanhdleOnPopup);
    } else {
      setVisible(true);
    }
  };

  const connectSuccess = () => {
    hanhdleOnPopup();
  };

  const hanhdleMetamaskNotfound = () => {
    setVisible(!visible);
  }

  return (
    <div className="connect_wallet">
      <Title className="title">{t("header.txt_connect_wallet")}</Title>
      <p className="desc" dangerouslySetInnerHTML={{ __html: t("header.txt_note_connect_wallet") }}></p>
      <ButtonConnectWallet className="btn" onSubmit={onMetamask}>
        <img className="btn__icon-logo" src={icon_metamask} alt={icon_metamask} />
        {t("header.txt_metamask")}
        <img className="btn__icon-right" src={icon_right} alt={icon_right} />
      </ButtonConnectWallet>
      <ButtonConnectWallet className="btn" onSubmit={onWalletConnect}>
        <img className="btn__icon-logo" src={icon_walletconnect} alt={icon_walletconnect} />
        {t("header.txt_wallet_connect")}
        <img className="btn__icon-right" src={icon_right} alt={icon_right} />
      </ButtonConnectWallet>
      <ModalComponent onClose={hanhdleMetamaskNotfound} visible={visible} onCancel={hanhdleMetamaskNotfound} wrapClassName="model-metamask_notfound" showCloseIcon={false}>
        <div className={'metamask_notfound'}>
          <Paragraph className="title">MetaMask Not Found</Paragraph>
          <img src={icon_metamask} alt={icon_metamask} />
          <Text className="hint">Don't have MetaMask Wallet?</Text>
          <a target="_blank" href={`${METAMASK_DEEPLINK}`} className="link" rel="noreferrer">
            <IconDownload />
            Download Metamask
          </a>
        </div>
      </ModalComponent>
    </div>
  );
};

export default withTranslation()(ConnectWallet);
