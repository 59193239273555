import { Button, Checkbox, Radio } from "antd";
import Title from "antd/lib/typography/Title";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { MAX_LENGTH_PASSWORD, REGEX_PASSWORD, TYPE_INPUT } from "common/constant";
import FormItem from "components/FormItem";
import { loginStart } from "redux/auth/slice";
import { useAppDispatch } from "utils/customHooks";
import { trim } from "lodash";
import IconUserLogin from 'resources/images/IconUserLogin';
import { handleBlurField } from 'utils';

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(t("message.E4"))
      .required(t("message.E2", { fieldName: t("common.text_email_address") })),
    password: Yup.string()
      .required(t("message.E2", { fieldName: t("common.text_password") }))
      .matches(REGEX_PASSWORD, t("message.E8"))
  });

  const goToPath = (pathname: string, params?: any) => {
    return {
      pathname,
      state: params,
    };
  };

  const callback = () => {
    setIsLoading(false);
  };

  const handleSubmit = (data: any) => {
    const { email, password } = data;

    dispatch(
      loginStart({
        data: {
          email: trim(email),
          password: password,
        },
        callback,
      })
    );
    setIsLoading(true);
  };

  return (
    <div className="login">
      {/* <img src={Logo} alt="add value" className="login__logo" /> */}
      <Title level={5} className="login__title">
        {t("login.title")}
      </Title>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnMount
        className="form-login"
        enableReinitialize
      >
        {({ values, setFieldValue, handleBlur }) => (
          <Form>
            <FormItem
              name="email"
              className="form-item__input full-width"
              // label={t("common.text_email_address")}
              suffix={<IconUserLogin />}
              placeholder={t("common.text_email_address")}
              required
              onBlur={handleBlurField(setFieldValue, 'email', handleBlur)}
            />
            <FormItem
              typeInput={TYPE_INPUT.PASSWORD}
              name="password"
              className="form-item__input full-width"
              placeholder={`${t("common.text_password")}`}
              required
              maxLength={MAX_LENGTH_PASSWORD}
              onBlur={handleBlurField(setFieldValue, 'password', handleBlur)}
            />
            <div className="label-password">
              <div className="remember"></div>
              <Link to={goToPath("/forgot-password", { email: values.email })} className="form-item__link">
                {t("common.text_forgot_password")}
              </Link>
            </div>
            <Button
              htmlType="submit"
              className="form-item__button-submit full-width"
              loading={isLoading}
            >
              {t("login.button_login")}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginPage;
