import React, { useEffect, useMemo, useRef, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { Button, Col, Row, Space, Spin, Tooltip } from "antd";
import { Form, Formik } from "formik";
import classNames from "classnames";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import BigNumber from "bignumber.js";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import {
  DATA_DURATION,
  DATA_PUBLISH,
  FORMAT_DATE_TIME,
  NUMBER_OF_NFT_REWARD_PER_DAY,
  PAGE_SIZE_DEFAULT,
  POOL_REWARD_TYPE,
  PUBLISH_TEXT,
  SECONDS_IN_A_YEAR,
  SECOND_IN_DAY,
  STAKING_MECHANISM_TYPE,
  TYPE_INPUT,
  STAKING_DATE,
  STAKING_MECHANISM,
} from "common/constant";
import FormItem from "components/FormItem";
import ModalConfirm from "components/ModalConfirm";
import { getPoolTypeRequest, poolDetailRequest } from "redux/pool/slice";
import { disabledStartTime, getTimeDiffSeconds, handleBlurField, limitMaxLengthNumber } from "utils";
import { useAppSelector } from "utils/customHooks";
import CreatePool from "./CreatePool";
import UpdatePool from "./UpdatePool";
import IconPop from "resources/images/IconPop";
import IconQuestion from "resources/images/IconQuestion";

import AddNFT from "./AddNFT";

const PoolAction = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { account, chainId } = useWeb3React();
  const history = useHistory();
  const { id } = useParams();
  const formRef = useRef(null);

  const poolDetail = useAppSelector((state) => state.pool.poolDetail);
  const walletAddress = useAppSelector((state) => state.auth.walletAddress);
  const loadingPoolDetail = useAppSelector((state) => state.pool.loadingPoolDetail);
  const listPoolType = useAppSelector((state) => state.pool.poolTypes);
  const chains = useAppSelector((state) => state.auth.chains);

  const [visibleCheckAddress, setVisibleCheckAddress] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [dataPublishs, setdataPublish] = useState(DATA_PUBLISH);
  const [isRewardTooSmall, setIsRewardTooSmall] = useState(false);
  const [poolType, setPoolType] = useState(POOL_REWARD_TYPE.token);
  const [initialValues, setInitialValues] = useState({
    stakingMechanism: STAKING_MECHANISM_TYPE.ALLOCATION,
    name: "",
    rewardFund: "",
    maxTotalValueLocked: "",
    minimumAmountStake: "",
    apr: "",
    rewardRate: "",
    duration: 0,
    type: null,
    token: null,
    publish: PUBLISH_TEXT.DATE,
    datePublish: getTimeDiffSeconds(moment(), moment(STAKING_DATE.start)) >= 0 ? moment() : moment(STAKING_DATE.start),
    endStakeDate: moment(STAKING_DATE.end),
    endDate: "",
    isShow: false,
    network: chainId,
  });
  const [searchData, setSearchData] = useState({
    page: 1,
    pageSize: PAGE_SIZE_DEFAULT,
    type: null,
    keyword: "",
    sortField: "createdAt",
    sortType: -1,
    isAdded: false,
  });
  const [selectedNftRewards, setSelectedNftRewards] = useState([]);
  const [isSelectedNftEmpty, setIsSelectedNftEmpty] = useState(false);

  const networkType = useMemo(() => {
    return chains.map((item) => ({ name: item.chainName, value: item.chainId }));
  }, [chains]);

  useEffect(() => {
    if (id) return;
    if (poolType === POOL_REWARD_TYPE.nft) {
      setInitialValues({
        ...initialValues,
        isLimited: 0,
        tier1From: null,
        tier1To: null,
        duration: null,
        publish: PUBLISH_TEXT.NOT_NOW,
        datePublish: "",
        endStakeDate: "",
      });
      setIsDraft(true);
      formRef.current.resetForm();
    } else {
      setInitialValues({
        ...initialValues,
        duration: 0,
        publish: PUBLISH_TEXT.DATE,
        datePublish:
          getTimeDiffSeconds(moment(), moment(STAKING_DATE.start)) >= 0 ? moment() : moment(STAKING_DATE.start),
        endStakeDate: moment(STAKING_DATE.end),
      });
      setIsDraft(false);
      formRef.current.resetForm();
    }
  }, [poolType]);

  useEffect(() => {
    if (!formRef.current.values.network && !Object.keys(poolDetail).length)
      setInitialValues({
        ...initialValues,
        network: chainId,
      });
  }, [chainId]);

  useEffect(() => {
    if (id) dispatch(poolDetailRequest({ data: { id } }));
  }, [id]);

  const renderListOption = (listPoolType) => {
    const network = formRef.current?.values?.network || chainId;
    return listPoolType
      .filter((option) => option.chainId === network)
      .map((item) => ({ name: item.name, value: item.key }));
  };

  useEffect(() => {
    if (!walletAddress) setVisibleCheckAddress(true);
  }, [walletAddress, account]);

  useEffect(() => {
    if (Object.keys(poolDetail).length > 0) {
      let publish = poolDetail?.isPublish
        ? PUBLISH_TEXT.NOW
        : poolDetail?.isDraft
        ? PUBLISH_TEXT.NOT_NOW
        : PUBLISH_TEXT.DATE;
      let data = {
        name: poolDetail?.name,
        type: poolDetail?.currencyKey,
        rewardFund: poolDetail?.totalRewardFunds,
        duration: poolDetail?.duration,
        endDate: poolDetail?.endDate && moment(poolDetail?.endDate),
        endStakeDate: poolDetail?.endStakeDate && moment(poolDetail?.endStakeDate),
        publish,
        token: poolDetail?.currencyKeyReward,
        datePublish: poolDetail?.isDraft ? "" : poolDetail?.startDate && moment(poolDetail?.startDate),
        isShow: poolDetail?.isShow,
        maximumValueLocked: poolDetail?.totalMaxLocked,
        tier1From: poolDetail?.tier1,
        tier1To: poolDetail?.tier2,
        tier2To: poolDetail?.tier3,
        tier3To: poolDetail?.tier4,
        tier2From: poolDetail?.tier2,
        tier3From: poolDetail?.tier3,
        tier4From: poolDetail?.tier4,
        tier4To: poolDetail?.maxStakeTier,
        numberOfNftReward: poolDetail?.nftRewardPerDay || null,
        isLimited: poolDetail?.nftRewardPerDay > 0 ? 1 : 0,
        stakingMechanism: poolDetail?.modelType,
        maxTotalValueLocked: poolDetail?.totalMaxLocked,
        minimumAmountStake: poolDetail?.minimumAmountStake ?? "",
        apr: poolDetail?.apr,
        rewardRate: poolDetail?.rate,
        network: poolDetail?.networkType,
      };
      setInitialValues(data);
      setPoolType(poolDetail?.rewardType);
      formRef.current.setTouched({ ...formRef.current.touched, endDate: true });
      // setTimeout(() => {
      //   formRef.current && formRef.current.setErrors({});
      // }, 0);
      if (!poolDetail?.isDraft) {
        let data = [...DATA_PUBLISH].map((e) => ({
          ...e,
          disabled: e.value === PUBLISH_TEXT.NOT_NOW,
        }));

        setdataPublish(data);
      }
    }
  }, [poolDetail]);

  useEffect(() => {
    dispatch(getPoolTypeRequest());
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = "";
    return;
  };

  const renderCurrency = (type, token, listPoolType) => {
    const network = formRef.current?.values?.network || chainId;

    const stakeCurrency = listPoolType.find((option) => option.chainId === network && option.key === type);
    const rewardCurrency = listPoolType.find((option) => option.chainId === network && option.key === token);
    if (stakeCurrency && rewardCurrency) {
      const { name, key, tokenId } = stakeCurrency;
      return {
        stakeName: name,
        stakeKey: key,
        rewardName: rewardCurrency?.name,
        rewardKey: rewardCurrency?.key,
        stakeToken: tokenId,
        rewardToken: rewardCurrency?.tokenId,
      };
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(t("message.E2", { fieldName: t("pool.txt_pool_name") })),

    rewardFund: Yup.number()
      .required(t("message.E2", { fieldName: t("pool.txt_rewards_fund") }))
      .moreThan(0, "Reward fund must be greater than 0"),
    // .when(() => {
    //   if (Object.keys(poolDetail).length && poolDetail.isDraft) {
    //     return Yup.number()
    //       .nullable()
    //       .required(t("message.E2", { fieldName: t("pool.txt_rewards_fund") }))
    //       .min(poolDetail?.totalRewardFunds, t("message.E27"));
    //   }
    // }),
    duration: Yup.number().nullable().required(t("message.E2")),
    type: Yup.string().nullable().required(t("message.E2")),
    token: Yup.string().nullable().required(t("message.E2")),
    endStakeDate: Yup.string()
      .nullable()
      .trim()
      .required(t("message.E2", { fieldName: t("pool.txt_end_date") })),

    endDate: Yup.string()
      .nullable()
      .trim()
      .required(t("message.E2", { fieldName: t("pool.txt_end_date") })),

    publish: Yup.string()
      .trim()
      .required(t("message.E2", { fieldName: t("pool.txt_publish") })),

    network: Yup.string()
      .trim()
      .required(t("message.E2", { fieldName: t("pool.txt_publish") })),

    datePublish: Yup.string()
      .trim()
      .when("publish", (publish) => {
        if (publish === PUBLISH_TEXT.DATE) {
          return Yup.string().nullable().required("Please choose the date that users can join staking.");
        }
      }),

    minimumAmountStake: Yup.number()
      .nullable()
      .when(
        ["stakingMechanism", "maxTotalValueLocked", "maximumValueLocked"],
        (stakingMechanism, maxTotalValueLocked, maximumValueLocked) => {
          if (stakingMechanism === STAKING_MECHANISM_TYPE.LINEAR)
            return Yup.number()
              .nullable()
              .max(maxTotalValueLocked, "The value must be smaller than Maximum total value locked");
          return Yup.number()
            .nullable()
            .max(maximumValueLocked, "The value must be smaller than Estimated maximum total value locked");
        }
      ),
  });

  const validateLargerThanZero = (value) => {
    let error;
    if (!value) error = t("message.E2");
    else if (parseFloat(value) === 0) error = t("message.E6_1");
    return error;
  };

  const validateTierFrom = (value) => {
    let error;
    if (!value && value !== 0) error = t("message.E2");
    return error;
  };

  const validateTierTo = (value, fromValue) => {
    if (!value) return t("message.E2");
    if (value <= fromValue) return "End value must be greater than start value";

    return "";
  };

  const validateNumberOfNftPerDay = (value) => {
    let error;
    if (!value && parseInt(value) !== 0) {
      error = t("message.E2");
    } else if (parseInt(value) === 0) {
      error = t("message.E6_1");
    } else if (Math.floor(value) < value) {
      error = "Value must be integer";
    }
    return error;
  };

  const validateEndDate = (value, key, errors) => {
    if (Object.keys(poolDetail).length && !poolDetail.isDraft) return;
    let error;

    if (value[key] && getTimeDiffSeconds(value[key], moment()) < 0)
      error = "You cannot choose end date before current time";

    if (
      value.duration &&
      value.datePublish &&
      value[key] &&
      getTimeDiffSeconds(value[key], moment(value.datePublish)) < value.duration * SECOND_IN_DAY
    )
      error = errors.datePublish ? "" : "Available time of a pool must be higher than pool duration. Please try again.";

    if (
      value.endStakeDate &&
      value.endDate &&
      getTimeDiffSeconds(value.endDate, value.endStakeDate) < value.duration * SECOND_IN_DAY
    )
      error = "Please enter the end date for pool greater than end date for staking.";

    return error;
  };

  const validateEndDateStake = (value, values) => {
    if (Object.keys(poolDetail).length && !poolDetail.isDraft) return;

    let error;

    if (value && getTimeDiffSeconds(value, moment()) < 0) error = "You cannot choose end date before current time";

    if (values.datePublish && value && moment(value).isSameOrBefore(values.datePublish))
      error = "Available time end of a staking must be higher than pool publish. Please try again.";

    return error;
  };

  const validatePublishDate = (value) => {
    if (Object.keys(poolDetail).length && !poolDetail.isDraft) return;

    let error;
    if (value.datePublish && getTimeDiffSeconds(value.datePublish, moment()) < 0)
      error = "You cannot choose end date before current time";

    if (
      !value.endDate &&
      value.datePublish &&
      value.endStakeDate &&
      getTimeDiffSeconds(value.endStakeDate, value.datePublish) < 0
    )
      error = "Available time end of a staking must be higher than pool publish. Please try again.";

    return error;
  };

  const disabledStartDate = (current, dateTime) => {
    return current.clone().startOf("day").isBefore(moment(dateTime).clone().startOf("day"));
  };

  const handleChangeEndStakeDate = (e, values) => {
    formRef.current.setFieldValue("endStakeDate", e.value);
    if (e.value && !formRef.current.values.endDate) {
      formRef.current.setFieldValue("endDate", moment(e.value).clone().add(formRef.current.values.duration, "days"));
      setMaxTotalValueLocked(values.rewardFund, moment(e.value).clone().add(formRef.current.values.duration, "days"));
    }
    handleSetEndPool(formRef.current.values.duration, e.value);
  };

  const handleChangeDuration = (values) => (value) => {
    formRef.current.setFieldValue("duration", value.val);
    if (values.endStakeDate && !values.endDate) {
      formRef.current.setFieldValue("endDate", moment(values.endStakeDate).clone().add(value.val, "days"));

      setMaxTotalValueLocked(values.rewardFund, moment(values.endStakeDate).clone().add(value.val, "days"));
    }

    handleSetEndPool(value.val, formRef.current.values.endStakeDate);
  };

  const handleSetEndPool = (duration, endTimeStake) => {
    if (
      formRef.current.values.endDate &&
      getTimeDiffSeconds(formRef.current.values.endDate, endTimeStake) < duration * SECOND_IN_DAY
    ) {
      formRef.current.setFieldValue("endDate", moment(endTimeStake).clone().add(duration, "days"));
      setMaxTotalValueLocked(formRef.current.values.rewardFund, moment(endTimeStake).clone().add(duration, "days"));
    }
  };

  const handleChangeEndPool = (e, values) => {
    formRef.current.setFieldValue("endDate", e.value);
    setMaxTotalValueLocked(values.rewardFund, e.value);
  };

  const handleChangePublish = (values) => (e) => {
    const { value } = e.target;
    if (value !== PUBLISH_TEXT.NOT_NOW) {
      setIsDraft(false);
    } else setIsDraft(true);

    formRef.current.setFieldValue("publish", value);
    formRef.current.setFieldValue("isShow", value !== PUBLISH_TEXT.NOT_NOW);
    formRef.current.setFieldValue("datePublish", moment());
    formRef.current.setFieldValue("endStakeDate", "");

    if (
      value === PUBLISH_TEXT.DATE &&
      values.stakingMechanism === STAKING_MECHANISM_TYPE.ALLOCATION &&
      poolType === POOL_REWARD_TYPE.token
    ) {
      formRef.current.setFieldValue(
        "datePublish",
        getTimeDiffSeconds(moment(), moment(STAKING_DATE.start)) >= 0 ? moment() : moment(STAKING_DATE.start)
      );
      formRef.current.setFieldValue("endStakeDate", moment(STAKING_DATE.end));
    }
  };

  const onBlurCalculateTVL = (e, handleBlur, values) => {
    handleBlur(e);
    setMaxTotalValueLocked(values.rewardFund, values.endDate);
  };

  const setMaxTotalValueLocked = (rewardFund, endDate) => {
    if (endDate && rewardFund) {
      let poolDurationSeconds;
      if (formRef.current.values.publish === PUBLISH_TEXT.DATE) {
        poolDurationSeconds = endDate.clone().utc().unix() - formRef.current.values.datePublish.clone().utc().unix();
      } else {
        poolDurationSeconds = endDate.clone().utc().unix() - moment().utc().unix();
      }
      if (new BigNumber(rewardFund).dividedBy(poolDurationSeconds).isLessThan(Math.pow(10, -20)))
        setIsRewardTooSmall(true);
      else setIsRewardTooSmall(false);
      const maxTVL = new BigNumber(rewardFund).dividedBy(poolDurationSeconds).dividedBy(Math.pow(10, -20));
      formRef.current.setFieldValue("maximumValueLocked", maxTVL.toNumber());
    }
  };

  const handleSelectPoolType = (selectedPoolType) => {
    setPoolType(selectedPoolType);
  };

  const handleBlurTierTo = (handleBlur, index, setFieldValue) => (e) => {
    handleBlur(e);
    setFieldValue(`tier${index}To`, parseFloat(e.target.value));
    setFieldValue(`tier${index + 1}From`, parseFloat(e.target.value));
  };

  const onGoBack = () => {
    history.goBack();
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const calculateRewardFund = (values) => {
    let rewardFund;
    if (values.publish !== PUBLISH_TEXT.DATE) {
      rewardFund =
        (((values.maxTotalValueLocked * values.apr) / 100) *
          values.rewardRate *
          (values.endDate.clone().utc().unix() - moment().utc().unix())) /
        SECONDS_IN_A_YEAR;
    } else {
      rewardFund =
        (((values.maxTotalValueLocked * values.apr) / 100) *
          values.rewardRate *
          (values.endDate.clone().utc().unix() - values.datePublish.clone().utc().unix())) /
        SECONDS_IN_A_YEAR;
    }
    return rewardFund;
  };

  const onChangeStakingMechanism = (e, setFieldValue, resetForm) => {
    resetForm();
    if (e.target.value === STAKING_MECHANISM_TYPE.LINEAR) {
      setFieldValue("duration", null);
      setFieldValue("publish", PUBLISH_TEXT.NOT_NOW);
      setFieldValue("datePublish", "");
      setFieldValue("endStakeDate", "");
      setIsDraft(true);
    }
    setFieldValue("stakingMechanism", e.target.value);
  };

  const onChangeToken = (e, setFieldValue, values) => {
    setFieldValue(e.field.name, e.val);
    if (values.stakingMechanism === STAKING_MECHANISM_TYPE.LINEAR) {
      if ((e.field.name === "type" && e.val === values?.token) || (e.field.name === "token" && e.val === values?.type))
        setFieldValue("rewardRate", 1);
      else setFieldValue("rewardRate", "");
    }
  };

  const handleChangeNetworkType = (e, setFieldValue, values) => {
    setFieldValue(e.field.name, e.val);
    setFieldValue("token", null);
    setFieldValue("type", null);
  };

  return (
    <div className="action-pool">
      {loadingPoolDetail && (
        <div className="loading-pool-detail">
          <Spin size="large" />
        </div>
      )}
      <div className="action-pool__head">
        <IconPop className="cursor-pointer" onClick={onGoBack} />
        <h2 className="action-pool__title">{Object.keys(poolDetail).length > 0 ? "Edit" : "Create"} Pool</h2>
      </div>
      <div className="action-pool__body">
        <div className="action-pool__body__tabs">
          <Button
            type="ghost"
            className={classNames({
              "action-pool__body__tab": true,
              "action-pool__body__tab--active": poolType === POOL_REWARD_TYPE.token,
            })}
            onClick={() => handleSelectPoolType(POOL_REWARD_TYPE.token)}
            disabled={Object.keys(poolDetail).length > 0}
          >
            Token Rewards Pool
          </Button>
          <Button
            type="ghost"
            className={classNames({
              "action-pool__body__tab": true,
              "action-pool__body__tab--active": poolType === POOL_REWARD_TYPE.nft,
            })}
            onClick={() => handleSelectPoolType(POOL_REWARD_TYPE.nft)}
            disabled={Object.keys(poolDetail).length > 0}
          >
            NFT Random Rewards Pool
          </Button>
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          className="form-create-pool"
          innerRef={formRef}
        >
          {({ values, setFieldValue, handleBlur, errors, resetForm }) => {
            return (
              <Form onKeyDown={onKeyDown}>
                <Row gutter={24}>
                  <Col span={24}>
                    <FormItem
                      name="stakingMechanism"
                      className=""
                      typeInput={TYPE_INPUT.RADIO}
                      label="Staking mechanism"
                      options={STAKING_MECHANISM}
                      required
                      disabled={Object.keys(poolDetail).length}
                      onChange={(e) => onChangeStakingMechanism(e, setFieldValue, resetForm)}
                    />
                  </Col>
                  <Col span={24}>
                    <FormItem
                      name="network"
                      className="select"
                      options={networkType}
                      typeInput={TYPE_INPUT.SELECT}
                      label="Network"
                      placeholder={"Network"}
                      required
                      disabled={Object.keys(poolDetail).length}
                      getPopupContainer={(trigger) => trigger.parentElement}
                      onChange={(e) => handleChangeNetworkType(e, setFieldValue, values)}
                    />
                  </Col>
                  <Col span={12}>
                    <FormItem
                      name="name"
                      typeInput={TYPE_INPUT.TEXT}
                      className="form-item__input full-width"
                      label="Pool Name"
                      placeholder={!poolType === POOL_REWARD_TYPE.nft ? t("pool.txt_pool_name") : "Enter pool name"}
                      onBlur={handleBlurField(setFieldValue, "name", handleBlur)}
                      required
                    />
                    {values.stakingMechanism === STAKING_MECHANISM_TYPE.LINEAR && (
                      <>
                        <FormItem
                          name="maxTotalValueLocked"
                          className="form-item__input full-width"
                          typeInput={TYPE_INPUT.NUMBER}
                          label="Maximum total value locked"
                          placeholder="Maximum total value locked"
                          thousandSeparator={true}
                          validate={validateLargerThanZero}
                          disabled={Object.keys(poolDetail).length > 0 && !poolDetail?.isDraft}
                          required
                        />
                        <FormItem
                          name="apr"
                          className="form-item__input full-width"
                          typeInput={TYPE_INPUT.NUMBER}
                          label="APR"
                          placeholder="Enter amount"
                          thousandSeparator
                          required
                          suffix="%"
                          validate={validateLargerThanZero}
                          decimalScale={0}
                          isAllowed={limitMaxLengthNumber(5)}
                          disabled={Object.keys(poolDetail).length > 0 && !poolDetail?.isDraft}
                        />
                        <div className="reward-rate">
                          <Space align="center">
                            <p className="reward-rate__title"> Reward rate *</p>
                            <Tooltip title="The Reward Rate will represent the user's reward ratio.">
                              <IconQuestion />
                            </Tooltip>
                          </Space>
                        </div>
                        <Row justify="space-between" style={{ maxWidth: "520px" }}>
                          <Col span={11}>
                            <FormItem
                              name="stakeRate"
                              className="form-item__input"
                              typeInput={TYPE_INPUT.TEXT}
                              value={
                                values.type && values.token
                                  ? `1 ${
                                      renderCurrency(values.type, values.token, listPoolType)
                                        ? renderCurrency(values.type, values.token, listPoolType).stakeName
                                        : ""
                                    }`
                                  : "1"
                              }
                              disabled
                            />
                          </Col>
                          <Col span={2} className="equal">
                            =
                          </Col>
                          <Col span={11}>
                            <FormItem
                              name="rewardRate"
                              className="form-item__input"
                              typeInput={TYPE_INPUT.NUMBER}
                              placeholder="Enter amount"
                              thousandSeparator
                              inputProps={{
                                suffix:
                                  values.type && values.token && renderCurrency(values.type, values.token, listPoolType)
                                    ? renderCurrency(values.type, values.token, listPoolType).rewardName
                                    : "",
                              }}
                              disabled={
                                !values.type ||
                                !values.token ||
                                values.type === values.token ||
                                (Object.keys(poolDetail).length > 0 && !poolDetail?.isDraft)
                              }
                              validate={validateLargerThanZero}
                              decimalScale={2}
                              isAllowed={limitMaxLengthNumber(3)}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                    <FormItem
                      name="rewardFund"
                      className="form-item__input full-width"
                      typeInput={TYPE_INPUT.NUMBER}
                      label={t("pool.txt_rewards_fund")}
                      placeholder="Reward fund"
                      required
                      thousandSeparator={true}
                      onBlur={(e) => onBlurCalculateTVL(e, handleBlur, values)}
                      disabled={
                        (Object.keys(poolDetail).length && !poolDetail?.isDraft) ||
                        values.stakingMechanism === STAKING_MECHANISM_TYPE.LINEAR
                      }
                      {...(values.stakingMechanism === STAKING_MECHANISM_TYPE.LINEAR && values.endDate
                        ? {
                            value: calculateRewardFund(values),
                          }
                        : {})}
                      inputProps={{
                        suffix:
                          values.type && values.token && renderCurrency(values.type, values.token, listPoolType)
                            ? renderCurrency(values.type, values.token, listPoolType).rewardName
                            : "",
                      }}
                    />
                    <FormItem
                      name="duration"
                      className="select"
                      typeInput={TYPE_INPUT.SELECT}
                      options={
                        poolType === POOL_REWARD_TYPE.nft || values.stakingMechanism === STAKING_MECHANISM_TYPE.LINEAR
                          ? DATA_DURATION.filter((e) => e).slice(1)
                          : DATA_DURATION.filter((e) => e || e === 0)
                      }
                      label={t("pool.txt_duration")}
                      placeholder="Pool Duration"
                      disabled={Object.keys(poolDetail).length > 0 && !poolDetail?.isDraft}
                      onChange={handleChangeDuration(values)}
                      getPopupContainer={(trigger) => trigger.parentElement}
                      required
                    />

                    <FormItem
                      name="publish"
                      className=""
                      typeInput={TYPE_INPUT.RADIO}
                      label={t("pool.txt_publish")}
                      options={dataPublishs}
                      required
                      disabled={Object.keys(poolDetail).length > 0 && !poolDetail?.isDraft}
                      onChange={handleChangePublish(values)}
                    />
                    {values.publish === PUBLISH_TEXT.DATE && (
                      <FormItem
                        name="datePublish"
                        className="form-item__input full-width"
                        typeInput={TYPE_INPUT.DATE}
                        disabledDate={(e) => disabledStartDate(e, moment())}
                        disabledTime={(e) => disabledStartTime(e, moment())}
                        showTime={{ defaultValue: moment() }}
                        required
                        disabled={Object.keys(poolDetail).length > 0 && !poolDetail?.isDraft}
                        format={FORMAT_DATE_TIME}
                        validate={() => validatePublishDate(values)}
                        getPopupContainer={(trigger) => trigger.parentElement}
                      />
                    )}

                    <FormItem
                      name="endStakeDate"
                      className="form-item__input full-width"
                      typeInput={TYPE_INPUT.DATE}
                      validate={(value) => validateEndDateStake(value, values)}
                      label={t("pool.txt_end_date_stake")}
                      placeholder={poolType === POOL_REWARD_TYPE.nft ? "Choose A Date" : t("pool.txt_end_date_stake")}
                      disabledDate={(e) =>
                        disabledStartDate(
                          e,
                          values.publish === PUBLISH_TEXT.DATE ? moment(values.datePublish) : moment()
                        )
                      }
                      disabledTime={(e) =>
                        disabledStartTime(
                          e,
                          values.publish === PUBLISH_TEXT.DATE ? moment(values.datePublish) : moment()
                        )
                      }
                      showTime={{
                        defaultValue: values.publish === PUBLISH_TEXT.DATE ? moment(values.datePublish) : moment(),
                      }}
                      required
                      format={FORMAT_DATE_TIME}
                      onChange={(e) => handleChangeEndStakeDate(e, values)}
                      getPopupContainer={(trigger) => trigger.parentElement}
                      disabled={Object.keys(poolDetail).length && !poolDetail?.isDraft}
                    />

                    <FormItem
                      name="endDate"
                      className="form-item__input full-width"
                      validate={() => validateEndDate(values, "endDate", errors)}
                      typeInput={TYPE_INPUT.DATE}
                      label={t("pool.txt_end_date")}
                      placeholder={poolType === POOL_REWARD_TYPE.nft ? "Choose A Date" : t("pool.txt_end_date")}
                      disabledDate={(e) =>
                        disabledStartDate(e, moment(values.endStakeDate).clone().add(values.duration, "days"))
                      }
                      disabledTime={(e) =>
                        disabledStartTime(e, moment(values.endStakeDate).clone().add(values.duration, "days"))
                      }
                      disabled={!values.endStakeDate || (Object.keys(poolDetail).length && !poolDetail?.isDraft)}
                      showTime={{
                        defaultValue: values.publish === PUBLISH_TEXT.DATE ? moment(values.datePublish) : moment(),
                      }}
                      required
                      format={FORMAT_DATE_TIME}
                      onChange={(e) => handleChangeEndPool(e, values)}
                      getPopupContainer={(trigger) => trigger.parentElement}
                    />

                    <FormItem
                      name="minimumAmountStake"
                      className="form-item__input full-width"
                      typeInput={TYPE_INPUT.NUMBER}
                      label={t("Minimum Staking Amount")}
                      placeholder="Enter Minimum Staking Amount"
                      thousandSeparator={true}
                    />

                    {values.stakingMechanism === STAKING_MECHANISM_TYPE.ALLOCATION && (
                      <FormItem
                        name="maximumValueLocked"
                        typeInput={TYPE_INPUT.NUMBER}
                        className="form-item__input token"
                        label="Estimated maximum total value locked"
                        placeholder="Maximum total value locked"
                        disabled
                        thousandSeparator={true}
                      />
                    )}
                    {poolType === POOL_REWARD_TYPE.nft && (
                      <FormItem
                        name="isLimited"
                        className=""
                        typeInput={TYPE_INPUT.RADIO}
                        label="Maximum number NFT rewards per day "
                        options={NUMBER_OF_NFT_REWARD_PER_DAY}
                        required
                      />
                    )}
                    {values.isLimited === 1 && (
                      <FormItem
                        name="numberOfNftReward"
                        typeInput={TYPE_INPUT.NUMBER}
                        className="form-item__input"
                        placeholder="Enter maximum number of NFT rewards per day"
                        validate={validateNumberOfNftPerDay}
                        thousandSeparator={true}
                      />
                    )}
                    {poolType === POOL_REWARD_TYPE.nft && (
                      <div className="reward-rule">
                        <Space align="center">
                          <p className="reward-rule__title">Reward Rule</p>
                          <Tooltip title="The staking range determines which NFT will be able to random.">
                            <IconQuestion />
                          </Tooltip>
                        </Space>
                        <Row gutter={16} style={{ maxWidth: 520 }}>
                          {[1, 2, 3, 4].map((item, index) => (
                            <React.Fragment key={index}>
                              <Col span={24}>
                                <span className="reward-rule__label">Tier {item}</span>
                              </Col>
                              <Col span={12}>
                                <FormItem
                                  name={`tier${item}From`}
                                  typeInput={TYPE_INPUT.NUMBER}
                                  className="form-item__input"
                                  placeholder="From"
                                  inputProps={{
                                    suffix:
                                      values.type && values.token && listPoolType.length
                                        ? renderCurrency(values.type, values.token, listPoolType).stakeName
                                        : "",
                                  }}
                                  disabled={item !== 1 || (Object.keys(poolDetail).length > 0 && !poolDetail?.isDraft)}
                                  onBlur={handleBlur}
                                  validate={validateTierFrom}
                                />
                              </Col>
                              <Col span={12}>
                                <FormItem
                                  name={`tier${item}To`}
                                  typeInput={TYPE_INPUT.NUMBER}
                                  className="form-item__input"
                                  placeholder="To"
                                  inputProps={{
                                    suffix:
                                      values.type && values.token && listPoolType.length
                                        ? renderCurrency(values.type, values.token, listPoolType).stakeName
                                        : "",
                                  }}
                                  disabled={
                                    (item !== 1 && !values[`tier${item - 1}To`]) ||
                                    (Object.keys(poolDetail).length > 0 && !poolDetail?.isDraft)
                                  }
                                  validate={(value) => validateTierTo(value, values[`tier${item}From`])}
                                  onBlur={handleBlurTierTo(handleBlur, item, setFieldValue)}
                                />
                              </Col>
                            </React.Fragment>
                          ))}
                        </Row>
                      </div>
                    )}
                    {poolType === POOL_REWARD_TYPE.token && (
                      <FormItem
                        name="isShow"
                        typeInput={TYPE_INPUT.CHECKBOX}
                        content={"Display on the user’s web interface"}
                        disabled={values.publish === PUBLISH_TEXT.NOT_NOW}
                      />
                    )}
                  </Col>
                  <Col span={12}>
                    <FormItem
                      name="type"
                      className="select"
                      options={renderListOption(listPoolType)}
                      typeInput={TYPE_INPUT.SELECT}
                      label="Stake Token"
                      placeholder="Stake Token"
                      required
                      disabled={Object.keys(poolDetail).length > 0 && !poolDetail?.isDraft}
                      getPopupContainer={(trigger) => trigger.parentElement}
                      onChange={(e) => onChangeToken(e, setFieldValue, values)}
                    />
                    <FormItem
                      name="token"
                      className="select token"
                      typeInput={TYPE_INPUT.SELECT}
                      options={renderListOption(listPoolType)}
                      label="Reward Token"
                      required
                      placeholder="Reward token"
                      disabled={Object.keys(poolDetail).length > 0 && !poolDetail?.isDraft}
                      getPopupContainer={(trigger) => trigger.parentElement}
                      onChange={(e) => onChangeToken(e, setFieldValue, values)}
                    />
                  </Col>
                  {poolType === POOL_REWARD_TYPE.nft && (
                    <AddNFT
                      isSelectedNftEmpty={isSelectedNftEmpty}
                      poolDetail={poolDetail}
                      searchData={searchData}
                      id={id}
                      setIsSelectedNftEmpty={setIsSelectedNftEmpty}
                      setSearchData={setSearchData}
                      selectedNftRewards={selectedNftRewards}
                      setSelectedNftRewards={setSelectedNftRewards}
                      values={values}
                    />
                  )}

                  {Object.keys(poolDetail).length > 0 ? (
                    <UpdatePool
                      poolDetail={poolDetail}
                      values={values}
                      errors={errors}
                      isDraft={isDraft}
                      isRewardTooSmall={isRewardTooSmall}
                      selectedNftRewards={selectedNftRewards}
                      poolType={poolType}
                      setIsSelectedNftEmpty={setIsSelectedNftEmpty}
                    />
                  ) : (
                    <CreatePool
                      values={values}
                      errors={errors}
                      isDraft={isDraft}
                      onChangeIsDraft={setIsDraft}
                      isRewardTooSmall={isRewardTooSmall}
                      poolType={poolType}
                      selectedNftRewards={selectedNftRewards}
                      setIsSelectedNftEmpty={setIsSelectedNftEmpty}
                    />
                  )}
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>

      <ModalConfirm
        visible={visibleCheckAddress}
        title="Error"
        content={"You must connect wallet before creating the pool. Please connect it and try again. "}
        onConfirm={() => history.push("/pool")}
        cancelText={"Cancel"}
        confirmText={"Close"}
        isErr
      />
    </div>
  );
};

export default PoolAction;
