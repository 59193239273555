import { all } from "redux-saga/effects";
import watchAuth from "./auth/saga";
import watchUser from "./user/saga";
import watchPool from "./pool/saga";
import watchNftSaga from "./nft/saga";
import watchToken from "./token/saga";

function* rootSaga() {
  yield all([watchAuth(), watchUser(), watchPool(), watchNftSaga(), watchToken()]);
}
export default rootSaga;
