import * as React from "react";

function IconCalendar(props: any) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.167 1.667c.46 0 .833.373.833.833v.833h.834a2.5 2.5 0 012.5 2.5v10a2.5 2.5 0 01-2.5 2.5H4.167a2.5 2.5 0 01-2.5-2.5v-10a2.5 2.5 0 012.5-2.5H5V2.5a.833.833 0 111.667 0v.833h6.667V2.5c0-.46.373-.833.833-.833zM3.334 10v5.833c0 .46.373.833.833.833h11.667c.46 0 .833-.373.833-.833V10H3.334zm0-1.667h13.333v-2.5A.833.833 0 0015.834 5H15v.833a.833.833 0 11-1.666 0V5H6.667v.833a.833.833 0 11-1.667 0V5h-.833a.833.833 0 00-.833.833v2.5z"
        fill="#B5B5B5"
      />
    </svg>
  );
}

export default IconCalendar;
