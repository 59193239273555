import React, { lazy } from "react";
import { Redirect } from "react-router";
import IconDashboard from "resources/images/IconDashboard";
import IconPoolManagement from "resources/images/IconPoolManagement";
import IconUserManagement from "resources/images/IconUserManagement";
import IconRewardManagement from "resources/images/IconRewardManagement";

import PoolAction from "pages/pool/Action";
import LoginPage from "pages/login";
import ForgotPassword from "pages/forgotPassword";
import PasswordReset from "pages/passwordReset";
import User from "pages/user";
import Dashboard from "pages/dashboard";
import ChangePassword from "pages/changePassword";
import Token from "pages/token";
import TokenDetail from "pages/token/Detail";
import Pool from "pages/pool";
import UserDetail from "pages/user/Detail";
import PoolDetail from "pages/pool/Detail";
import NftReward from "pages/nftReward";
import RegisterNft from "pages/registerNft";
import NftDetail from "pages/nftDetail";

export const publicRoutes = [
  {
    name: "login",
    path: "/login",
    component: LoginPage,
    exact: true,
  },
  {
    name: "Forgot Password",
    path: "/forgot-password",
    component: ForgotPassword,
    exact: true,
  },
  {
    name: "Password Reset",
    path: "/reset-password",
    component: PasswordReset,
    exact: true,
  },
];

export const privateRoutes = (t) => {
  return [
    {
      name: t("menu.text_wallet"),
      path: "/",
      component: () => <Redirect to="/dashboard" />,
      exact: true,
      title: "Dashboard",
      icon: <IconDashboard />,
      inMenu: false,
    },
    {
      name: t("DASHBOARD"),
      path: "/dashboard",
      component: Dashboard,
      exact: true,
      title: "Dashboard",
      icon: <IconDashboard />,
      inMenu: true,
    },
    {
      name: t("USER MANAGEMENT"),
      path: "/user",
      component: User,
      exact: true,
      title: "User",
      icon: <IconUserManagement />,
      inMenu: true,
    },
    {
      name: t("USER MANAGEMENT DETAIL"),
      path: "/user/:id",
      component: UserDetail,
      exact: true,
      title: "User Deatil",
      icon: "",
      inMenu: false,
    },
    {
      name: t("POOL MANAGEMENT"),
      path: "/pool",
      component: Pool,
      exact: true,
      title: "Pool",
      icon: <IconPoolManagement />,
      inMenu: true,
    },
    {
      name: t("TOKEN MANAGEMENT"),
      path: "/token",
      component: Token,
      exact: true,
      title: "Pool",
      icon: <IconRewardManagement />,
      inMenu: true,
    },
    {
      name: t("TOKEN Detail"),
      path: "/token/:id",
      component: TokenDetail,
      exact: true,
      title: "Token",
      icon: <IconRewardManagement />,
      inMenu: false,
    },
    {
      name: t("REWARD MANAGEMENT"),
      path: "/nft",
      component: NftReward,
      exact: true,
      title: "Reward",
      icon: <IconRewardManagement />,
      inMenu: true,
    },
    {
      name: t("REGISTER NFT"),
      path: "/nft-register",
      component: RegisterNft,
      exact: true,
      title: "Register NFT Reward",
      icon: <IconRewardManagement />,
      inMenu: false,
    },
    {
      name: t("NFT DETAIL"),
      path: "/nft-detail/:id",
      component: NftDetail,
      exact: true,
      title: "NFT Detail",
      icon: <IconRewardManagement />,
      inMenu: false,
    },
    {
      name: t("POOL Detail"),
      path: "/pool/:id",
      component: PoolDetail,
      exact: true,
      title: "Pool",
      icon: <IconPoolManagement />,
      inMenu: false,
    },
    {
      name: t("POOL CREATE"),
      path: "/pool-create",
      component: PoolAction,
      exact: true,
      title: "Pool",
      icon: <IconPoolManagement />,
      inMenu: false,
    },
    {
      name: t("POOL UPDATE"),
      path: "/pool-update/:id",
      component: PoolAction,
      exact: true,
      title: "Pool",
      icon: <IconPoolManagement />,
      inMenu: false,
    },
    {
      name: t("POOL EDIT"),
      path: "/pool-edit/:id",
      component: PoolAction,
      exact: true,
      title: "Pool",
      icon: <IconPoolManagement />,
      inMenu: false,
    },
    {
      name: "Change Pass",
      path: "/change-password",
      component: ChangePassword,
      exact: true,
      inMenu: false,
    },
  ];
};
