import { useWeb3React } from "@web3-react/core";
import { Button, message, Space } from "antd";
import {
  erc721Collection,
  FORMAT_DATE_TIME,
  FORMAT_DATE_TIME_API,
  POOL_REWARD_TYPE,
  PUBLISH_TEXT,
  signatureUtils,
  STAKING_MECHANISM_TYPE,
  TIME_SECONDS_IN_DAY,
  TYPE_OF_ANT_DESIGN,
} from "common/constant";
import LoadingContract from "components/LoadingContract";
import showMessage from "components/Message";
import ModalConfirm from "components/ModalConfirm";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { createPoolRequest, handleSetNetworkPool, poolDeleteRequest, poolUpdateTxidRequest } from "redux/pool/slice";
import { convertPrice } from "utils";
import BaseWalletService from "utils/baseWallet";
import { useAppSelector } from "utils/customHooks";
import { requestSupportNetwork } from "utils/setupNetwork";

const CreatePool = ({
  errors,
  values,
  isDraft,
  onChangeIsDraft,
  isRewardTooSmall,
  poolType,
  selectedNftRewards,
  setIsSelectedNftEmpty,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { account, library, chainId } = useWeb3React();
  const wallet = new BaseWalletService().getInstance();
  const history = useHistory();

  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [visibleLoaing, setVisibleLoaing] = useState(false);

  const listPoolType = useAppSelector((state) => state.pool.poolTypes);
  const chains = useAppSelector((state) => state.auth.chains);

  const poolAddress = useMemo(() => {
    if (values.network) {
      const networkSelected = chains.find((item) => item.chainId === values.network);
      return networkSelected?.poolContractAddress;
    }
    return "";
  }, [chains, values]);

  const renderCurrency = (type, token, listPoolType) => {
    const stakeCurrency = listPoolType.find((option) => option.chainId === values.network && option.key === type);
    const { name, key, tokenId } = stakeCurrency;
    const rewardCurrency = listPoolType.find((option) => option.chainId === values.network && option.key === token);
    return {
      stakeName: name,
      stakeKey: key,
      rewardName: rewardCurrency?.name,
      rewardKey: rewardCurrency?.key,
      stakeToken: tokenId,
      rewardToken: rewardCurrency?.tokenId,
    };
  };

  const handleTurnOnPopupConfirm = async (isDraft) => {
    if (poolType === POOL_REWARD_TYPE.nft && !selectedNftRewards.length) setIsSelectedNftEmpty(true);
    else if (poolType === POOL_REWARD_TYPE.nft && selectedNftRewards.length) {
      const tierArray = new Set(selectedNftRewards.map((nft) => nft?.tier || 1));
      let tierMissing = [];
      [1, 2, 3, 4].forEach((tier) => {
        if (!tierArray.has(tier)) tierMissing.push(tier);
      });
      if (tierMissing.join(", ")) {
        showMessage(TYPE_OF_ANT_DESIGN.ERROR, "message.E20_1", { tier: tierMissing.join(", ") });
      } else
        setTimeout(() => {
          if (!Object.keys(errors).length && values.endDate) setVisibleConfirm(true);
          if (isDraft) onChangeIsDraft(true);
        }, 0);
    } else {
      setTimeout(() => {
        if (!Object.keys(errors).length && values.endDate) setVisibleConfirm(true);
        if (isDraft) onChangeIsDraft(true);
      }, 0);
    }
  };

  const handleSubmit = () => {
    if (values.network !== chainId) {
      dispatch(handleSetNetworkPool({ network: values.network }));
      return switchNetwork();
    }
    if (values && errors && !Object.keys(errors).length && values.endDate) {
      let value = { ...values };
      let nftPoolData = {};
      let linearPoolData = {};
      if (poolType === POOL_REWARD_TYPE.nft) {
        nftPoolData = {
          tier1: value.tier1From,
          tier2: value.tier2From,
          tier3: value.tier3From,
          tier4: value.tier4From,
          maxStakeTier: value.tier4To,
          nftRewardPerDay: value.isLimited ? value.numberOfNftReward : 0,
          nfts: JSON.stringify(
            selectedNftRewards.map((item) => ({ nftId: item._id, tier: item.tier || item?.detail?.tier || 1 }))
          ),
        };
      }
      if (values.stakingMechanism === STAKING_MECHANISM_TYPE.LINEAR) {
        linearPoolData = {
          apr: Number(values.apr),
          rate: Number(values.rewardRate),
        };
      }
      const postCurrency = renderCurrency(value.type, value.token, listPoolType);
      const data = {
        rewardType: poolType,
        name: value.name,
        duration: value.duration,
        status: value.publish !== PUBLISH_TEXT.DATE ? 1 : 2,
        startDate:
          value.publish !== PUBLISH_TEXT.DATE
            ? moment().clone().utc().format(FORMAT_DATE_TIME_API)
            : value.datePublish.clone().utc().format(FORMAT_DATE_TIME_API),
        endDate: value.endDate.clone().utc().format(FORMAT_DATE_TIME_API),
        endStakeDate: value.endStakeDate.utc().format(FORMAT_DATE_TIME_API),
        isDraft,
        isPublish: value.publish !== PUBLISH_TEXT.NOW ? false : true,
        totalRewardFunds: value.rewardFund,
        currencyKey: postCurrency.stakeKey,
        currencyName: postCurrency.stakeName,
        currencyKeyReward: postCurrency.rewardKey,
        currencyNameReward: postCurrency.rewardName,
        currencyContract: postCurrency.stakeToken,
        currencyContractReward: postCurrency.rewardToken,
        isClaim: !value.duration,
        isShow: value.isShow,
        modelType: values.stakingMechanism,
        networkType: values.network,
        totalMaxLocked:
          values.stakingMechanism === STAKING_MECHANISM_TYPE.LINEAR
            ? Number(values.maxTotalValueLocked)
            : Number(values.maximumValueLocked),
        minimumAmountStake: values.minimumAmountStake ?? 0,
        ...nftPoolData,
        ...linearPoolData,
      };

      setVisibleLoaing(true);
      dispatch(
        createPoolRequest({ data, callbackError: onCloseModalContract, callbackSuccess: callbackCreatePoolSuccess })
      );
    }
  };

  const onCloseModalContract = () => {
    setVisibleLoaing(false);
    setVisibleConfirm(false);
    message.error("An error occurred. Please try again later.");
  };

  const callbackCreatePoolSuccess = (poolId) => {
    const value = { ...values };
    const { rewardToken, stakeToken } = renderCurrency(value.type, value.token, listPoolType);
    const startTime = value.publish !== PUBLISH_TEXT.DATE ? moment().unix() : value.datePublish.unix();
    const endTime = value.endDate.unix();
    const endStakeDate = value.endStakeDate.unix();

    const checkDuration = !value.duration
      ? 0
      : process.env.REACT_APP_IS_DEV
      ? value.duration * TIME_SECONDS_IN_DAY
      : value.duration.toString();
    const data = {
      stakeToken,
      rewardToken,
      erc721Collection: erc721Collection,
      signatureUtils: signatureUtils,
      rewardFund: convertPrice(value.rewardFund, 18),
      minimumAmountStake: value.minimumAmountStake ? convertPrice(value.minimumAmountStake, 18) : 0,
      time: [startTime.toString(), endTime.toString(), checkDuration, endStakeDate.toString()],
      dataApi: value,
      isDraft,
    };
    let linearData = {};
    if (value.stakingMechanism === STAKING_MECHANISM_TYPE.LINEAR) {
      linearData = {
        apr: value.apr,
        stakingLimit: convertPrice(value.maxTotalValueLocked, 18),
        exchangeRate: value.rewardRate * 100,
      };
    }
    if (!isDraft)
      wallet.createPool({
        poolId,
        poolData: data,
        library,
        currentAccount: account,
        poolAddress: poolAddress,
        ...linearData,
        callbackError: () => handleDelete(poolId),
        callbackSuccess: () => {
          redirectDetail(poolId);
          dispatch(handleSetNetworkPool({ network: "" }));
        },
        callbackProcessing: updateTxId,
      });
    else redirectDetail(poolId);
  };

  const handleDelete = (id) => {
    dispatch(poolDeleteRequest({ id, callbackSuccess: () => {} }));
    dispatch(handleSetNetworkPool({ network: "" }));
    onCloseModalContract();
  };

  const redirectDetail = (id) => {
    history.push(`/pool/${id}`);
  };

  const updateTxId = ({ poolId, txId }) => {
    dispatch(
      poolUpdateTxidRequest({
        data: { poolId, txId },
        callbackSuccess: () => {},
        callbackFinish: () => {},
      })
    );
  };

  const switchNetwork = async () => {
    const networkSupports = chains.reduce(
      (object, item) => ({
        ...object,
        [item.chainId]: {
          chainId: `0x${item.chainId.toString(16)}`,
          chainName: item?.chainName,
          nativeCurrency: item?.nativeCurrency,
          rpcUrls: item?.rpcUrls,
          blockExplorerUrls: [item?.blockExplorerUrls],
        },
      }),
      {}
    );
    // check user choose which network then use id network.
    await requestSupportNetwork("metamask", networkSupports, values.network);
  };

  const handleCancelCreate = () => {
    dispatch(handleSetNetworkPool({ network: "" }));
    setVisibleConfirm(false);
  };

  return (
    <div className="create-pool">
      <Space size="middle" className="action">
        <Button
          htmlType="submit"
          onClick={() => handleTurnOnPopupConfirm(false)}
          className="button button-confirm"
          disabled={values.publish === PUBLISH_TEXT.NOT_NOW}
        >
          {t("pool.txt_btn_save")}
        </Button>
        <Button
          htmlType="submit"
          onClick={() => handleTurnOnPopupConfirm(true)}
          className="button button-cancel"
          disabled={values.publish !== PUBLISH_TEXT.NOT_NOW}
        >
          {t("pool.txt_btn_save_as_draft")}
        </Button>
      </Space>

      {values && (
        <ModalConfirm
          visible={visibleConfirm}
          title="Confirmation"
          content={
            isDraft
              ? "Do you want to save your pool as draft?"
              : `Do you want your pool to be published on ${
                  values.publish === PUBLISH_TEXT.NOT_NOW
                    ? moment().format(FORMAT_DATE_TIME)
                    : values.datePublish && values.datePublish.format(FORMAT_DATE_TIME)
                }?`
          }
          onCancel={handleCancelCreate}
          onConfirm={handleSubmit}
          cancelText={"Cancel"}
          confirmText={"Confirm"}
        />
      )}

      <LoadingContract
        visible={visibleLoaing}
        title={`Processing pool creation`}
        content={`The pool is being created. Please wait until the end of the process. Leaving this means process cancellation.`}
      />
    </div>
  );
};

export default CreatePool;
