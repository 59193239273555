import React, { FC } from "react";
import { Typography } from "antd";
import classnames from "classnames";
import IconCopy from "resources/images/IconCopy";

const { Text } = Typography;

const TextComponent: FC<any> = ({ copyable, children, className = "", ...props }) => {
  return (
    <Text
      copyable={copyable && { ...copyable, icon: <IconCopy /> }}
      className={classnames("text-component", className)}
      {...props}
    >
      {children}
    </Text>
  );
};

export default TextComponent;
