import api from "./api";

export const listPoolAPI = (payload) => api.get("/pool", payload);

export const createPoolAPI = (payload) => api.post("/pool", payload);

export const updatePoolAPI = (payload) => api.put("/pool", payload);

export const poolDetailAPI = (payload) => api.get(`/pool/admin/${payload.id}`);

export const poolDeleteAPI = (payload) => api.delete(`/pool/${payload.id}`);

export const poolUpdateStatusAPI = (payload) => api.put(`/pool/edit-show`, payload);

export const poolUpdateTxidAPI = (payload) => api.put(`/pool/update-txid`, payload);

export const getPoolType = () => api.get("/pool/pool-type-with-reward");

export const getListUserByPoolId = (id) => api.get(`/pool/admin/${id}/export`);

export const stopPool = (id) => api.put(`/pool/admin/${id}/stop`);
