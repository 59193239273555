import { Button, message } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";
import FormItem from "components/FormItem";
import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { forgotPasswordStart } from "redux/auth/slice";
import { useAppDispatch } from "utils/customHooks";
import * as Yup from "yup";
import { trim } from "lodash";
import { handleBlurField } from 'utils';
import IconBack from 'resources/images/Iconback';

const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  let location = useLocation() as any;
  const history = useHistory();

  const formikRef = useRef(null) as any;

  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<any>({
    email: "",
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(t("message.E4"))
      .required(t("message.E2", { fieldName: t("common.text_email_address") })),
  });

  const onResponse = (status: boolean) => {
    setIsLoading(false);
    if (status) {

      message.success('This is a success message');
    } else {
      console.log("Error");
    }
  };

  const handleSubmit = (data: any) => {
    const { email } = data;
    dispatch(
      forgotPasswordStart({
        data: {
          email: trim(email),
          // role: USER_ROLE.ADMIN,
        },
        onResponse,
      })
    );
    setIsLoading(true);
  };

  useEffect(() => {
    if (location.state?.email) {
      setInitialValues({
        email: location.state.email,
      });
    }
  }, [location]);

  return (
    <div className="forgot-password">
      <Title level={5} className="forgot-password__title">
        {t("forgot_password.title")}
      </Title>
      <Paragraph className="forgot-password__description">{t("forgot_password.description")}</Paragraph>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        className="form-forgot-password"
        innerRef={formikRef}
        validateOnMount
      >
        {({ values, handleBlur, setFieldValue }) => (
          <Form>
            <FormItem
              name="email"
              className="form-item__input full-width"
              // label={t("common.text_email_address")}
              placeholder={t("common.text_email_address")}
              required
              onBlur={handleBlurField(setFieldValue, 'email', handleBlur)}
            />
            <Button
              htmlType="submit"
              className="form-item__button-submit full-width"
              loading={isLoading}
              disabled={!values.email}
            >
              {t("forgot_password.button_request_password_reset")}
            </Button>
            <div className="link-container">
              <IconBack />
              <Link to="/login" className="form-item__link">
                {t("forgot_password.button_back")}
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPassword;
