import { Button, Select } from "antd";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import IconAdd from "resources/images/IconAdd";
import IconEdit from "resources/images/IconEdit";
import IconDelete from "resources/images/IconDelete";
import IconEye from "resources/images/icon-eye.svg";

import FormItem from "components/FormItem";
import showMessage from "components/Message";
import ModalComponent from "components/Modal";
import NumberFormat from "components/NumberFormat";
import ModalConfirm from "components/ModalConfirm";

import { getCurrency, updateCurrency } from "services/token";
import { handleBlurField } from "utils";
import { TYPE_INPUT, TYPE_OF_ANT_DESIGN } from "common/constant";
import AddToken from "./Action/AddToken";
import { useHistory } from "react-router";
import { useAppSelector } from "utils/customHooks";

const { Option } = Select;

const CurrencyBlock = ({ title, data, onChooseCurrency }) => {
  const history = useHistory();
  return (
    <div className="currency-block">
      <div className="currency-block__header">
        <p>{title}</p>
      </div>
      <div className="currency-block__body">
        {data &&
          data.map((item, index) => (
            <div className="currency-item" key={index}>
              <div>
                <p className="currency-name">{item?.name}</p>
                <NumberFormat
                  value={item?.exchangeRate}
                  thousandSeparator
                  decimalScale={8}
                  displayType="text"
                  prefix="$ "
                  className="currency-value"
                />
              </div>
              {item.isUpdatable && (
                <div onClick={() => onChooseCurrency(item?.name, item?.exchangeRate, item?._id)} className="edit">
                  <IconEdit />
                </div>
              )}
              <div
                onClick={() => {
                  history.push({ pathname: `/token/${item?._id}`, state: { id: item?._id } });
                }}
                className="edit"
              >
                <img src={IconEye} alt="" />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

const Token = () => {
  const [listCurrency, setListCurrency] = useState([]);
  const [visible, setVisible] = useState(false);
  const [chosenCurrency, setChosenCurrency] = useState({ name: "", exchangeRate: 0, id: "" });
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [action, setAction] = useState("");
  const [title, setTitle] = useState("");
  const listChain = useAppSelector((state) => state.auth.chains);
  const [currentChain, setCurrentChain] = useState(listChain?.[0]?.chainId);

  useEffect(() => {
    const getListCurrency = async () => {
      const res = await getCurrency({ chainId: currentChain });
      if (res?.meta?.code === 0) {
        const { data } = res;
        setListCurrency(data.records);
      } else {
        showMessage(TYPE_OF_ANT_DESIGN.ERROR, "An error occurred, please try again!");
      }
    };
    getListCurrency();
  }, [currentChain]);

  const onChooseCurrency = (name, exchangeRate, id) => {
    setVisible(true);
    setChosenCurrency({ name, exchangeRate, id });
    setAction("changeRate");
    setTitle(`Set exchange rate for ${name}`);
  };

  const handleAddToken = () => {
    setVisible(true);
    setAction("addToken");
    setTitle("Add Token");
  };

  const handleSubmit = async (value) => {
    setIsLoading(true);
    const res = await updateCurrency(value);
    if (res?.meta?.code === 0) {
      setIsLoading(false);
      setVisible(false);
      const newListCurrency = listCurrency.map((item) => (item._id === chosenCurrency.id ? res.data : item));
      setListCurrency(newListCurrency);
      showMessage(TYPE_OF_ANT_DESIGN.SUCCESS, "Update exchange rate successfully.");
    } else {
      setVisible(false);
      setIsLoading(false);
      showMessage(TYPE_OF_ANT_DESIGN.ERROR, "An error occurred, please try again!");
    }
  };

  const validationSchema = Yup.object().shape({
    exchangeRate: Yup.number()
      .required(t("message.E2", { fieldName: "exchange rate" }))
      .moreThan(0, "Exchange rate must be greater than 0")
      .notOneOf([chosenCurrency.exchangeRate], "New exchange rate must be difference from the current one"),
  });

  const handleCloseModal = () => {
    setVisible(false);
  };

  const FormChangeRateToken = () => {
    return (
      <div className="form-token">
        <Formik
          initialValues={{ exchangeRate: chosenCurrency.exchangeRate }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const data = { ...values, id: chosenCurrency.id };
            handleSubmit(data);
          }}
        >
          {({ values, handleBlur, errors, setFieldValue }) => (
            <Form>
              <FormItem
                name="exchangeRate"
                typeInput={TYPE_INPUT.NUMBER}
                label="Enter new value"
                className="form-item__input"
                onBlur={handleBlurField(setFieldValue, "exchangeRate", handleBlur)}
              />
              <Button htmlType="submit" className="button button-confirm" loading={isLoading}>
                Save
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  const onChangeChainId = (value) => {
    setCurrentChain(value);
  };

  return (
    <div className="token-wrap">
      <div className="token-container">
        <div className="token__header">
          <h2 className="title">Token Price Management</h2>
          <div className="search">
            <Select
              onChange={onChangeChainId}
              value={currentChain}
              style={{ width: 228 }}
              className="search-select"
              getPopupContainer={(trigger) => trigger.parentElement}
              placeholder="Network"
            >
              {listChain?.map((chain) => {
                return (
                  <Option key={chain?.chainId} value={chain?.chainId}>
                    {chain?.chainName}
                  </Option>
                );
              })}
            </Select>
            <Button onClick={handleAddToken} icon={<IconAdd />}>
              Add Token
            </Button>
          </div>
        </div>

        <div className="list-currency">
          <CurrencyBlock
            title="Tokens that have been listed on multiple platforms"
            data={listCurrency.length > 0 && listCurrency.filter((currency) => !currency.isUpdatable)}
            onChooseCurrency={onChooseCurrency}
          />

          <CurrencyBlock
            title="Tokens that have not been listed yet"
            data={listCurrency.length > 0 && listCurrency.filter((currency) => currency.isUpdatable)}
            onChooseCurrency={onChooseCurrency}
          />
        </div>
      </div>
      <ModalComponent visible={visible} title={title} showCloseIcon={true} onClose={handleCloseModal}>
        {action === "changeRate" ? FormChangeRateToken() : <AddToken handleCloseModal={handleCloseModal} />}
      </ModalComponent>
    </div>
  );
};

export default Token;
