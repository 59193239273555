import axios from "axios";
import { logout } from "redux/auth/slice";
import { HTTP_RESPONSE, TYPE_OF_ANT_DESIGN } from "../common/constant";
import showMessage from "../components/Message";
import { validateStatus } from "../utils/validateStatus";
import { store } from "redux/configStore";

const HEADERS: any = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
};

const HEADERS_MULTIPLE_PART = {
  ...HEADERS,
  "Content-Type": "multipart/form-data; boundary=something",
  Accept: "application/json",
};

export const getToken = (token: any): any => {
  HEADERS["Authorization"] = `Bearer ${token}`;
  HEADERS_MULTIPLE_PART["Authorization"] = `Bearer ${token}`;
};

const getFullUrl = (url: string) => {
  if (url.startsWith("http")) {
    return url;
  }
  if (!url.startsWith("/")) {
    url = "/" + url;
  }
  return `${process.env.REACT_APP_API_URL}/api` + url;
};

const throttledResetToLogin = () => {
  // TODO: logout
  store.dispatch(logout());
};

const checkErrorNetwork = (err?: any) => {
  if ((err?.toJSON() && err.toJSON().msg === "Network Error") || err?.message === "Network Error") {
    return showMessage(TYPE_OF_ANT_DESIGN.ERROR, "message.E9");
  }
  return err;
};

const checkErrorStatus = (response?: any, outsideUrl?: boolean) => {
  if (response?.meta?.code === 0 || response?.data?.isVerified === false || outsideUrl) {
    return response;
  }
  if (response?.meta?.errorCode) {
    if (response?.meta?.errorCode !== "E0") {
      showMessage(TYPE_OF_ANT_DESIGN.ERROR, `message.${response?.meta?.errorCode}`, response?.meta?.extraInfo);
    } else {
      showMessage(TYPE_OF_ANT_DESIGN.ERROR, response?.meta?.msg);
    }
  }
  return response;
};

const handleErrorInThen = (err?: any) => {
  return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
};

const handleCatchErr = (err?: any) => {
  return (err?.response?.data && err.response.data) || checkErrorNetwork(err);
};

const api = {
  post: (endpoint: string, params?: any) => {
    return axios
      .post(getFullUrl(endpoint), params, {
        headers: HEADERS,
        validateStatus: (status: any) => validateStatus(status),
      })
      .then((response: any) => {
        if (response?.status === HTTP_RESPONSE.ERROR_CODE_401) {
          throttledResetToLogin();
          return response?.data;
        }
        return checkErrorStatus(response?.data);
      }, handleErrorInThen)
      .catch(handleCatchErr);
  },

  postMultiplePart: (endpoint: string, params: any) => {
    return axios
      .post(getFullUrl(endpoint), params, {
        headers: HEADERS_MULTIPLE_PART,
        validateStatus: (status: any) => validateStatus(status),
      })
      .then((response: any) => {
        if (response?.status === HTTP_RESPONSE.ERROR_CODE_401) {
          throttledResetToLogin();
          return response?.data;
        }
        return checkErrorStatus(response?.data);
      }, handleErrorInThen)
      .catch(handleCatchErr);
  },

  get: (endpoint: string, params = {}, outsideUrl?: boolean) => {
    return axios
      .get(getFullUrl(endpoint), {
        params: params,
        headers: HEADERS,
        validateStatus: (status: any) => validateStatus(status),
      })
      .then((response: any) => {
        if (response?.status === HTTP_RESPONSE.ERROR_CODE_401) {
          throttledResetToLogin();
          return response?.data;
        }
        return checkErrorStatus(response?.data, outsideUrl);
      }, handleErrorInThen)
      .catch(handleCatchErr);
  },

  put: (endpoint: string, params?: any) => {
    return axios
      .put(getFullUrl(endpoint), params, {
        headers: HEADERS,
        validateStatus: (status: any) => validateStatus(status),
      })
      .then((response: any) => {
        if (response?.status === HTTP_RESPONSE.ERROR_CODE_401) {
          throttledResetToLogin();
          return response?.data;
        }
        return checkErrorStatus(response.data);
      }, handleErrorInThen)
      .catch(handleCatchErr);
  },

  delete: (endpoint: string, params?: any) => {
    return axios
      .delete(getFullUrl(endpoint), {
        params: params,
        headers: HEADERS,
        validateStatus: (status: any) => validateStatus(status),
      })
      .then((response: any) => {
        if (response?.status === HTTP_RESPONSE.ERROR_CODE_401) {
          throttledResetToLogin();
          return response?.data;
        }
        return checkErrorStatus(response?.data);
      }, handleErrorInThen)
      .catch(handleCatchErr);
  },
};

export default api;
