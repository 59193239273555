import React, { FC, useCallback, useMemo } from "react";
import { Pagination, Skeleton, Spin, Table } from "antd";
import { useTranslation } from "react-i18next";
import { PAGE_SIZE_DEFAULT, PAGE_SIZE_OPTIONS } from "common/constant";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { FilterValue, GetRowKey, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import Button from "antd/lib/button";
import IconArrowRight from "resources/images/IconArrowRight";
import IconArrowLeft from "resources/images/IconArrowLeft";
import NodataTable from "resources/images/nodata-table";

type TableProps = {
  columns?: ColumnsType<any>;
  dataSource?: readonly any[];
  current?: number;
  pageSize?: number;
  total?: number;
  rowClassName?: string;
  pageSizeOptions?: string[];
  onChangeTable?: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    extra: TableCurrentDataSource<any>
  ) => void;
  onChangePagination?: (page: number, pageSize?: number) => void;
  size?: SizeType;
  bordered?: boolean;
  rowKey?: string | GetRowKey<any>;
  className?: string;
  scroll?: any;
  NoData?: any;
  loading?: boolean;
  showTotal?: boolean;
  showSizeChanger?: boolean;
  showPagination?: boolean;
};

const TableComponent: FC<TableProps> = ({
  columns,
  dataSource,
  current,
  pageSize,
  total,
  rowClassName,
  pageSizeOptions,
  onChangeTable,
  onChangePagination,
  size,
  bordered,
  rowKey,
  className,
  scroll,
  loading,
  NoData,
  showTotal = true,
  showSizeChanger = false,
  showPagination = true,
  ...props
}) => {
  const { t } = useTranslation();

  const renderShowTotal = useCallback(
    (total: number) => {
      return t("user.text_total_count", { total });
    },
    [t]
  );

  const RenderEmptyData = useMemo(() => {
    return <div className="ant-empty-text">{NoData ? NoData : <NodataTable />}</div>;
  }, [t]);

  const itemRender = (current: number, type: string, originalElement: any) => {
    if (type === "prev") {
      return <Button className="button-pagination" icon={<IconArrowLeft />} />;
    }
    if (type === "next") {
      return <Button className="button-pagination" icon={<IconArrowRight />} />;
    }
    return originalElement;
  };

  return (
    <div className="table">
      <Table
        locale={{
          emptyText: loading ? (
            <div style={{ minHeight: "194px", display: "flex", alignItems: "center", justifyContent: "center" }}></div>
          ) : (
            RenderEmptyData
          ),
        }}
        pagination={false}
        columns={columns}
        bordered={bordered}
        rowClassName={rowClassName}
        dataSource={dataSource}
        onChange={onChangeTable}
        size={size}
        showSorterTooltip={false}
        rowKey={rowKey}
        className={className}
        scroll={scroll || { x: 960 }}
        loading={{
          spinning: loading,
          indicator: <Spin size="large" />,
        }}
        {...props}
      />
      {showPagination && (
        <div className="my-pagination">
          <Pagination
            size="small"
            total={total ? total : 0}
            current={current}
            onChange={onChangePagination}
            pageSizeOptions={pageSizeOptions ?? PAGE_SIZE_OPTIONS}
            pageSize={pageSize ?? PAGE_SIZE_DEFAULT}
            showSizeChanger={showSizeChanger}
            showTotal={(showTotal && renderShowTotal) || undefined}
            itemRender={itemRender}
            showQuickJumper={Boolean(total && pageSize && total > pageSize * 10)}
          />
        </div>
      )}
    </div>
  );
};

export default TableComponent;
