import { message } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  changePasswordAPI,
  loginAPI,
  forgotPasswordAPI,
  verifyOTPCodeAPI,
  resetPasswordAPI,
  checkExpireLinkAPI,
  getChainsApi,
} from "../../services/auth";
import { ResponseGenerator } from "../../types";
import {
  changePasswordStart,
  forgotPasswordStart,
  loginStart,
  loginSuccess,
  verifyOTPCodeStart,
  resetPasswordStart,
  checkExpireLinkStart,
  handleGetChainsRequest,
  handleGetChainsResponse,
} from "./slice";

function* login({ payload }: any) {
  const { data, callback } = payload;
  try {
    const response: ResponseGenerator = yield loginAPI(data);
    if (response.meta.code === 0) {
      yield put(loginSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield call(callback);
  }
}

function* forgotPassword({ payload }: any) {
  const { data, onResponse } = payload;
  try {
    const response: ResponseGenerator = yield forgotPasswordAPI(data);
    if (response.meta.code === 0) {
      yield call(onResponse, true);
    } else {
      yield call(onResponse, false);
    }
  } catch (error) {
    console.error(error);
    yield call(onResponse, false);
  }
}

function* verifyOTPCode({ payload }: any) {
  const { data, onResponse } = payload;
  try {
    const response: ResponseGenerator = yield verifyOTPCodeAPI(data);
    if (response.meta.code === 0) {
      yield call(onResponse, true, response.data.code);
    } else {
      yield call(onResponse, false);
    }
  } catch (error) {
    console.error(error);
    yield call(onResponse, false);
  }
}

function* changePassword({ payload }: any) {
  const { data, onResponse } = payload;
  try {
    const response: ResponseGenerator = yield changePasswordAPI(data);
    if (response.meta.code === 0) {
      yield call(onResponse, true);
    } else {
      message.error(response.meta.msg);
    }
  } catch (error) {
    console.error(error);
    yield call(onResponse, false);
  }
}

function* resetPassword({ payload }: any) {
  const { data, onResponse } = payload;
  try {
    const response: ResponseGenerator = yield resetPasswordAPI(data);
    if (response.meta.code === 0) {
      yield call(onResponse, true);
    } else {
      yield call(onResponse, false);
    }
  } catch (error) {
    console.error(error);
    yield call(onResponse, false);
  }
}

function* checkExpireLinkSaga({ payload }: any) {
  const { data, onResponse } = payload;
  try {
    const response: ResponseGenerator = yield checkExpireLinkAPI({ code: data });

    if (response) yield call(onResponse, true);
    else yield call(onResponse, false);
  } catch (error) {
    console.error(error);
    yield call(onResponse, false);
  }
}

function* handleGetChainsSaga(action: any) {
  const { payload } = action;
  try {
    const response: ResponseGenerator = yield getChainsApi({});
    if (response?.meta?.code === 0) {
      yield put(
        handleGetChainsResponse({
          data: response.data,
        }),
      );
    } else {
    }
  } catch (error) {
  } finally {
  }
}

function* watchAuth() {
  yield takeLatest(loginStart, login);
  yield takeLatest(forgotPasswordStart, forgotPassword);
  yield takeLatest(verifyOTPCodeStart, verifyOTPCode);
  yield takeLatest(changePasswordStart, changePassword);
  yield takeLatest(resetPasswordStart, resetPassword);
  yield takeLatest(checkExpireLinkStart, checkExpireLinkSaga);
  yield takeLatest(handleGetChainsRequest, handleGetChainsSaga);
}

export default watchAuth;
