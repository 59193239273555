import { useWeb3React } from "@web3-react/core";
import { Button } from "antd";
import { TYPE_OF_ANT_DESIGN } from "common/constant";
import showMessage from "components/Message";
import ModalConfirm from "components/ModalConfirm";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { poolDetailRequest } from "redux/pool/slice";
import { stopPool } from "services/pool";
import BaseWalletService from "utils/baseWallet";
import { useAppSelector } from "utils/customHooks";

const StopPool = ({ pool, id }) => {
  const [visibleStop, setVisibleStop] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const wallet = new BaseWalletService().getInstance();
  const { account, library } = useWeb3React();

  const dispatch = useDispatch();

  const chains = useAppSelector((state) => state.auth.chains);

  const poolAddress = useMemo(() => {
    if (pool.networkType) {
      const networkSelected = chains.find((item) => item.chainId === pool.networkType);
      return networkSelected?.poolContractAddress;
    }
    return "";
  }, [chains, pool]);

  const handleConfirm = async () => {
    setLoading(true);
    await wallet.stopPool({
      poolId: pool?._id,
      library,
      currentAccount: account,
      poolAddress,
      callbackError: () => {
        handleShowModalConfirm();
        setLoading(false);
        showMessage(TYPE_OF_ANT_DESIGN.ERROR, "message.E_100");
      },
      callbackSuccess: async () => {
        const res = await stopPool(pool?._id);
        if (!res?.meta?.code) {
          handleShowModalConfirm();
          setLoading(false);
          showMessage(TYPE_OF_ANT_DESIGN.SUCCESS, "Stop pool successfully!");
          dispatch(poolDetailRequest({ data: { id } }));
        }
      },
    });
  };
  const handleShowModalConfirm = () => {
    setVisibleStop(!visibleStop);
  };

  return (
    <>
      {!pool?.isStop && (
        <Button className="button button-confirm" onClick={handleShowModalConfirm}>
          Stop Pool
        </Button>
      )}{" "}
      <ModalConfirm
        visible={visibleStop}
        title="Are you sure?"
        content={
          <p
            dangerouslySetInnerHTML={{
              __html: "This pool will be stopped on the user's web interface.<br/> Are you sure to stop it?",
            }}
          ></p>
        }
        onConfirm={handleConfirm}
        onCancel={handleShowModalConfirm}
        cancelText={"Cancel"}
        confirmText={"Confirm"}
        loadingConfirm={loading}
        showCloseIcon={true}
      />
    </>
  );
};

export default StopPool;
