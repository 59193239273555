import Web3 from "web3";
import { AddressZero } from "@ethersproject/constants";
import BigNumber from "bignumber.js";
import { formatUnits } from "@ethersproject/units";
import { Token, WETH, Fetcher, Route } from "quickswap-sdk";
import { Fetcher as FetcherEther, WETH as WETHEther, Token as TokenEther, Route as RouteEther } from "@uniswap/sdk";

import { Contract, ContractFactory } from "@ethersproject/contracts";

import PePoolAbi from "common/abi/peAbi.json";
import { JsonRpcSigner } from "@ethersproject/providers";
import TokenJSON from "common/abi/token.json";
import moment from "moment";
import {
  ID_COINGECKO_CHAIN_ID,
  INIT_UNIT_256,
  SUPPORT_CHAIN_MAINNET,
  TOKEN_LIST_STATUS,
  TOKEN_TYPE,
  W_ETH,
  W_MATIC,
} from "common/constant";
import { convertPrice, getLengthObject } from "utils";
import erc1155Abi from "common/abi/erc1155Abi.json";
import erc721Abi from "common/abi/erc721Abi.json";
import BSCTokenAbi from "common/abi/bscToken.json";
import SushiData from "@sushiswap/sushi-data";

let instance: any;

export function getContractFactory(abi: any, bytecode: any, library: any, account: string): ContractFactory {
  const signer = library.getSigner(account);

  return new ContractFactory(abi, bytecode, signer);
}

export const checkEnoughBalance = (data: any, balance: any) => {
  return new BigNumber(balance).isGreaterThanOrEqualTo(new BigNumber(data.price).multipliedBy(data.quantity));
};

export const convertEToNumber = (value: any, number: any) => {
  BigNumber.config({
    EXPONENTIAL_AT: 100,
  });

  return new BigNumber(value).toNumber() / new BigNumber(10).pow(number).toNumber();
};

export function getSigner(library: any, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
function getProviderOrSigner(library: any, account?: string): any | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

export function isAddress(address: string) {
  return Web3.utils.isAddress(address);
}

// account is optional
export function getContract(address: string, ABI: any, library: any, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any);
}

export default class BaseWalletService {
  getInstance = () => {
    if (instance == null) {
      instance = new BaseWalletService();
      instance.constructor = null;
    }
    return instance;
  };

  /**
   * Check Buyer Balance
   * @param data
   * @returns
   */
  checkBuyerBalance = async (library: any, data: any, tokenAddress: string) => {
    if (tokenAddress) {
      const buyerAdress = data;
      const balances = await this._getBalanceBuyer(library, buyerAdress, tokenAddress);
      const balance = balances.balance;

      console.log("balance:", balance);

      return balance;
    }
    return null;
  };

  /**
   * get Balance
   * @returns balance
   */
  _getBalanceBuyer = async (library: any, buyerAddress: string, tokenAddress: string) => {
    const tokenInst = getContract(tokenAddress, TokenJSON.output.abi, library);
    if (buyerAddress) {
      const balance = await tokenInst.balanceOf(buyerAddress);
      const decimals = await tokenInst.decimals();

      return {
        balance: convertEToNumber(formatUnits(balance, "wei"), decimals),
      };
    } else {
      return {
        balance: 0,
      };
    }
  };

  getNftERC1155Balance = async (library: any, contractAddress: string, tokenId: string, account: string) => {
    try {
      const erc1155Contract = getContract(contractAddress, erc1155Abi, library);
      const balance = await erc1155Contract.balanceOf(account, tokenId);
      const balanceNumber = new BigNumber(balance.toString());
      return balanceNumber.toNumber();
    } catch (error) {
      console.log(error);
    }
  };

  getNftERC721Balance = async (library: any, contractAddress: string, tokenId: string, account: string) => {
    try {
      const erc721Contract = getContract(contractAddress, erc721Abi, library);
      const owner = await erc721Contract.ownerOf(tokenId);
      if (owner === account) return 1;
      else return 0;
    } catch (error) {
      console.log(error);
    }
  };

  depositNFT721 = async ({
    library,
    contractAddress,
    account,
    tokenId,
    poolId,
    internalTx,
    poolAddress,
    callbackSuccess,
    callbackProcessing,
    callbackError,
  }: {
    library: any;
    contractAddress: string;
    account: string;
    tokenId: string;
    poolId: string;
    internalTx: string;
    poolAddress: string;
    callbackSuccess: () => void;
    callbackProcessing: (hash: string) => void;
    callbackError: (e?: any) => void;
  }) => {
    try {
      const poolContract = getContract(poolAddress, PePoolAbi.output.abi, library, account);
      const depositRes = await poolContract.depositNFT721(contractAddress, tokenId, [poolId, internalTx]);
      if (depositRes) {
        callbackProcessing(depositRes.hash);
      }
      const receipt = await depositRes.wait();
      if (receipt.status) callbackSuccess();
    } catch (error: any) {
      callbackError();
    }
  };

  depositNFT1155 = async ({
    library,
    contractAddress,
    account,
    tokenId,
    poolId,
    amount,
    internalTx,
    poolAddress,
    callbackSuccess,
    callbackProcessing,
    callbackError,
  }: {
    library: any;
    contractAddress: string;
    account: string;
    tokenId: string;
    poolId: string;
    amount: number;
    internalTx: string;
    poolAddress: string;
    callbackSuccess: () => void;
    callbackProcessing: (hash: string) => void;
    callbackError: (e?: any) => void;
  }) => {
    try {
      const poolContract = getContract(poolAddress, PePoolAbi.output.abi, library, account);
      const depositRes = await poolContract.depositNFT1155(contractAddress, tokenId, amount, [poolId, internalTx]);
      if (depositRes) {
        callbackProcessing(depositRes.hash);
      }
      const receipt = await depositRes.wait();
      if (receipt.status) callbackSuccess();
    } catch (error: any) {
      callbackError();
    }
  };

  withdrawNFT721 = async ({
    library,
    contractAddress,
    account,
    recipientAddress,
    tokenId,
    poolId,
    internalTx,
    poolAddress,
    callbackSuccess,
    callbackProcessing,
    callbackError,
  }: {
    library: any;
    contractAddress: string;
    account: string;
    recipientAddress: string;
    tokenId: string;
    poolId: string;
    internalTx: string;
    poolAddress: string;
    callbackSuccess: () => void;
    callbackProcessing: (hash: string) => void;
    callbackError: (e?: any) => void;
  }) => {
    try {
      const poolContract = getContract(poolAddress, PePoolAbi.output.abi, library, account);
      const withdrawRes = await poolContract.withDrawNFT721(contractAddress, recipientAddress, tokenId, [
        poolId,
        internalTx,
      ]);
      if (withdrawRes) {
        callbackProcessing(withdrawRes.hash);
      }
      const receipt = await withdrawRes.wait();
      if (receipt.status) callbackSuccess();
    } catch (error: any) {
      callbackError();
    }
  };

  withdrawNFT1155 = async ({
    library,
    contractAddress,
    account,
    tokenId,
    poolId,
    amount,
    recipientAddress,
    internalTx,
    poolAddress,
    callbackSuccess,
    callbackProcessing,
    callbackError,
  }: {
    library: any;
    contractAddress: string;
    account: string;
    tokenId: string;
    poolId: string;
    amount: number;
    recipientAddress: string;
    internalTx: string;
    poolAddress: string;
    callbackSuccess: () => void;
    callbackProcessing: (hash: string) => void;
    callbackError: (e?: any) => void;
  }) => {
    try {
      const poolContract = getContract(poolAddress, PePoolAbi.output.abi, library, account);
      const withdrawRes = await poolContract.withDrawNFT1155(contractAddress, recipientAddress, tokenId, amount, [
        poolId,
        internalTx,
      ]);
      if (withdrawRes) {
        callbackProcessing(withdrawRes.hash);
      }
      const receipt = await withdrawRes.wait();
      if (receipt.status) callbackSuccess();
    } catch (error: any) {
      callbackError();
    }
  };

  transferFrom = async ({
    library,
    tokenAddress,
    currentAccount,
    poolAddress,
    rewardFund,
    dataDeploySuccess,
    callbackError,
    callbackSuccess,
  }: {
    library: any;
    tokenAddress: string;
    currentAccount: string;
    poolAddress: string;
    rewardFund: string;
    dataDeploySuccess: any;
    callbackError: any;
    callbackSuccess: any;
  }) => {
    try {
      let contract: any;
      let { contractDeploy, dataApi, isDraft, isUpdate } = dataDeploySuccess;
      const tokenInst = getContract(tokenAddress, TokenJSON.output.abi, library, currentAccount);

      console.log("contacrt transfer:", currentAccount, poolAddress, rewardFund);

      contract = await tokenInst.transfer(poolAddress, rewardFund);

      console.log("contacrt transfer:", contract);

      const receipt = await contract.wait((res: any) => {
        console.log(`res`, res);
      });

      if (receipt.status) {
        callbackSuccess(contractDeploy, dataApi, isUpdate);
      } else {
        callbackError();
      }
    } catch (e) {
      console.log(e);

      callbackError();
    }
  };

  isLPToken = async ({ tokenAddress, rpc }: { tokenAddress: string; rpc: string }) => {
    try {
      const web3Provider = rpc && new Web3(rpc);
      const contract = web3Provider && new web3Provider.eth.Contract(BSCTokenAbi.abi as any, tokenAddress);
      const tokenAddressNative = contract && (await contract?.methods.token0().call());
      const tokenAddressSingle = contract && (await contract?.methods.token1().call());

      return [tokenAddressSingle, tokenAddressNative];
    } catch (err) {
      return "";
    }
  };

  getPriceSDK = async ({
    tokenAddress,
    chainId,
    decimals,
  }: {
    tokenAddress: string;
    chainId: number;
    decimals: number;
  }) => {
    try {
      if (chainId === SUPPORT_CHAIN_MAINNET.MATIC) {
        const token = new Token(chainId, tokenAddress, decimals);

        const pair = await Fetcher.fetchPairData(token, WETH[SUPPORT_CHAIN_MAINNET.MATIC as keyof typeof WETH]);
        const route = new Route([pair], WETH[SUPPORT_CHAIN_MAINNET.MATIC as keyof typeof WETH]);

        return route.midPrice.invert().toSignificant(6);
      }

      const token = new TokenEther(chainId, tokenAddress, decimals);

      const pair = await FetcherEther.fetchPairData(token, WETHEther[token.chainId]);

      const route = new RouteEther([pair], WETHEther[token.chainId]);
      return route.midPrice.invert().toSignificant(6);
    } catch (err) {
      return null;
    }
  };

  infoToken = async ({
    tokenAddress,
    rpc,
    chainId,
    callback,
    callbackErrAddress,
  }: {
    tokenAddress: string;
    rpc: string;
    chainId: number;
    callback: any;
    callbackErrAddress: any;
  }) => {
    let response: any = {};

    try {
      let tokenCallContract = [tokenAddress];
      let totalSupply;
      let reserves;
      let decimals;

      const tokenSingleLP = await this.isLPToken({ tokenAddress, rpc });
      if (tokenSingleLP) tokenCallContract = tokenSingleLP;

      const web3Provider = rpc && new Web3(rpc);

      const contractLP = web3Provider && new web3Provider.eth.Contract(BSCTokenAbi.abi as any, tokenAddress);

      decimals = contractLP && (await contractLP?.methods.decimals().call());
      totalSupply = contractLP && (await contractLP?.methods.totalSupply().call());

      if (tokenCallContract.length > 1) {
        reserves =
          contractLP &&
          (await contractLP?.methods
            .getReserves()
            .call()
            .then((e: any) => [e[1], e[0]]));
      }

      for (let index = 0; index < tokenCallContract.length; index++) {
        const contract =
          web3Provider && new web3Provider.eth.Contract(BSCTokenAbi.abi as any, tokenCallContract[index]);

        let tokenInfo: any = {};

        if (contract) {
          const [tokenDecimal, tokenName, tokenSymbol] = await Promise.all([
            contract.methods.decimals().call(),
            contract.methods.name().call(),
            contract.methods.symbol().call(),
          ]);

          tokenInfo.tokenName = tokenName;
          tokenInfo.tokenSymbol = tokenSymbol;
          tokenInfo.totalSupply = totalSupply;
          tokenInfo.tokenDecimal = decimals;
          tokenInfo.tokenAddress = tokenCallContract[index];

          if (reserves && reserves[index]) {
            tokenInfo.reserve = convertPrice(reserves[index], -tokenDecimal);
          }

          if (chainId === SUPPORT_CHAIN_MAINNET.MATIC || chainId === SUPPORT_CHAIN_MAINNET.ETH) {
            const contractNativeToken = chainId === SUPPORT_CHAIN_MAINNET.MATIC ? W_MATIC : W_ETH;

            const res: any = await fetch(
              `https://api.coingecko.com/api/v3/coins/${
                ID_COINGECKO_CHAIN_ID[chainId as keyof typeof ID_COINGECKO_CHAIN_ID]
              }/contract/${contractNativeToken}`
            ).then((res) => res.json());

            const exchangeRateNativeUsd = res.market_data?.current_price?.usd;

            if (tokenCallContract[index] !== contractNativeToken) {
              let rate;
              let exchangeRateUsd;

              try {
                rate = await this.getPriceSDK({ tokenAddress: tokenCallContract[index], chainId, decimals });
                exchangeRateUsd = exchangeRateNativeUsd * Number(rate);

                if (!rate) throw "not call get price token";
              } catch (error) {
                try {
                  rate = await SushiData.exchange.token24h({
                    token_address: tokenCallContract[index],
                  });

                  exchangeRateUsd = exchangeRateNativeUsd * Number(rate?.derivedETH);
                } catch (error) {
                  console.log("error:", error);
                }
              }

              tokenInfo.exchangeRate = exchangeRateUsd;
              tokenInfo.typeToken = exchangeRateUsd
                ? TOKEN_LIST_STATUS.LISTED_ON_MULTIPLE_PLATFORM
                : TOKEN_LIST_STATUS.NOT_LISTED_ON_MULTIPLE_PLATFORM;
            } else {
              tokenInfo.exchangeRate = exchangeRateNativeUsd;
              tokenInfo.typeToken = exchangeRateNativeUsd
                ? TOKEN_LIST_STATUS.LISTED_ON_MULTIPLE_PLATFORM
                : TOKEN_LIST_STATUS.NOT_LISTED_ON_MULTIPLE_PLATFORM;
            }
          }

          if (chainId === SUPPORT_CHAIN_MAINNET.BSC) {
            const res = await fetch(`https://api.pancakeswap.info/api/v2/tokens/${tokenCallContract[index]}`).then(
              (res) => res.json()
            );

            let exchangeRateUsd = res.data ? res.data.price : 0;

            tokenInfo.exchangeRate = exchangeRateUsd;
            tokenInfo.typeToken = exchangeRateUsd
              ? TOKEN_LIST_STATUS.LISTED_ON_MULTIPLE_PLATFORM
              : TOKEN_LIST_STATUS.NOT_LISTED_ON_MULTIPLE_PLATFORM;
          }

          if (!tokenInfo.exchangeRate) {
            const res = await fetch(
              `https://api.coingecko.com/api/v3/coins/${
                ID_COINGECKO_CHAIN_ID[chainId as keyof typeof ID_COINGECKO_CHAIN_ID]
              }/contract/${tokenCallContract[index]}`
            ).then((res) => res.json());
            let exchangeRateUsd = res.market_data && res.market_data?.current_price?.usd;

            tokenInfo.exchangeRate = exchangeRateUsd;
            tokenInfo.typeToken = exchangeRateUsd
              ? TOKEN_LIST_STATUS.LISTED_ON_MULTIPLE_PLATFORM
              : TOKEN_LIST_STATUS.NOT_LISTED_ON_MULTIPLE_PLATFORM;
          }

          tokenInfo.isSingle = getLengthObject(tokenCallContract) > 1 ? TOKEN_TYPE.LP_TOKEN : TOKEN_TYPE.SINGLE_TOKEN;
        }

        response[index] = tokenInfo;
      }

      if (getLengthObject(response) > 1) {
        let exchangeRateUsdLP = Object.values(response)
          .map((e: any) => new BigNumber(e.exchangeRate).multipliedBy(e.reserve))
          .reduce((previousValue, currentValue) => new BigNumber(previousValue).plus(currentValue));

        let exchangeRateUsdLPUsd = new BigNumber(+exchangeRateUsdLP)
          .dividedBy(convertPrice(+totalSupply, -18))
          .toString();
        response[1].exchangeRate = exchangeRateUsdLPUsd;
      }

      callback(response);
    } catch (err) {
      callback({});
      callbackErrAddress();
      return null;
    }
  };

  createPool = async ({
    poolId,
    poolData,
    library,
    currentAccount,
    poolAddress,
    apr = 0,
    stakingLimit = "0",
    exchangeRate = 0,
    callbackError,
    callbackSuccess,
    callbackProcessing,
  }: {
    poolId: string;
    poolData: any;
    library: any;
    currentAccount: string;
    poolAddress: string;
    apr?: number;
    stakingLimit?: string;
    exchangeRate?: number;
    callbackError: any;
    callbackSuccess: any;
    callbackProcessing: any;
  }) => {
    try {
      const { rewardToken, rewardFund, time, dataApi, stakeToken, minimumAmountStake } = poolData || {};
      let contract: any;
      const tokenInst = getContract(poolAddress, PePoolAbi.output.abi, library, currentAccount);
      console.log(
        "data:",
        [poolId, "internalTransaction"],
        [stakeToken, rewardToken],
        [rewardFund, dataApi.type === 1 ? 0 : 1, apr, minimumAmountStake],
        [...time, stakingLimit, 0, exchangeRate]
      );
      contract = await tokenInst.createPool(
        [poolId, "internalTransaction"],
        [stakeToken, rewardToken],
        [rewardFund, dataApi.type === 1 ? 0 : 1, apr, minimumAmountStake],
        [...time, stakingLimit, 0, exchangeRate]
      );

      if (!contract) {
        callbackError();
      }
      callbackProcessing({ poolId, txId: contract.hash });
      const receipt = await library.waitForTransaction(contract.hash);

      if (receipt.status === 1) {
        callbackSuccess();
      } else {
        callbackError();
      }
    } catch (e) {
      console.log(e);
      callbackError();
    }
  };

  stopPool = async ({
    poolId,
    library,
    currentAccount,
    poolAddress,
    callbackError,
    callbackSuccess,
  }: {
    poolId: string;
    library: any;
    currentAccount: string;
    poolAddress: string;
    callbackError: any;
    callbackSuccess: any;
  }) => {
    try {
      const contract = getContract(poolAddress, PePoolAbi.output.abi, library, currentAccount);
      const contractRes = await contract.setStopPool(poolId.toString());

      if (!contract) {
        callbackError();
      }
      const receipt = await contractRes.wait();

      if (receipt.status === 1) {
        callbackSuccess();
      } else {
        callbackError();
      }
    } catch (e) {
      console.log(e);
      callbackError();
    }
  };

  isApprovalForAll = async ({
    contractAddress,
    currentAccount,
    library,
    poolAddress,
    callback,
  }: {
    contractAddress: string;
    currentAccount: string;
    library: any;
    poolAddress: string;
    callback: any;
  }) => {
    try {
      const ercContract = getContract(contractAddress, erc721Abi, library);

      const isApproved = await ercContract.isApprovedForAll(currentAccount, poolAddress);

      return isApproved;
    } catch (e) {
      console.log(e);
    }
  };

  setApprovalForAll = async ({
    contractAddress,
    currentAccount,
    library,
    poolAddress,
    callbackSuccess,
    callbackError,
  }: {
    contractAddress: string;
    currentAccount: string;
    library: any;
    poolAddress: string;
    callbackSuccess: () => void;
    callbackError: () => void;
  }) => {
    try {
      const ercContract = getContract(contractAddress, erc721Abi, library, currentAccount);
      const approveRes = await ercContract.setApprovalForAll(poolAddress, true);
      const receipt = await approveRes.wait();
      if (receipt.status) {
        callbackSuccess();
      } else callbackError();
    } catch (error) {
      console.log(error);
      callbackError();
    }
  };

  approveCurrentcy = async ({
    poolAddress,
    tokenInst,
    library,
    callbackError,
    callbackSuccess,
  }: {
    poolAddress: string;
    tokenInst: any;
    library: any;
    callbackError: any;
    callbackSuccess: any;
  }) => {
    try {
      const contract = await tokenInst.approve(poolAddress, INIT_UNIT_256);

      if (!contract) {
        callbackError();
      }

      const receipt = await contract.wait((res: any) => {
        console.log(`res`, res);
      });

      if (receipt.status) {
        callbackSuccess();
      } else {
        callbackError();
      }
    } catch (e) {
      console.log(e);

      callbackError();
    }
  };

  updatePool = async ({
    poolId,
    poolData,
    library,
    poolAddress,
    currentAccount,
    callbackError,
    callbackSuccess,
    callbackProcessing,
  }: {
    poolId: string;
    poolData: any;
    library: any;
    poolAddress: string;
    currentAccount: string;
    callbackError: any;
    callbackSuccess: any;
    callbackProcessing: any;
  }) => {
    try {
      const { dataApi, oldData, minimumAmountStake } = poolData || {};
      let contract: any;

      const tokenInst = getContract(poolAddress, PePoolAbi.output.abi, library, currentAccount);

      let minimumStake = oldData.minimumAmountStake == minimumAmountStake ? "" : minimumAmountStake;

      console.log("data:", poolId, minimumStake);

      const data = [minimumStake.toString()];

      const dataCompareChange = [""];
      if (JSON.stringify(dataCompareChange) == JSON.stringify(data)) callbackSuccess(null, dataApi);
      else {
        contract = await tokenInst.updatePool([poolId, "internalTransaction"], [minimumAmountStake]);

        if (!contract) {
          callbackError();
        }
        callbackProcessing({ poolId, txId: contract.hash });
        const receipt = await contract.wait();
        if (receipt.status) {
          callbackSuccess(contract, dataApi);
        } else {
          callbackError();
        }
      }
    } catch (e) {
      console.log(e);
      callbackError();
    }
  };

  isAdmin = async ({ poolAddress, library, account }: { poolAddress: string; library: any; account: string }) => {
    try {
      const poolContract = getContract(poolAddress, PePoolAbi.output.abi, library);
      const isAdmin = await poolContract.isAdmin(account);
      return isAdmin;
    } catch (error) {
      console.log(error);
    }
  };
}
