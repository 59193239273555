import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

export interface Notification {
  title?: string;
  body?: string;
  createdAt?: string;
  _id?: string;
  isRead: boolean;
  metaData: any;
}

export interface NotificationReducer {
  listNotifications: Notification[];
  total: number;
  isLoading?: boolean;
  unreadCount: number;
}

const initialState: NotificationReducer = {
  listNotifications: [],
  total: 0,
  isLoading: false,
  unreadCount: 0,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    getNotificationsStart: (state, action) => ({
      ...state,
    }),
    getNotificationsSuccess: (state, action) => ({
      ...state,
      listNotifications: [...state.listNotifications, ...action.payload.records],
      total: action.payload.total,
    }),
    getNotificationsError: (state) => ({
      ...state,
    }),
    getUnreadCountStart: (state) => ({
      ...state,
    }),
    getUnreadCountSuccess: (state, action) => ({
      ...state,
      unreadCount: action.payload.unread,
    }),
    getUnreadCountError: (state) => ({
      ...state,
    }),
    updateReadNotificationStart: (state, action) => {
      const index = _.findIndex(state.listNotifications, ["_id", action.payload.notificationId]);
      state.listNotifications[index].isRead = true;
      state.unreadCount = state.unreadCount - 1;
    },
    updateReadNotificationSuccess: (state) => ({
      ...state,
    }),
    updateReadNotificationError: (state) => ({
      ...state,
    }),
    receiveNewNotificationStart: (state, action) => ({
      ...state,
      unreadCount: action.payload.unread,
    }),
    receiveNewNotificationSuccess: (state, action) => ({
      ...state,
      listNotifications: [...action.payload.records, ...state.listNotifications],
      total: action.payload.total,
    }),
    receiveNewNotificationError: (state) => ({
      ...state,
    }),
  },
});
export const getNotificationSlice = (state: any) => state.WalletReducer as NotificationReducer;

// Action creators are generated for each case reducer function
export const {
  getNotificationsStart,
  getNotificationsSuccess,
  getNotificationsError,
  getUnreadCountStart,
  getUnreadCountSuccess,
  getUnreadCountError,
  updateReadNotificationStart,
  updateReadNotificationSuccess,
  updateReadNotificationError,
  receiveNewNotificationStart,
  receiveNewNotificationSuccess,
  receiveNewNotificationError,
} = notificationSlice.actions;

export default notificationSlice.reducer;
