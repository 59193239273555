import { Button, Col, Image, Row, Space, Tooltip, Typography } from "antd";
import classNames from "classnames";
import { NFT_FORMAT_BY_VALUE, NFT_STATUS, NFT_STATUS_BY_VALUE, TYPE_OF_ANT_DESIGN } from "common/constant";
import showMessage from "components/Message";
import ModalComponent from "components/Modal";
import ModalConfirm from "components/ModalConfirm";
import TableComponent from "components/Table";
import is from "date-fns/esm/locale/is/index";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { selectListPoolOfNft, selectNftDetail } from "redux/nft/selectors";
import { getListPoolOfNftStart, getNftDetailStart } from "redux/nft/slice";
import IconCopy from "resources/images/IconCopy";
import IconImagePlaceholder from "resources/images/IconImagePlaceholder";
import IconPop from "resources/images/IconPop";
import Nft from "resources/images/image-nft.png";
import { deleteNftByIdApi } from "services/nft";
import { convertAddress, convertAddressWallet, formatDateFull } from "utils";
import { useAppSelector } from "utils/customHooks";
import { columnListPoolInNft } from "./column";

const { Paragraph } = Typography;

const NftDetail = () => {
  const listChain = useAppSelector((state) => state.auth.chains);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isListPoolModalVisible, setIsListPoolModalVisible] = useState(false);
  const [searchData, setSearchData] = useState({ sortField: "name", sortType: 1, limit: -1 });

  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const nftDetail = useSelector(selectNftDetail);
  const listPoolInNft = useSelector(selectListPoolOfNft);

  useEffect(() => {
    if (id) {
      dispatch(getNftDetailStart({ id }));
    }
  }, [id]);

  useEffect(() => {
    if (isListPoolModalVisible) {
      dispatch(getListPoolOfNftStart({ id, ...searchData }));
    }
  }, [isListPoolModalVisible, id, searchData]);

  const handleToggleDeleteModal = () => {
    setIsDeleteModalVisible(!isDeleteModalVisible);
  };

  const handleGoBack = () => {
    history.push("/nft");
  };

  const toggleListPoolModal = () => {
    setIsListPoolModalVisible(!isListPoolModalVisible);
  };

  const handleConfirmDeleteNft = async (id) => {
    const res = await deleteNftByIdApi(id);
    if (!res?.meta?.code) {
      showMessage(TYPE_OF_ANT_DESIGN.SUCCESS, "message.S2");
      history.push("/nft");
    }
    handleToggleDeleteModal();
  };

  const onChangeTable = (pagination, filter, sorter, extra) => {
    let { order, field } = sorter;

    let data = "";
    if (order) {
      switch (order) {
        case "ascend":
          data = 1;
          break;
        case "descend":
          data = -1;
          break;
      }
    } else data = "";

    setSearchData({ ...searchData, sortField: field, sortType: data });
  };

  const mapNftDetail = () => {
    return [
      {
        title: "Registered Date",
        value: formatDateFull(nftDetail?.createdAt),
      },
      {
        title: "NFT Format",
        value: NFT_FORMAT_BY_VALUE[nftDetail?.type],
      },
      {
        title: "Total Deposited",
        value: nftDetail?.totalDeposited || 0,
      },
      {
        title: "Contract Address",
        value: (
          <Paragraph
            copyable={{
              icon: [<IconCopy key="copy-icon" />],
              text: nftDetail?.contractAddress,
            }}
            style={{ margin: 0 }}
          >
            {convertAddress(nftDetail?.contractAddress)}
          </Paragraph>
        ),
      },
      {
        title: "Total Claimed",
        value: nftDetail?.totalClaim || 0,
      },
      {
        title: "Token ID",
        value: (
          <Paragraph
            copyable={{
              icon: [<IconCopy key="copy-icon" />],
              text: nftDetail?.tokenId,
            }}
            style={{ margin: 0 }}
          >
            {convertAddress(nftDetail?.tokenId)}
          </Paragraph>
        ),
      },
      {
        title: "Status",
        value: (
          <span
            className={classNames({
              registered: nftDetail?.status === 1,
              deposited: nftDetail?.status === 2,
              "out-of-stock": nftDetail?.status === 3,
            })}
          >
            {NFT_STATUS_BY_VALUE[nftDetail?.status]}
          </span>
        ),
      },
      {
        title: "Pool",
        value: nftDetail?.numberOfPool ? (
          <span className="pool-number cursor-pointer" onClick={toggleListPoolModal}>
            {nftDetail?.numberOfPool}
          </span>
        ) : (
          "0"
        ),
      },
    ];
  };

  const renderNetwork = useMemo(() => {
    const currentNetwork = listChain?.find((chain) => chain?.chainId === nftDetail?.networkType);
    return currentNetwork ? (
      <>
        <img src={require(`resources/svg/${currentNetwork?.icon}`)} />
        <p className="mgb-0px">{currentNetwork?.chainName}</p>
      </>
    ) : (
      ""
    );
  }, [listChain, nftDetail]);

  return (
    <div className="nft-detail">
      <div className="nft-detail__header">
        <Space size={16}>
          <IconPop className="nft-detail__pop" onClick={handleGoBack} />
          <h2 className="nft-detail__title">NFT Rewards Detail</h2>
        </Space>
        {nftDetail?.status === NFT_STATUS.REGISTERED && !nftDetail.numberOfPool && (
          <Button className="nft-detail__delete" onClick={handleToggleDeleteModal}>
            Remove NFT
          </Button>
        )}
      </div>
      <div className="nft-detail__body">
        <div>
          <Tooltip title={nftDetail?.name}>
            <h2 className="nft-detail__body__title">{nftDetail?.name}</h2>
          </Tooltip>
          <Col span={24}>
            <div className="d-flex mgb-12px">
              <p className="nft-detail__body__property mgb-0px">Network</p>
              {renderNetwork}
            </div>
          </Col>
          <Row className="nft-detail__body__content" gutter={[24, 20]}>
            {mapNftDetail().map((item, index) => (
              <Col span={12} key={index}>
                <p className="nft-detail__body__property">{item.title}</p>
                <div>{item.value}</div>
              </Col>
            ))}
          </Row>
        </div>
        <div>
          <div className="nft-detail__body__image">
            {nftDetail ? (
              nftDetail.imageUrlM ? (
                <ReactPlayer
                  url={nftDetail.imageUrl}
                  light={nftDetail.imageUrlM}
                  controls
                  style={{ borderRadius: "5px" }}
                  playing
                  width="100%"
                />
              ) : (
                <Image preview src={nftDetail?.imageUrl} alt="nft-preview" />
              )
            ) : (
              <IconImagePlaceholder />
            )}
          </div>
        </div>
      </div>
      <ModalConfirm
        visible={isDeleteModalVisible}
        showCloseIcon={true}
        title="Remove NFT"
        content="Once you remove this NFT, all of its details will be lost. Do you want to remove this NFT?"
        onCancel={handleToggleDeleteModal}
        onConfirm={() => handleConfirmDeleteNft(id)}
        confirmText="Delete"
        cancelText="Cancel"
      />
      <ModalComponent title="Pool" visible={isListPoolModalVisible} onClose={toggleListPoolModal}>
        <TableComponent
          scroll={{ y: 220 }}
          dataSource={listPoolInNft}
          columns={columnListPoolInNft()}
          loading={false}
          showPagination={false}
          onChangeTable={onChangeTable}
          rowKey={(record) => record._id}
        />
      </ModalComponent>
    </div>
  );
};

export default NftDetail;
