import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Button, Spin, Tooltip } from "antd";
import classNames from "classnames";

import { getDashboardRequest } from "redux/user/slice";
import { useAppSelector } from "utils/customHooks";
import NumberFormat from "components/NumberFormat";

import IconReduced from "resources/images/IconReduced";
import IconIncrease from "resources/images/IconIncrease";
import { numberToRound } from "utils";

const Dashboard = () => {
  const dispatch = useDispatch();
  const dataDashboard: any = useAppSelector((state) => state.user.dashboard);
  const loadingDashboard: boolean = useAppSelector((state) => state.user.loadingDashboard);
  const listChain: never[] = useAppSelector((state) => state.auth.chains);
  const [currentChain, setCurrentChain] = useState("");

  useEffect(() => {
    const currentDate = moment().utc().format("YYYY-MM-DD");
    dispatch(getDashboardRequest({ data: { utcDate: currentDate, chainId: currentChain } }));
  }, [currentChain]);

  const renderIcon = (data: number) => {
    if (data < 0) {
      let value = data.toString().split("-")[1];
      if (value.toString().includes(".")) {
        return (
          <p className="difference">
            <IconReduced /> {renderAmount(numberToRound(Number(value), 2, true)[0])}%
          </p>
        );
      }
      return (
        <p className="difference">
          <IconReduced /> {renderAmount(value)}%
        </p>
      );
    }
    if (data > 0) {
      if (data.toString().includes(".")) {
        return (
          <p className="difference">
            <IconIncrease /> {renderAmount(numberToRound(Number(data), 2, true)[0])}%
          </p>
        );
      }
      return (
        <p className="difference">
          <IconIncrease /> {renderAmount(data)}%
        </p>
      );
    }

    return <p className="difference">{data ?? 0}%</p>;
  };

  const handleFilterChain = (chainId: any) => {
    setCurrentChain(chainId);
  };

  const renderTotalRewardFund = (value: string) => {
    return numberToRound(Number(value), 2, true)[0];
  };

  const renderAmount = (value: number | string, prefix: string = "") => {
    return (
      <Tooltip title={value}>
        <NumberFormat thousandSeparator value={value} displayType="text" prefix={prefix} />
      </Tooltip>
    );
  };

  return (
    <div className="dashboard">
      {loadingDashboard && (
        <div className="loading-pool-detail">
          <Spin size="large" />
        </div>
      )}
      <div className="group-btn">
        <Button
          className={classNames("group-btn__item", { active: !currentChain })}
          onClick={() => handleFilterChain("")}
        >
          All
        </Button>
        {listChain?.map((chain: any) => {
          return (
            <Button
              key={chain?.chainId}
              className={classNames("group-btn__item", { active: currentChain === chain?.chainId })}
              onClick={() => handleFilterChain(chain?.chainId)}
            >
              {chain?.icon && <img src={require(`resources/svg/${chain?.icon}`)} />}
              {chain?.chainName}
            </Button>
          );
        })}
      </div>
      <div className="dashboard-group">
        <div className="item">
          <p className="amount">{renderAmount(dataDashboard.totalPools)}</p>
          <p className="name">Pool Created </p>
          {renderIcon(dataDashboard.rateAllPool)}
        </div>
        <div className="item">
          <p className="amount">{renderAmount(dataDashboard.listPoolAllLives)}</p>
          <p className="name">Active Pools </p>
          {renderIcon(dataDashboard.rateAllPoolLive)}
        </div>
        <div className="item">
          <p className="amount">{renderAmount(dataDashboard.totalPollFinisheds)}</p>
          <p className="name">Finished Pools </p>
          {renderIcon(dataDashboard.rateAllPoolFinished)}
        </div>
      </div>
      <div className="dashboard-group">
        <div className="item">
          <p className="amount">{renderAmount(dataDashboard.totalUsers)}</p>
          <p className="name">Total Users </p>
          {renderIcon(dataDashboard.rateAllUser)}
        </div>
        <div className="item">
          <p className="amount">{renderAmount(dataDashboard.totalUserNows)}</p>
          <p className="name">New Users </p>
          {renderIcon(dataDashboard.rateAllUser)}
        </div>
      </div>
      <div className="dashboard-group">
        <div className="item">
          <p className="amount">{renderAmount(renderTotalRewardFund(dataDashboard.totalRewardFundUsd), "$")}</p>
          <p className="name">Total Rewards Funds </p>
          {renderIcon(dataDashboard.rateTotalRewardFun)}
        </div>
        <div className="item">
          <p className="amount">{renderAmount(renderTotalRewardFund(dataDashboard.totalValueLockedUsd), "$")}</p>
          <p className="name">Total Value Locked </p>
          {renderIcon(dataDashboard.rateTotalValueLocked)}
        </div>
        <div className="item">
          <p className="amount">{renderAmount(renderTotalRewardFund(dataDashboard.totalRewardClaimedUsd), "$")}</p>
          <p className="name">Total Rewards Claimed</p>
          {renderIcon(dataDashboard.rateTotalRewardClaimed)}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
