export const selectedUser = (state: any) => state?.user?.user;

export const selectedUserDetail = (state: any) => state?.user?.userDetail;

export const selectedActivitylog = (state: any) => state?.user?.activitylog;

export const selectedDataSearchUser = (state: any) => state?.user?.dataSearchUser;

export const selectedLoadingList = (state: any) => state?.user?.loadingListUser;

export const selectedLoadingDetail = (state: any) => state?.user?.loadingUserDetail;
