import React from 'react'

const IconDownload = () => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 0C5.58579 0 5.25 0.335786 5.25 0.75V7.89675L2.08624 4.72952C1.78056 4.4235 1.28495 4.4235 0.979262 4.72952C0.673579 5.03553 0.67358 5.53169 0.979263 5.83771L5.40717 10.2705C5.57003 10.4335 5.7868 10.5097 6.00003 10.499C6.21325 10.5097 6.43001 10.4335 6.59285 10.2705L11.0208 5.83771C11.3264 5.53169 11.3264 5.03553 11.0208 4.72951C10.7151 4.42349 10.2195 4.4235 9.91379 4.72952L6.75 7.89678V0.75C6.75 0.335786 6.41421 0 6 0Z" fill="#3772FF" />
      <path d="M0.75 12.75C0.75 12.3358 1.08579 12 1.5 12H10.5C10.9142 12 11.25 12.3358 11.25 12.75C11.25 13.1642 10.9142 13.5 10.5 13.5H1.5C1.08579 13.5 0.75 13.1642 0.75 12.75Z" fill="#3772FF" />
    </svg>

  )
}

export default IconDownload
