import React from "react";

const IconEdit = () => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_16382_12272)">
        <path
          d="M13.1427 8C12.8271 8 12.5712 8.25583 12.5712 8.57143V14.2856C12.5712 14.6012 12.3154 14.857 11.9998 14.857H1.71426C1.39866 14.857 1.14283 14.6012 1.14283 14.2856V2.85721C1.14283 2.54161 1.39866 2.28578 1.71426 2.28578H8.57132C8.88691 2.28578 9.14275 2.02994 9.14275 1.71434C9.14275 1.39874 8.88691 1.14294 8.57132 1.14294H1.71426C0.767501 1.14294 0 1.91045 0 2.85721V14.2856C0 15.2324 0.767501 15.9999 1.71426 15.9999H11.9998C12.9466 15.9999 13.7141 15.2324 13.7141 14.2856V8.5714C13.7141 8.25583 13.4583 8 13.1427 8Z"
          fill="#D13724"
        />
        <path
          d="M15.3595 0.640904C14.9492 0.230552 14.3927 6.41474e-05 13.8124 0.000131111C13.2318 -0.00154297 12.6748 0.229347 12.2656 0.641272L4.73882 8.16742C4.67637 8.23033 4.62927 8.30681 4.60111 8.39085L3.45828 11.8194C3.35853 12.1188 3.52042 12.4424 3.81984 12.5421C3.87794 12.5614 3.93877 12.5713 3.99998 12.5714C4.06131 12.5713 4.12228 12.5614 4.18054 12.5422L7.60907 11.3994C7.69328 11.3713 7.76978 11.3239 7.83249 11.2611L15.3592 3.73438C16.2135 2.88019 16.2136 1.49519 15.3595 0.640904Z"
          fill="#D13724"
        />
      </g>
      <defs>
        <clipPath id="clip0_16382_12272">
          <rect width={16} height={16} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconEdit;
