import { Button, Col, Input, Select, Space, Switch } from "antd";
import { NFT_FORMAT, PAGE_SIZE_DEFAULT, PUBLISH_TEXT, TYPE_INPUT } from "common/constant";
import FormItem from "components/FormItem";
import ModalComponent from "components/Modal";
import TableComponent from "components/Table";
import { columnsListSelectedNftRewards, columnsSelectNft } from "pages/pool/column";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLoading, selectListNftInPool, selectListNftRewards, selectTotalNft } from "redux/nft/selectors";
import { getListNftInPoolStart, getListNftRewardsStart } from "redux/nft/slice";
import { poolDetailRequest } from "redux/pool/slice";
import IconRefresh from "resources/images/IconRefresh";
import IconSearch from "resources/images/IconSearch";

const { Option } = Select;

const AddNFT = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    isSelectedNftEmpty,
    poolDetail,
    searchData,
    id,
    setIsSelectedNftEmpty,
    setSearchData,
    selectedNftRewards,
    setSelectedNftRewards,
    values,
  } = props;

  const listNftRewards = useSelector(selectListNftRewards);
  const isLoading = useSelector(selectIsLoading);
  const totalNft = useSelector(selectTotalNft);
  const listNftInPool = useSelector(selectListNftInPool);
  const listChain = useSelector((state) => state.auth.chains);

  const [isSelectNftModalVisible, setIsSelectedNftModalVisible] = useState(false);
  const [pendingSelectedNftRewards, setPendingSelectedNftRewards] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    if (isSelectedNftEmpty) {
      if (selectedNftRewards.length) setIsSelectedNftEmpty(false);
    }
  }, [isSelectedNftEmpty, selectedNftRewards]);

  useEffect(() => {
    if (isSelectNftModalVisible) {
      handleGetListNftRewards();
    }
  }, [
    isSelectNftModalVisible,
    searchData.page,
    searchData.pageSize,
    searchData.type,
    searchData.sortField,
    searchData.sortType,
  ]);

  useEffect(() => {
    if (id) {
      dispatch(poolDetailRequest({ data: { id } }));
      handleGetListNftRewards(id);
    }
  }, [id, searchData.type, searchData.sortField, searchData.sortType]);

  useEffect(() => {
    if (listNftInPool.length && poolDetail.isDraft) {
      setSelectedNftRewards([...listNftInPool]);
      setPendingSelectedNftRewards([...listNftInPool]);
      setSelectedRowKeys(listNftInPool.map((item) => item._id));
    }
  }, [listNftInPool, poolDetail]);

  const renderNetwork = useMemo(() => {
    const currentNetwork = listChain?.find((chain) => chain?.chainId === values?.network);
    return currentNetwork ? (
      <>
        <img src={require(`resources/svg/${currentNetwork?.icon}`)} />
        <p className="mgb-0px">{currentNetwork?.chainName}</p>
      </>
    ) : (
      ""
    );
  }, [listChain, values]);

  const onResetSearch = () => {
    if (!searchData.type) {
      setSearchData({
        ...searchData,
        type: null,
        status: null,
        keyword: "",
      });
      handleGetListNftRewards();
    } else
      setSearchData({
        ...searchData,
        type: null,
        keyword: "",
      });
  };

  const handleGetListNftRewards = (poolId = null) => {
    const { keyword, type, page, pageSize, sortField, sortType } = searchData;
    if (!poolId)
      dispatch(
        getListNftRewardsStart({
          keyword: keyword.trim(),
          type,
          offset: (page - 1) * pageSize,
          limit: pageSize,
          sortField,
          sortType,
          isAvailable: 1,
          chainId: values.network,
        })
      );
    else
      dispatch(
        getListNftInPoolStart({
          keyword,
          type,
          limit: -1,
          sortField,
          sortType,
          inPool: poolId,
        })
      );
  };

  const onChangeInput = (e) => {
    setSearchData({ ...searchData, keyword: e.target.value });
  };

  const handleBlurInput = (e) => {
    setSearchData({ ...searchData, keyword: e.target.value.trim() });
  };

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      handleGetListNftRewards(id);
    }
  };

  const onChangeSelectFormat = (value) => {
    if (Object.keys(poolDetail).length) {
      setSearchData({ ...searchData, type: value, inPool: id });
    } else setSearchData({ ...searchData, type: value });
  };

  const handleChangePagination = (page, pageSize) => {
    setSearchData({ ...searchData, page, pageSize });
  };

  const onClickSearch = () => {
    handleGetListNftRewards(id);
  };

  const handleChangeAdded = (checked) => {
    setSearchData({ ...searchData, isAdded: checked, page: 1, pageSize: PAGE_SIZE_DEFAULT });
  };

  const toNftDetail = (id) => {
    window.open(`/nft-detail/${id}`, "_blank");
  };

  const handleCheckAll = (selectedRowKeys, listNftRewards, pendingSelectedNftRewards) => {
    let sum = 0;
    listNftRewards.forEach((item) => {
      if (selectedRowKeys.includes(item._id)) {
        sum = sum + 1;
      }
    });
    return searchData.isAdded && pendingSelectedNftRewards.length === 0
      ? false
      : sum === (searchData.isAdded ? pendingSelectedNftRewards.length : listNftRewards.length);
  };

  const handleUnCheckAll = (
    selectedRowKeys,
    pendingSelectedNftRewards,
    setPendingSelectedNftRewards,
    listNftRewards,
    setSelectedRowKeys
  ) => {
    const keyValue = listNftRewards.map((item) => item._id);
    setSelectedRowKeys(selectedRowKeys.filter((item) => !keyValue.includes(item)));
    setPendingSelectedNftRewards(pendingSelectedNftRewards.filter((item) => !keyValue.includes(item._id)));
  };

  const onChangeTable = (pagination, filter, sorter, extra) => {
    let { order, field } = sorter;

    let data = "";
    if (order) {
      switch (order) {
        case "ascend":
          data = 1;
          break;
        case "descend":
          data = -1;
          break;
      }
    } else data = "";
    if (Object.keys(poolDetail).length) setSearchData({ ...searchData, sortField: field, sortType: data, inPool: id });
    else setSearchData({ ...searchData, sortField: field, sortType: data });
  };

  const onConfirmSelectedNftRewards = () => {
    toggleSelectNftModal();
    setSelectedNftRewards([...pendingSelectedNftRewards]);
    setSearchData({
      page: 1,
      pageSize: PAGE_SIZE_DEFAULT,
      type: null,
      keyword: "",
      sortField: "createdAt",
      sortType: -1,
      isAdded: false,
    });
  };

  const onChangeTier = (tier, record) => {
    const newListNftRewards = selectedNftRewards.map((item) => {
      if (item._id === record._id) return { ...item, tier };
      else return item;
    });
    setSelectedNftRewards(newListNftRewards);
  };

  const handleRemoveNftFromPool = (id) => {
    const newListNftRewards = selectedNftRewards.filter((item) => item._id !== id);
    setSelectedNftRewards(newListNftRewards);
    setPendingSelectedNftRewards(newListNftRewards);
    setSelectedRowKeys(selectedRowKeys.filter((item) => item !== id));
  };

  const onCloseSelectNftModal = () => {
    setSearchData({
      page: 1,
      pageSize: PAGE_SIZE_DEFAULT,
      type: null,
      keyword: "",
      sortField: "createdAt",
      sortType: -1,
      isAdded: false,
    });
    setPendingSelectedNftRewards([]);
    toggleSelectNftModal();
  };

  const handleOpenSelectNftModal = () => {
    toggleSelectNftModal();
    setPendingSelectedNftRewards([...selectedNftRewards]);
  };

  const toggleSelectNftModal = () => {
    setIsSelectedNftModalVisible(!isSelectNftModalVisible);
    setPendingSelectedNftRewards([...selectedNftRewards]);
    setSelectedRowKeys(selectedNftRewards.map((item) => item._id));
  };

  const toggleOpenSelectNftModal = () => {
    setIsSelectedNftModalVisible(true);
    setPendingSelectedNftRewards([...selectedNftRewards]);
  };

  const renderListSelectedNftRewards = (listNftReward) => {
    return listNftReward.map((item, index) => ({
      ...item,
      index: (searchData.page - 1) * searchData.pageSize + index + 1,
      tier: item?.tier || item?.detail?.tier,
    }));
  };

  const renderListNftRewards = (listNftRewards) => {
    return listNftRewards.map((item, index) => ({
      ...item,
      index: (searchData.page - 1) * searchData.pageSize + index + 1,
    }));
  };

  return (
    <>
      <Col span={24}>
        <div className="nft-rewards">
          <h2 className="nft-rewards__title">NFT Rewards *</h2>
          {isSelectedNftEmpty && <span className="error-text">{t("message.E2")}</span>}
          <div className="nft-rewards__head">
            <div className="search">
              {Object.keys(poolDetail).length > 0 && !poolDetail?.isDraft && (
                <>
                  <Input
                    className="search-input"
                    placeholder="Search by NFT name, contract address, token ID"
                    onKeyDown={handleSearch}
                    value={searchData.keyword}
                    onChange={onChangeInput}
                    suffix={<IconSearch onClick={onClickSearch} />}
                    maxLength={256}
                    onBlur={handleBlurInput}
                  />
                  <Select placeholder="Select NFT format" className="search-select" onChange={onChangeSelectFormat}>
                    <Option value={NFT_FORMAT.ALL}>All</Option>
                    <Option value={NFT_FORMAT.ERC_721}>ERC-721</Option>
                    <Option value={NFT_FORMAT.ERC_1155}>ERC-1155</Option>
                  </Select>
                </>
              )}
              {(Object.keys(poolDetail).length === 0 ||
                (Object.keys(poolDetail).length > 0 && poolDetail?.isDraft)) && (
                <Button className="add-nft" onClick={handleOpenSelectNftModal}>
                  Add NFT
                </Button>
              )}
            </div>
            <Space direction="vertical" size={8} align="end">
              <span className="nft-rewards__head__total">
                Total Added NFT:&nbsp;
                {Object.keys(poolDetail).length && !poolDetail?.isDraft
                  ? poolDetail?.totalAdded
                  : selectedNftRewards.length}
              </span>
              <span className="nft-rewards__head__total">
                Total Deposited:&nbsp;{Object.keys(poolDetail).length ? poolDetail?.totalDeposited : 0}
              </span>
            </Space>
          </div>
          <div className="nft-rewards__table">
            <TableComponent
              columns={columnsListSelectedNftRewards({
                onChangeTier,
                handleRemoveNftFromPool,
                isInPoolDetail: Boolean(id),
                isInPoolDraft: poolDetail?.isDraft,
              })}
              dataSource={
                Object.keys(poolDetail).length && !poolDetail?.isDraft
                  ? renderListSelectedNftRewards(listNftInPool)
                  : renderListSelectedNftRewards(selectedNftRewards)
              }
              NoData={<p className="nodata-text">{"Please select NFT Rewards"}</p>}
              loading={isLoading}
              showPagination={false}
              scroll={{ y: 260, x: 960 }}
              onChangeTable={Object.keys(poolDetail).length ? onChangeTable : null}
              rowKey={(record) => record._id}
            />
            {!Object.keys(poolDetail).length > 0 && (
              <FormItem
                name="isShow"
                typeInput={TYPE_INPUT.CHECKBOX}
                content={"Display on the user’s web interface"}
                disabled={values.publish === PUBLISH_TEXT.NOT_NOW}
              />
            )}
          </div>
        </div>
      </Col>

      <ModalComponent
        title="Add NFT"
        visible={isSelectNftModalVisible}
        onClose={onCloseSelectNftModal}
        wrapClassName="select-nft-modal"
        width="80%"
      >
        <div className="select-nft-modal__content">
          <div className="select-nft-modal__head">
            <div className="search">
              <Input
                className="search-input"
                placeholder="Search by NFT name, contract address, token ID"
                onKeyDown={handleSearch}
                value={searchData.keyword}
                onChange={onChangeInput}
                suffix={<IconSearch onClick={onClickSearch} />}
                maxLength={256}
                onBlur={handleBlurInput}
              />
              <Select
                placeholder="Select NFT format"
                className="search-select"
                onChange={onChangeSelectFormat}
                value={searchData.type}
              >
                <Option value={NFT_FORMAT.ALL}>All</Option>
                <Option value={NFT_FORMAT.ERC_721}>ERC-721</Option>
                <Option value={NFT_FORMAT.ERC_1155}>ERC-1155</Option>
              </Select>
              <div style={{ display: "flex" }}>
                <IconRefresh className="refresh-icon" onClick={onResetSearch} />
              </div>
            </div>
            <Space>
              <Switch checked={searchData.isAdded} onChange={handleChangeAdded} />
              <span>Selected</span>
            </Space>
          </div>
          <div className="network"> {renderNetwork}</div>
          <div className="select-nft-modal__body">
            <TableComponent
              current={!searchData.isAdded && searchData.page}
              pageSize={!searchData.isAdded && searchData.pageSize}
              columns={columnsSelectNft({
                toNftDetail,
                selectedRowKeys,
                pendingSelectedNftRewards,
                setPendingSelectedNftRewards,
                listNftRewards,
                setSelectedRowKeys,
                handleCheckAll,
                handleUnCheckAll,
                isAdded: searchData.isAdded,
              })}
              dataSource={
                searchData.isAdded
                  ? renderListNftRewards(pendingSelectedNftRewards)
                  : renderListNftRewards(listNftRewards)
              }
              loading={isLoading}
              scroll={searchData.isAdded ? { y: 600 } : null}
              showPagination={!searchData.isAdded}
              showSizeChanger={!searchData.isAdded}
              onChangePagination={handleChangePagination}
              onChangeTable={onChangeTable}
              total={searchData.isAdded ? pendingSelectedNftRewards.length : totalNft}
              rowKey={(record) => record._id}
            />
          </div>
          <div className="select-nft-modal__action">
            <Button className="select-nft-modal__action__cancel" onClick={onCloseSelectNftModal}>
              Cancel
            </Button>
            <Button
              className="select-nft-modal__action__confirm"
              onClick={onConfirmSelectedNftRewards}
              disabled={pendingSelectedNftRewards.length === 0}
            >
              Confirm
            </Button>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default AddNFT;
