import { STEP_FORGOT_PASSWORD } from "common/constant";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
// import StepConfirmOTPCode from "./components/StepConfirmOTPCode";
import StepSetNewPassword from "./components/StepSetNewPassword";
import StepSuccess from "./components/StepSuccess";

const PasswordReset = () => {
  // let location = useLocation() as any;
  const history = useHistory() as any;
  const search = history.location.search;

  const [step, setStep] = useState(STEP_FORGOT_PASSWORD.SET_NEW_PASSWORD);
  const [code, setCode] = useState(new URLSearchParams(search).get("codeResetPassword") ?? '');

  const onChangeStep = (step: string) => {
    if (!step) {
      return;
    }

    setStep(step);
  };

  return (
    <>
      {/* {step === STEP_FORGOT_PASSWORD.CONFIRM_OTP_CODE && (
        <StepConfirmOTPCode
          email={location.state?.email}
          onChangeStep={onChangeStep}
          onChangeOTPCode={onChangeOTPCode}
        />
      )} */}
      {step === STEP_FORGOT_PASSWORD.SET_NEW_PASSWORD && (
        <StepSetNewPassword codeResetPassword={code} onChangeStep={onChangeStep} />
      )}
      {step === STEP_FORGOT_PASSWORD.SUCCESS && <StepSuccess />}
    </>
  );
};

export default PasswordReset;
