import { Button, Menu } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import React, { Suspense, useState, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, Switch, useHistory, useLocation, withRouter } from "react-router-dom";

import IconMenu from "resources/images/IconMenu";
import HeaderCommon from "components/Header";
import PrivateRoute from "components/PrivateRoute";
import { privateRoutes } from "Routes";
import LoadingComponent from "components/Loading";
import LogoText from "resources/images/logo-text.svg";
import SubMenu from "antd/lib/menu/SubMenu";
import { useDispatch } from "react-redux";
import { handelResetSearchUser } from "redux/user/slice";
import { handelResetSearchPool } from "redux/pool/slice";
import IconClose from "resources/images/IconClose";
import { handleGetChainsRequest } from "redux/auth/slice";

const { Item } = Menu;

function PrivateLayout() {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();

  const toggleCollapse = () => {
    setCollapsed((collapsed) => !collapsed);
  };

  const goToPage = (e: any) => {
    history.push(e.key);
  };

  const selectedKey = location.pathname.split("/")[1];

  useEffect(() => {
    if (history.location.pathname.search("/pool") < 0) {
      dispatch(handelResetSearchPool({}));
    }

    if (history.location.pathname.search("/user") < 0) {
      dispatch(handelResetSearchUser({}));
    }
  }, [history.location.pathname]);

  useEffect(() => {
    dispatch(handleGetChainsRequest({}));
  }, []);

  const activeMenu = (data: any) => {
    const active = history.location?.pathname.split(/[/-]+/)[1] === data.split(["/"])[1];
    return active && "ant-menu-item-selected";
  };
  return (
    <Layout style={{ minHeight: "100vh" }} className="layout-private">
      <Sider collapsed={collapsed} theme={"light"} className="sider" width={256}>
        <div className="sider__top">
          <Link to="/" className={"logo-page"}>
            <img src={LogoText} alt="TTX FINANCE" className={"sider__text"} width={52} />
            {!collapsed && " TTX.FINANCE"}
          </Link>
        </div>
        <Menu theme="light" selectedKeys={[selectedKey]} mode="inline">
          {privateRoutes(t).map((item: any, index: number) => (
            <Fragment key={index}>
              {!!item.inMenu && (
                <Item key={item.path} icon={item.icon} onClick={goToPage} className={`${activeMenu(item.path)}`}>
                  {!collapsed && item.name}
                </Item>
              )}
              {item?.subMenu && (
                <SubMenu key={index} icon={item.icon} title={item.name}>
                  {item.subMenu.map((subItem: any, index: number) => (
                    <Menu.Item key={subItem.path} onClick={goToPage}>
                      {subItem.name}
                    </Menu.Item>
                  ))}
                </SubMenu>
              )}
            </Fragment>
          ))}
          {!collapsed && <IconClose className="close-icon" onClick={toggleCollapse} />}
          {collapsed && <Button className="button-collapse-slider" onClick={toggleCollapse} icon={<IconMenu />} />}
        </Menu>
      </Sider>
      <Layout>
        <HeaderCommon />
        <Content>
          <Suspense fallback={<LoadingComponent />}>
            <Switch>
              {privateRoutes(t).map((route: any) => {
                if (route?.subMenu) {
                  return route.subMenu.map(
                    (r: any) => r.component && <PrivateRoute key={r.path} component={r.component} {...r} />
                  );
                }
                return route.component && <PrivateRoute key={route.path} component={route.component} {...route} />;
              })}
            </Switch>
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
}

export default React.memo(withRouter(PrivateLayout));
