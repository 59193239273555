import React from "react";
import { Space, Switch, Tooltip } from "antd";

import IconEdit from "resources/images/IconEdit";
import IconEye from "resources/images/icon-eye.svg";
import ImageNft from "resources/images/image-nft.png";
import moment from "moment";
import { ACTION_NAME, FORMAT_DATE_TIME, POOL_REWARD_TYPE } from "common/constant";
import NumberFormat from "components/NumberFormat";
import { numberToRound } from 'utils';

export const columns = ({ history }) => {
  return [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Wallet address",
      dataIndex: "networkAddress",
      key: "networkAddress",
    },
    {
      title: "Update At",
      dataIndex: "lastDateLogin",
      key: "lastDateLogin",
      sorter: true,
      render: (value) => moment(value).format(FORMAT_DATE_TIME),
    },
    {
      title: "Total Locked",
      dataIndex: "totalLocked",
      key: "totalLocked",
      sorter: true,
      render: (value) => {
        return <NumberFormat thousandSeparator value={value} displayType="text" prefix={"$"} decimalScale={2} />;
      },
    },
    {
      title: "Total Rewards Claimed",
      dataIndex: "totalRewardClaimed",
      key: "totalRewardClaimed",
      sorter: true,
      render: (value) => {
        return <NumberFormat thousandSeparator value={value} displayType="text" prefix={"$"} decimalScale={2} />;
      },
    },
    {
      title: "Status",
      dataIndex: "isBlocked",
      key: "status",
      render: (value) => (value ? <p className="inactive">Inactive</p> : <p className="active">Active</p>),
    },
    {
      title: "",
      dataIndex: "isVerified",
      key: "isVerified",
      render: (isVerified, record, index) => {
        return (
          <div className="action-user">
            {/* <Switch checked={isVerified} /> */}
            <div
              onClick={() => {
                history.push({ pathname: `/user/${record?._id}`, state: { id: record?._id } });
              }}
            >
              <img src={IconEye} alt="" className="cursor-pointer" />
            </div>
          </div>
        );
      },
    },
  ];
};

export const dataNft = [
  {
    image: ImageNft,
    name: "Character - Level 1",
    tags: ["#Grunt", "#Common"],
  },
  {
    image: ImageNft,
    name: "Character - Level 1",
    tags: ["#Grunt", "#Common"],
  },
  {
    image: ImageNft,
    name: "Character - Level 1",
    tags: ["#Grunt", "#Common"],
  },
  {
    image: ImageNft,
    name: "Character - Level 1",
    tags: ["#Grunt", "#Common"],
  },
  {
    image: ImageNft,
    name: "Character - Level 1",
    tags: ["#Grunt", "#Common"],
  },
  {
    image: ImageNft,
    name: "Character - Level 1",
    tags: ["#Grunt", "#Common"],
  },
  {
    image: ImageNft,
    name: "Character - Level 1",
    tags: ["#Grunt", "#Common"],
  },
  {
    image: ImageNft,
    name: "Character - Level 1",
    tags: ["#Grunt", "#Common"],
  },
  {
    image: ImageNft,
    name: "Character - Level 1",
    tags: ["#Grunt", "#Common"],
  },
];

export const columnsActivityLog = (listChain) => {
  return [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "datcreatedAte",
      render: (value) => moment(value).format(FORMAT_DATE_TIME),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (value) => ACTION_NAME[value],
    },
    {
      title: "Detail",
      dataIndex: "nftName",
      key: "nftName",
      render: (value, record) => {
        if (record.type !== 1) return `Token ${record.currencyName}`;
        else return "Rewards";
      },
    },
    {
      title: "Pool",
      dataIndex: "poolName",
      key: "poolName",
      width: 200,
      render: (value, record) => (
        <Space size={8} align="center">
          <span className="textEllipsis name white-space-nomal">{value}</span>
        </Space>
      ),
    },
    {
      title: "Network",
      dataIndex: "networkType",
      key: "networkType",
      width: 100,
      render: (value) => {
        const currentChain = listChain?.find((chain) => chain?.chainId === value);
        return currentChain ? <img src={require(`resources/svg/${currentChain?.icon}`)} /> : "";
      },
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      width: 200,
      render: (value, record) => {
        if (record.type !== 1)
          return (
            <>
              {ACTION_NAME[record.action] + " "}
              {record.action === 3 ? (
                <NumberFormat thousandSeparator value={numberToRound(record.amountClaim, 8)[0]} displayType="text" />
              ) : (
                <NumberFormat thousandSeparator value={numberToRound(record.amountStake, 8)[0]} displayType="text" />
              )}
              {record.action !== 3 ? ` ${record.currencyName}` : ` ${record.currencyNameReward}`}
            </>
          );
        else
          return (
            <Tooltip title={record.nftName}>
              <span className="textEllipsis name-nft white-space-nomal">{record.nftName}</span>
            </Tooltip>
          );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: () => "Successful",
    },
  ];
};
