import { Button, Select, Spin } from "antd";
import { TOKEN_LIST_STATUS, TOKEN_TYPE, TYPE_ADD_TOKEN, TYPE_INPUT, TYPE_OF_ANT_DESIGN } from "common/constant";
import FormItem from "components/FormItem";
import showMessage from "components/Message";
import ModalConfirm from "components/ModalConfirm";
import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { selectedLoading } from "redux/token/selector";
import { handelAddTokenRequest } from "redux/token/slice";
import { getLengthObject, selectActiveProvider } from "utils";
import BaseWalletService from "utils/baseWallet";
import { useAppSelector } from "utils/customHooks";
import Web3 from "web3";
import * as Yup from "yup";

const AddToken = ({ handleCloseModal }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const wallet = new BaseWalletService().getInstance();
  const refForm = useRef();

  const [loading, setLoading] = useState(false);
  const loadingAddToken = useAppSelector(selectedLoading);
  const [tokenInfo, setTokenInfo] = useState({});
  const [visibleAddToken, setVisibleAddToken] = useState(false);
  const listChain = useAppSelector((state) => state.auth.chains);
  const [currentChain, setCurrentChain] = useState(listChain?.[0] || {});

  const toggleOpenModalConfirm = () => {
    setVisibleAddToken(true);
  };

  const handleClose = () => {
    handleCloseModal();
    setLoading(false);
  };

  const handleSubmit = () => {
    const data = {
      name: renderInfoToken("tokenSymbol"),
      key: renderInfoToken("tokenName"),
      chainId: currentChain?.chainId,
      isSingle: tokenInfo[0].isSingle,
      exchangeRate: refForm.current.values.price,
      tokenId: refForm.current.values.tokenAddress,
      decimal: tokenInfo[0]?.tokenDecimal,
      token0: tokenInfo[0].isSingle === TOKEN_TYPE.LP_TOKEN ? tokenInfo[0]?.tokenAddress : null,
      token1: tokenInfo[0].isSingle === TOKEN_TYPE.LP_TOKEN ? tokenInfo[1]?.tokenAddress : null,
    };
    dispatch(
      handelAddTokenRequest({
        data: data,
        callbackSuccess: (id) => {
          setVisibleAddToken(false);
          handleCloseModal();
          showMessage(TYPE_OF_ANT_DESIGN.SUCCESS, `You have added ${renderInfoToken("tokenSymbol")} successfully`);
          history.push(`/token/${id}`);
        },
        callbackError: (message) => {
          setVisibleAddToken(false);
          handleCloseModal();
          showMessage(TYPE_OF_ANT_DESIGN.ERROR, message);
        },
      })
    );
  };

  const handleGetTokenData = async (tokenAddress) => {
    const rpc = await selectActiveProvider(currentChain?.rpcUrls);
    tokenAddress &&
      rpc &&
      (await wallet.infoToken({
        tokenAddress: tokenAddress,
        rpc,
        chainId: currentChain?.chainId,
        callback: (tokenInfo) => {
          setTokenInfo(tokenInfo);
          setLoading(false);
        },
        callbackErrAddress: () => showMessage(TYPE_OF_ANT_DESIGN.ERROR, "message.E2_1"),
      }));
  };

  const onChangeAddressToken = async (e, setFieldValue, errors) => {
    setFieldValue("tokenAddress", e.target.value.trim());
    let isValid = Web3.utils.isAddress(e.target.value.trim());
    if (isValid) {
      setLoading(true);
      await handleGetTokenData(e.target.value.trim());
    } else setLoading(false);
  };

  const validationSchema = Yup.object().shape({
    price: Yup.number()
      .positive("Price must be positive")
      .required(t("message.E2", { fieldName: "Price" })),
  });

  const validateAddress = (value) => {
    let error;
    if (!value) error = t("message.E2");
    else {
      let isValid = Web3.utils.isAddress(value);
      if (!isValid) error = t("message.E2_1");
    }
    return error;
  };

  useEffect(() => {
    refForm.current.setFieldValue("price", tokenInfo[1]?.exchangeRate || tokenInfo[0]?.exchangeRate || 0);
  }, [tokenInfo]);

  const onChangeChainId = (e, setFieldValue, setTouched) => {
    setFieldValue(e.field.name, e.val);
    setCurrentChain(listChain?.find((chain) => chain?.chainId === e.val));
    setTokenInfo({});
    setFieldValue("tokenAddress", "");
    setFieldValue("price", 0);
    setTouched({ tokenAddress: false });
  };

  const renderInfoToken = (key) => {
    let data = "N/A";
    let keyConnect = "/";
    if (key === "tokenSymbol") keyConnect = "-";
    if (getLengthObject(tokenInfo) > 0) {
      if (getLengthObject(tokenInfo) > 1) {
        data =
          key == "tokenDecimal"
            ? `${tokenInfo[0]?.[key]} Decimals` || "N/A"
            : tokenInfo[0] && tokenInfo[1]
            ? `${tokenInfo[1]?.[key]}${keyConnect}${tokenInfo[0]?.[key]}`
            : "N/A";
      } else
        data =
          key == "tokenDecimal" && tokenInfo[0]
            ? `${tokenInfo[0]?.[key]} Decimals` || "N/A"
            : `${tokenInfo[0]?.[key]}` || "N/A";
    } else data = "--";
    return loading ? <Spin /> : data;
  };

  return (
    <div className="add-token">
      <Formik
        initialValues={{ tokenAddress: "", price: 0, chainId: currentChain?.chainId }}
        validationSchema={validationSchema}
        // onSubmit={handleSubmit}
        innerRef={refForm}
      >
        {({ values, setFieldValue, handleBlur, errors, setFieldError, touched, setTouched }) => {
          return (
            <Form>
              <FormItem
                name="chainId"
                className="select"
                options={listChain?.map((chain) => {
                  return { ...chain, name: chain?.chainName, value: chain?.chainId };
                })}
                typeInput={TYPE_INPUT.SELECT}
                label="Network"
                placeholder={"Select Network"}
                required
                getPopupContainer={(trigger) => trigger.parentElement}
                onChange={(e) => onChangeChainId(e, setFieldValue, setTouched)}
              />
              <FormItem
                name="tokenAddress"
                typeInput={TYPE_INPUT.TEXT}
                label="Token Address"
                placeholder="Enter Token Address"
                className="form-item__input"
                values={values.tokenAddress}
                required
                validate={validateAddress}
                onChange={(e) => onChangeAddressToken(e, setFieldValue, errors)}
                onBlur={handleBlur}
              />
              <div className="info-token">
                <div className="token--name">
                  <p className="title">Token Name</p>
                  <div className="content">{renderInfoToken("tokenName")}</div>
                </div>
                <div className="token--symbol">
                  <p className="title">Token Symbol</p>
                  <div className="content">{renderInfoToken("tokenSymbol")}</div>
                </div>
                <div className="token--name">
                  <p className="title">Token Decimals</p>
                  <div className="content">{renderInfoToken("tokenDecimal")}</div>
                </div>
              </div>
              <FormItem
                name="tokenType"
                typeInput={TYPE_INPUT.TEXT}
                label="Token Type"
                placeholder="Type"
                disabled
                value={
                  getLengthObject(tokenInfo) &&
                  TYPE_ADD_TOKEN.find((item) => item?.value === tokenInfo[0]?.typeToken)?.name
                }
                className="select select-token"
                getPopupContainer={(trigger) => trigger.parentElement}
              />

              <FormItem
                name="price"
                typeInput={TYPE_INPUT.NUMBER}
                label={"Token Price"}
                decimalScale={tokenInfo[0]?.tokenDecimal}
                placeholder={"Enter Token Price"}
                className="form-item__input"
                prefix={"$ "}
                thousandSeparator
                disabled={
                  tokenInfo[0]?.typeToken === TOKEN_LIST_STATUS.LISTED_ON_MULTIPLE_PLATFORM ||
                  !getLengthObject(tokenInfo)
                }
              />
              <div className="group-button">
                <Button className="button-cancel" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  className="button-add-token"
                  disabled={getLengthObject(errors) || loading || !values?.price || !values?.tokenAddress}
                  onClick={toggleOpenModalConfirm}
                >
                  Add Token
                </Button>
              </div>
              <ModalConfirm
                visible={visibleAddToken}
                showCloseIcon={true}
                title="Adding Confirmation"
                content={`Do you want to add token ${renderInfoToken("tokenSymbol")} to TTX.FINANCE?`}
                onCancel={() => setVisibleAddToken(false)}
                onConfirm={handleSubmit}
                confirmText="Confirm"
                loadingConfirm={loadingAddToken}
                cancelText="Cancel"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddToken;
