import React, { useMemo } from 'react';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { injected, walletConnect, getErrorConnectMessage } from '../connectors';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';

export const useConnectWallet = () => {
  const { activate, deactivate } = useWeb3React();
  const connect = useMemo(() => {
    return {
      connectInjected(metamaskNotFound?: any, callbackSuccess?: any, callbackError?: any): void {
        injected.isAuthorized().then(async (isAuthorized: boolean) => {
          callbackSuccess && callbackSuccess();
          console.log(isAuthorized, 'isAuthorizedisAuthorized');
          await activate(injected, undefined, true).catch((error) => {
            callbackError && callbackError();
            getErrorConnectMessage(error, deactivate, metamaskNotFound);
          });
        });
      },

      connectWalletConnect(): void {
        if (walletConnect instanceof WalletConnectConnector && walletConnect.walletConnectProvider?.wc?.uri) {
          walletConnect.walletConnectProvider = undefined;
        }
        walletConnect &&
          activate(walletConnect, undefined, true).catch(async (error) => {
            getErrorConnectMessage(error, deactivate);
            if (error instanceof UnsupportedChainIdError) {
              await activate(walletConnect, undefined, true).catch((error) => console.log(error, 'error')); // a little janky...can't use setError because the connector isn't set
            }
          });
      },

      connectDeactivate(): void {
        deactivate();
      },
    };
  }, []);

  return connect;
};

export default { useConnectWallet };
