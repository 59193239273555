import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Space, Spin, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { useHistory, useParams } from "react-router";
import SwiperCore, { Navigation, Pagination } from "swiper/core";

import TableComponent from "components/Table";
import { columnsActivityLog, dataNft } from "../column";
import { getActivitylogRequest, getUserDetailRequest } from "redux/user/slice";
import { useAppSelector } from "utils/customHooks";
import { selectedActivitylog, selectedLoadingDetail, selectedUserDetail } from "redux/user/slected";

import IconArrowLeft from "resources/images/IconArrowLeft";
import IconArrowRight from "resources/images/IconArrowRight";

// Import Swiper styles
import "swiper/swiper.scss";
import moment from "moment";
import { FORMAT_DATE_TIME, TYPE_OF_ANT_DESIGN } from "common/constant";
import ModalConfirm from "components/ModalConfirm";
import NumberFormat from "components/NumberFormat";
import { blockAndUnblockUser } from "services/user";
import showMessage from "components/Message";
import IconPop from "resources/images/IconPop";

SwiperCore.use([Pagination, Navigation]);

const renderNetwork = (listChain, chainId) => {
  const currentNetwork = listChain?.find((chain) => chain?.chainId === chainId);
  return currentNetwork ? (
    <img
      style={{ aspectRatio: "auto", marginRight: 3, width: 24 }}
      src={require(`resources/svg/${currentNetwork?.icon}`)}
    />
  ) : (
    ""
  );
};

const UserDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [visibleCofirm, setVisibleCofirm] = useState(false);

  const userDetail = useAppSelector(selectedUserDetail);
  const activitylog = useAppSelector(selectedActivitylog);
  const loadingUserDetail = useSelector(selectedLoadingDetail);
  const listChain = useAppSelector((state) => state.auth.chains);

  const { id } = useParams();

  useEffect(() => {
    dispatch(getUserDetailRequest({ data: { id } }));
  }, [id]);

  useEffect(() => {
    dispatch(getActivitylogRequest({ data: { userId: id, limit, offset: (page - 1) * limit } }));
  }, [id, page]);

  const handleBlock = async () => {
    setIsLoading(true);
    try {
      const response = await blockAndUnblockUser(id);
      if (response.meta.code === 0) {
        showMessage(TYPE_OF_ANT_DESIGN.SUCCESS, `${userDetail.isBlocked ? "Unblock" : "Block"} user successfully`);
      } else showMessage(TYPE_OF_ANT_DESIGN.ERROR, `Fail to ${userDetail.isBlocked ? "Unblock" : "Block"} user!`);
      dispatch(getUserDetailRequest({ data: { id } }));
      setVisibleCofirm(false);
      setIsLoading(false);
    } catch (error) {
      showMessage(TYPE_OF_ANT_DESIGN.ERROR, `Fail to ${userDetail.isBlocked ? "Unblock" : "Block"} user!`);
      setIsLoading(false);
    }
  };

  const onChangePagination = (page, pageSize) => {
    setPage(page);
  };

  const toNftDetail = (id) => {
    history.push(`/nft-detail/${id}`);
  };

  const handleGoBack = () => {
    history.push("/user");
  };

  return (
    <div>
      <Space size={16} className="mgb-20">
        <IconPop className="nft-detail__pop" onClick={handleGoBack} />
        <h2 className="nft-detail__title">User Detail</h2>
      </Space>{" "}
      <div className="user-detail-wrap">
        {loadingUserDetail && (
          <div className="loading-pool-detail">
            <Spin size="large" />
          </div>
        )}
        <div className="header-user-detail">
          <p className="title">Metamask: {userDetail && userDetail.networkAddress} </p>
          {userDetail.isBlocked ? (
            <Button onClick={() => setVisibleCofirm(true)}>Unblock</Button>
          ) : (
            <Button onClick={() => setVisibleCofirm(true)}>Block</Button>
          )}
        </div>
        <Divider />
        <div className="date">
          <p className="access">
            <span className="title">Access date: </span>
            {userDetail && moment(userDetail.createdAt).format(FORMAT_DATE_TIME)}
          </p>
          <p className="last-updated">
            <span className="title">Last updated date: </span>
            {userDetail && moment(userDetail.lastDateLogin).format(FORMAT_DATE_TIME)}
          </p>
        </div>
        <Divider />
        <div className="info">
          <div className="item">
            <label>Total Value Locked</label>
            <p className="price">
              {userDetail && (
                <NumberFormat
                  thousandSeparator
                  value={userDetail.totalLocked}
                  displayType="text"
                  prefix={"$"}
                  decimalScale={2}
                />
              )}
            </p>
          </div>
          <div className="item">
            <label>Total Rewards Claimed</label>
            <p className="price">
              {userDetail && (
                <NumberFormat
                  thousandSeparator
                  value={userDetail.totalRewardClaimed}
                  displayType="text"
                  prefix={"$"}
                  decimalScale={2}
                />
              )}
            </p>
          </div>
          <div className="item">
            <label>Number of NFTs:</label>
            <p className="amount">{userDetail?.totalNft}</p>
          </div>
        </div>
        <div className="nft">
          <div className="nft-header">
            <h2 className="title">NFT Rewards</h2>
            <div className="nft-header__right">
              <Button shape="circle" icon={<IconArrowLeft />} ref={navigationPrevRef} />
              <Button shape="circle" icon={<IconArrowRight />} ref={navigationNextRef} />
            </div>
          </div>

          <div className="list-nft">
            {Object.keys(userDetail).length && userDetail?.nfts.length ? (
              <Swiper
                navigation={{
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current,
                }}
                onInit={(swiper) => {
                  swiper.params.navigation.prevEl = navigationPrevRef.current;
                  swiper.params.navigation.nextEl = navigationNextRef.current;
                  swiper.navigation.update();
                }}
                slidesPerView={5}
                slidesPerGroup={3}
                spaceBetween={24}
              >
                {userDetail.nfts.map((e, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="item cursor-pointer" onClick={() => toNftDetail(e._id)}>
                        <img src={e.imageUrlM || e.imageUrl} alt="thumbnail" />
                        <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
                          {renderNetwork(listChain, e.networkType)}
                          <Tooltip title={e.name}>
                            <p className="name" style={{ marginTop: 0 }}>
                              {e.name}
                            </p>
                          </Tooltip>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            ) : (
              <span>{t("message.E10_1")}</span>
            )}
          </div>
        </div>
        <div className="active-log">
          <p className="title">Active log</p>
          <TableComponent
            columns={columnsActivityLog(listChain)}
            dataSource={activitylog.records}
            // showPagination={false}
            width={520}
            className="table-active-log"
            showPagination
            current={page}
            onChangePagination={onChangePagination}
            total={activitylog.total}
            loading={false}
          />
        </div>

        <ModalConfirm
          visible={visibleCofirm}
          title="Are you sure?"
          content={
            userDetail.isBlocked
              ? "The user you unblock will have access to the system. Do you want to unblock this user?"
              : "The user blocked will not access the system anymore. Do you want to block this user?"
          }
          onCancel={() => setVisibleCofirm(false)}
          onConfirm={handleBlock}
          cancelText={"Cancel"}
          confirmText={userDetail.isBlocked ? "Unblock" : "Block"}
          loadingConfirm={isLoading}
        />
      </div>
    </div>
  );
};

export default UserDetail;
