import IconEye from "resources/images/icon-eye.svg";
import React from "react";
import { Space, Tooltip, Typography } from "antd";
import IconCopy from "resources/images/IconCopy";
import moment from "moment";
import { FORMAT_DATE_FULL, NFT_FORMAT_BY_VALUE, NFT_STATUS_BY_VALUE } from "common/constant";
import { convertAddress, formatDateFull } from "utils";
import classNames from "classnames";

const { Paragraph } = Typography;

const nftRewardManagementColumn = (toNftDetail, listChain) => [
  {
    title: "No",
    dataIndex: "index",
    key: "index",
    width: "60px",
  },
  {
    title: "Registered Date",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 150,
    sorter: true,
    render: (value) => formatDateFull(value),
  },
  {
    title: "NFT name",
    dataIndex: "name",
    key: "name",
    sorter: true,
    width: 200,
    ellipsis: true,
    render: (value, record) => (
      <Tooltip title={value}>
        <Space size={8} align="center">
          <img src={record.imageUrlM || record.imageUrl} className="image-thumbnail" alt="thumbnail" />
          <span className="textEllipsis name-nft white-space-nomal">{value}</span>
        </Space>
      </Tooltip>
    ),
  },
  {
    title: "Network",
    dataIndex: "networkType",
    key: "networkType",
    width: 100,
    render: (value) => {
      const currentChain = listChain?.find((chain) => chain?.chainId === value);
      return currentChain ? <img src={require(`resources/svg/${currentChain?.icon}`)} /> : "";
    },
  },
  {
    title: "NFT Format",
    dataIndex: "type",
    key: "type",
    width: 150,
    render: (value) => NFT_FORMAT_BY_VALUE[value],
  },
  {
    title: "Token ID",
    dataIndex: "tokenId",
    key: "tokenId",
    width: 150,
    render: (value) => (
      <Paragraph
        copyable={{
          icon: [<IconCopy key="copy-icon" />],
          text: value,
        }}
        style={{ margin: 0 }}
      >
        {convertAddress(value)}
      </Paragraph>
    ),
  },
  {
    title: "Total Deposited",
    dataIndex: "totalDeposited",
    key: "totalDeposited",
    width: 150,
    sorter: true,
    render: (value) => value || 0,
  },
  {
    title: "Total Claimed",
    dataIndex: "totalClaim",
    key: "totalClaim",
    width: 150,
    sorter: true,
    render: (value) => value || 0,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: 120,
    render: (value) => (
      <span className={classNames({ registered: value === 1, deposited: value === 2, "out-of-stock": value === 3 })}>
        {NFT_STATUS_BY_VALUE[value]}
      </span>
    ),
  },
  {
    title: "Action",
    key: "action",
    width: 100,
    render: (record) => <img src={IconEye} onClick={() => toNftDetail(record._id)} className="cursor-pointer" />,
  },
];
export default nftRewardManagementColumn;
