import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pool: {
    total: 0,
    records: [],
  },
  loadingListPool: false,
  poolDetail: {},
  loadingPoolDetail: false,
  loadingUpdateStatus: false,
  poolTypes: [],
  network: "",
};

export const poolSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    handelResetSearchPool: (state, action) => {
      return { ...state };
    },
    getListPoolRequest: (state, action) => ({
      ...state,
      loadingListPool: true,
      poolDetail: {},
    }),
    getListPoolResponse: (state, action) => {
      const { payload } = action;
      const { data } = payload;

      return { ...state, pool: { ...data }, loadingListPool: false };
    },

    createPoolRequest: (state, action) => ({
      ...state,
    }),
    createPoolResponse: (state, action) => {
      const { payload } = action;

      return { ...state, pool: { ...payload } };
    },
    poolDetailRequest: (state, action) => ({
      ...state,
      loadingPoolDetail: true,
    }),
    poolDetailResponse: (state, action) => {
      const { payload } = action;

      return { ...state, poolDetail: { ...payload }, loadingPoolDetail: false };
    },
    poolUpdateRequest: (state, action) => ({
      ...state,
    }),
    poolUpdateResponse: (state, action) => {
      const { payload } = action;

      return { ...state };
    },
    poolDeleteRequest: (state, action) => ({
      ...state,
    }),
    poolDeleteResponse: (state, action) => {
      const { payload } = action;

      return { ...state };
    },
    poolUpdateStatusRequest: (state, action) => ({
      ...state,
      loadingUpdateStatus: true,
    }),
    poolUpdateStatusResponse: (state, action) => {
      const { payload } = action;

      return { ...state, loadingUpdateStatus: false };
    },

    poolUpdateTxidRequest: (state, action) => ({
      ...state,
    }),

    getPoolTypeRequest: (state) => {
      return { ...state };
    },

    getPoolTypeSuccess: (state, action) => {
      const { payload } = action;
      return { ...state, poolTypes: payload };
    },

    handleSetNetworkPool: (state, action) => {
      const { payload } = action;

      return { ...state, ...payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  handelResetSearchPool,
  getListPoolRequest,
  getListPoolResponse,
  createPoolRequest,
  createPoolResponse,
  poolDetailRequest,
  poolDetailResponse,
  poolUpdateRequest,
  poolUpdateResponse,
  poolDeleteRequest,
  poolDeleteResponse,
  poolUpdateStatusRequest,
  poolUpdateStatusResponse,
  poolUpdateTxidRequest,
  getPoolTypeRequest,
  getPoolTypeSuccess,
  handleSetNetworkPool,
} = poolSlice.actions;

export default poolSlice.reducer;
