import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

import FormItem from "components/FormItem";
import { MAX_LENGTH_PASSWORD, REGEX_PASSWORD, TYPE_INPUT } from 'common/constant';
import { changePasswordStart, logout } from 'redux/auth/slice';

const ChangePassword = () => {

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const initialForm = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  }

  const onResponse = () => {
    message.success('Change password successed!');
    history.push('/login');
    dispatch(logout());
  };

  const onSubmit = (value: any) => {
    const data = {
      "currentPassword": value.currentPassword,
      "newPassword": value.newPassword
    }
    dispatch(changePasswordStart({ data, onResponse }))
  }

  const ChangePassSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .trim()
      .required(
        t('message.E2', {
          fieldName: t('common.text_password_old'),
        }),
      ).matches(REGEX_PASSWORD, t("message.E8")),
    newPassword: Yup.string()
      .trim()
      .required(t('message.E2', { fieldName: t('common.text_password_new') }))
      .matches(REGEX_PASSWORD, t('message.E8')),
    confirmPassword: Yup.string()
      .trim()
      .required(t('message.E2', { fieldName: t('common.text_password_confirm') }))
      .oneOf(["newPassword", Yup.ref("newPassword")], t('message.E7')),
  });

  return (
    <div className="change-password-wrap">
      <div className="change-password">
        <p className="title-change-pass">Change Password</p>
        <Formik initialValues={initialForm} onSubmit={onSubmit} validationSchema={ChangePassSchema}>
          {({ values }) => {
            return (
              < Form >
                <FormItem
                  typeInput={TYPE_INPUT.PASSWORD}
                  name="currentPassword"
                  className="form-item__input full-width"
                  placeholder={`${t("common.text_password_old")}`}
                  required
                  maxLength={MAX_LENGTH_PASSWORD}
                />
                <FormItem
                  typeInput={TYPE_INPUT.PASSWORD}
                  name="newPassword"
                  className="form-item__input full-width"
                  placeholder={`${t("common.text_password_new")}`}
                  required
                  maxLength={MAX_LENGTH_PASSWORD}
                />
                <FormItem
                  typeInput={TYPE_INPUT.PASSWORD}
                  name="confirmPassword"
                  className="form-item__input full-width"
                  placeholder={`${t("common.text_password_confirm")}`}
                  required
                  maxLength={MAX_LENGTH_PASSWORD}
                />
                <Button
                  htmlType="submit"
                  className="form-item__button-submit full-width"
                  loading={isLoading}
                >
                  {t("common.button_change_pass")}
                </Button>
              </Form>
            )
          }
          }
        </Formik>
      </div >
    </div>
  )
}

export default ChangePassword
