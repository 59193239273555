import { useWeb3React } from "@web3-react/core";
import { Button, Input, Select } from "antd";
import { NFT_FORMAT, PAGE_SIZE_DEFAULT } from "common/constant";
import TableComponent from "components/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { selectIsLoading, selectListNftRewards, selectTotalNft } from "redux/nft/selectors";
import { getListNftRewardsStart } from "redux/nft/slice";
import { connectWalletModalRequest } from "redux/user/slice";
import IconAdd from "resources/images/IconAdd";
import IconRefresh from "resources/images/IconRefresh";
import IconSearch from "resources/images/IconSearch";
import { useAppSelector } from "utils/customHooks";
import nftRewardManagementColumn from "./column";

const { Option } = Select;

const NftReward = () => {
  const listChain = useAppSelector((state) => state.auth.chains);
  const [searchData, setSearchData] = useState({
    page: 1,
    pageSize: PAGE_SIZE_DEFAULT,
    type: null,
    chainId: null,
    status: null,
    keyword: "",
    sortField: "createdAt",
    sortType: -1,
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const { account, library } = useWeb3React();

  const listNftRewards = useSelector(selectListNftRewards);
  const isLoading = useSelector(selectIsLoading);
  const totalNft = useSelector(selectTotalNft);

  const handleGetListNftRewards = () => {
    const { keyword, status, type, page, pageSize, sortField, sortType, chainId } = searchData;
    dispatch(
      getListNftRewardsStart({
        keyword: keyword.trim(),
        status,
        type,
        chainId,
        offset: (page - 1) * pageSize,
        limit: pageSize,
        sortField,
        sortType,
      })
    );
  };

  useEffect(() => {
    handleGetListNftRewards();
  }, [
    searchData.page,
    searchData.pageSize,
    searchData.type,
    searchData.chainId,
    searchData.status,
    searchData.sortField,
    searchData.sortType,
  ]);

  const renderDataSource = (data) => {
    return data.map((item, index) => ({
      ...item,
      index: (searchData.page - 1) * searchData.pageSize + index + 1,
    }));
  };

  const onChangeSelectFormat = (value) => {
    setSearchData({ ...searchData, type: value });
  };

  const onChangeSelectNetwork = (value) => {
    setSearchData({ ...searchData, chainId: value });
  };

  const onChangeSelectStatus = (value) => {
    setSearchData({ ...searchData, status: value });
  };

  const onChangeInput = (e) => {
    setSearchData({ ...searchData, keyword: e.target.value });
  };

  const handleBlurInput = (e) => {
    setSearchData({ ...searchData, keyword: e.target.value.trim() });
  };

  const onResetSearch = () => {
    if (!searchData.status && !searchData.type) {
      setSearchData({
        ...searchData,
        type: null,
        status: null,
        keyword: "",
      });
      dispatch(
        getListNftRewardsStart({
          keyword: "",
          status: searchData.status,
          type: searchData.type,
          offset: (searchData.page - 1) * searchData.pageSize,
          limit: searchData.pageSize,
        })
      );
    } else
      setSearchData({
        ...searchData,
        type: null,
        status: null,
        keyword: "",
      });
  };

  const onChangeTable = (pagination, filter, sorter, extra) => {
    let { order, field } = sorter;

    let data = "";
    if (order) {
      switch (order) {
        case "ascend":
          data = 1;
          break;
        case "descend":
          data = -1;
          break;
      }
    } else data = "";

    setSearchData({ ...searchData, sortField: field, sortType: data });
  };

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      handleGetListNftRewards();
      setSearchData({ ...searchData, page: 1, pageSize: PAGE_SIZE_DEFAULT });
    }
  };

  const onClickSearch = () => {
    handleGetListNftRewards();
    setSearchData({ ...searchData, page: 1, pageSize: PAGE_SIZE_DEFAULT });
  };

  const handleChangePagination = (page, pageSize) => {
    setSearchData({ ...searchData, page, pageSize });
  };

  const toRegisterNftReward = () => {
    if (account && library) history.push("/nft-register");
    else dispatch(connectWalletModalRequest({ data: null }));
  };

  const toNftDetail = (id) => {
    history.push(`/nft-detail/${id}`);
  };

  return (
    <div className="nft-reward">
      <h2 className="nft-reward__title">NFT Reward Management</h2>
      <div className="nft-reward__header">
        <div className="search">
          <Input
            className="search-input"
            placeholder="Search by NFT name,  contract address, token ID"
            onKeyDown={handleSearch}
            value={searchData.keyword}
            onChange={onChangeInput}
            suffix={<IconSearch onClick={onClickSearch} />}
            maxLength={256}
            onBlur={handleBlurInput}
          />
          <Select
            onChange={onChangeSelectFormat}
            value={searchData.type}
            className="search-select"
            getPopupContainer={(trigger) => trigger.parentElement}
            placeholder="Select NFT Format"
          >
            <Option value={NFT_FORMAT.ALL}>All</Option>
            <Option value={NFT_FORMAT.ERC_721}>ERC-721</Option>
            <Option value={NFT_FORMAT.ERC_1155}>ERC-1155</Option>
          </Select>
          <Select
            onChange={onChangeSelectNetwork}
            value={searchData.chainId}
            style={{ width: 228 }}
            className="search-select"
            getPopupContainer={(trigger) => trigger.parentElement}
            placeholder="Network"
          >
            <Option value="">All Chain</Option>
            {listChain?.map((chain) => {
              return (
                <Option value={chain?.chainId} key={chain?.chainId}>
                  {chain?.chainName}
                </Option>
              );
            })}
          </Select>
          <Select
            onChange={onChangeSelectStatus}
            value={searchData.status}
            className="search-select"
            getPopupContainer={(trigger) => trigger.parentElement}
            placeholder="Select NFT Status"
          >
            <Option value={0}>All</Option>
            <Option value={1}>Registered</Option>
            <Option value={2}>Deposited</Option>
            <Option value={3}>Out Of Stock</Option>
          </Select>
          <IconRefresh className="refresh-icon" onClick={onResetSearch} />
        </div>
        <Button className="nft-reward__register" icon={<IconAdd />} onClick={toRegisterNftReward}>
          Register NFT
        </Button>
      </div>
      <TableComponent
        columns={nftRewardManagementColumn(toNftDetail, listChain)}
        dataSource={listNftRewards && listNftRewards.length && renderDataSource(listNftRewards)}
        pageSize={searchData.pageSize}
        loading={isLoading}
        current={searchData.page}
        total={totalNft}
        showSizeChanger={true}
        onChangePagination={handleChangePagination}
        onChangeTable={onChangeTable}
        rowKey={(record) => record._id}
      />
    </div>
  );
};

export default NftReward;
