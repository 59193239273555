import * as React from "react";
import { SVGProps } from "react";

const IconImagePlaceholder = (props: SVGProps<SVGSVGElement>) => (
  <svg width={75} height={74} viewBox="0 0 75 74" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M43.6666 18.5L32.1041 33.9167L40.8916 45.6333L35.9582 49.3333C30.7474 42.3958 22.0833 30.8333 22.0833 30.8333L3.58325 55.5H71.4166L43.6666 18.5Z"
      fill="#B1B1B1"
    />
  </svg>
);

export default IconImagePlaceholder;
