import { CloseOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React from "react";

const ModalConfirm = ({
  visible,
  content,
  title,
  onConfirm,
  onCancel,
  cancelText,
  confirmText,
  isErr,
  loadingConfirm,
  showCloseIcon,
}) => {
  return (
    <Modal visible={visible} footer={false} closable={false} className="modal-confirm">
      {showCloseIcon && <Button onClick={onCancel} className="ant-modal-close" icon={<CloseOutlined />} />}
      <p className="title">{title}</p>
      <div className="content">{content}</div>
      <div className="group-btn">
        {!isErr && (
          <Button className="btn-cancel" onClick={onCancel}>
            {cancelText}
          </Button>
        )}
        <Button className="btn-confirm" onClick={onConfirm} loading={loadingConfirm}>
          {confirmText}
        </Button>
      </div>
    </Modal>
  );
};

export default ModalConfirm;
