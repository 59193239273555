import React from "react";
import moment from "moment";
import {  Space, Switch, Tooltip, Select, Typography, Button, Checkbox } from "antd";

import IconEye from "resources/images/icon-eye.svg";
import {
  FORMAT_DATE_TIME_API,
  NFT_FORMAT_BY_VALUE,
  NFT_TIER,
  STAKING_MECHANISM_TYPE,
} from "common/constant";
import NumberFormat from "components/NumberFormat";
import IconDelete from "resources/images/IconDelete";
import IconCopy from "resources/images/IconCopy";
import { convertAddress, formatDateFull } from "utils";

const { Option } = Select;
const { Paragraph } = Typography;

export const columnsPoolManagement = ({ history, handleChangeShowPool, listChain }) => {
  return [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: "60px",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt ",
      width: "200px",
      sorter: true,
      render: (value) => moment(value).format(FORMAT_DATE_TIME_API),
    },
    {
      title: "Pool Name",
      dataIndex: "name",
      key: "name",
      width: "300px",
      ellipsis: true,
    },
    {
      title: "Network",
      dataIndex: "networkType",
      key: "networkType",
      width: 100,
      render: (value) => {
        const currentChain = listChain?.find((chain) => chain?.chainId === value);
        return currentChain ? <img src={require(`resources/svg/${currentChain?.icon}`)} /> : "";
      },
    },
    {
      title: "Mechanism",
      dataIndex: "modelType",
      key: "modelType",
      width: "120px",
      render: (value) => (value === STAKING_MECHANISM_TYPE.LINEAR ? "Linear" : "Allocation"),
    },
    {
      title: "Pool Type",
      dataIndex: "type",
      key: "type",
      width: "130px",
      render: (value, record) => {
        return `${record.currencyName} Staking`;
      },
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      width: "120px",

      render: (value) => {
        let data = "Flexible";
        if (value) data = `${value} days`;
        return data;
      },
    },
    {
      title: "Users",
      dataIndex: "numberOfUser",
      key: "numberOfUser",
      width: "150px",

      sorter: true,
    },
    {
      title: "Rewards fund",
      dataIndex: "totalRewardFundUsd",
      key: "totalRewardFundUsd",
      width: "150px",

      sorter: true,

      render: (value) => {
        return <NumberFormat thousandSeparator value={value} prefix={"$"} decimalScale={2} displayType="text" />;
      },
    },
    {
      title: "Total Locked",
      dataIndex: "totalValueLockedUsd",
      key: "totalValueLockedUsd",
      width: "150px",

      sorter: true,

      render: (value) => (
        <NumberFormat thousandSeparator value={value} displayType="text" prefix={"$"} decimalScale={2} />
      ),
    },
    {
      title: "Total Rewards Claimed",
      dataIndex: "totalRewardClaimedUsd",
      key: "totalRewardClaimedUsd",
      sorter: true,
      width: "180px",

      render: (value) => (
        <NumberFormat thousandSeparator value={value} displayType="text" prefix={"$"} decimalScale={2} />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "150px",

      render: (value, records) => {
        let text = "Draft";
        if (records.isDraft) {
          text = "Draft";
        } else if (records.isPublish && records.status === 1) {
          text = "Live";
        } else {
          if (records.status === 3) {
            text = "Finished";
          } else {
            text = "Waiting for public";
          }
        }

        return text;
      },
    },
    {
      title: "",
      dataIndex: "isShow",
      key: "isShow",
      width: "120px",

      render: (value, record, index) => {
        return (
          <div className="action-user">
            <Tooltip title={record.isDraft ? "Draft" : value ? "Public" : "Hidden"}>
              <Switch
                disabled={record.isDraft}
                checked={value}
                onChange={(e) => handleChangeShowPool(record?._id, e)}
              />
            </Tooltip>
            <div
              onClick={() => {
                history.push({ pathname: `/pool/${record?._id}`, state: { id: record?._id } });
              }}
            >
              <Tooltip title="Detail">
                <img src={IconEye} />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];
};

export const columnsPoolNftManagement = ({ history, handleChangeShowPool, listChain }) => {
  return [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      width: "60px",
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt ",
      width: "200px",

      sorter: true,
      render: (value) => moment(value).format(FORMAT_DATE_TIME_API),
    },
    {
      title: "Pool Name",
      dataIndex: "name",
      key: "name",
      width: "300px",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Network",
      dataIndex: "networkType",
      key: "networkType",
      width: 100,
      render: (value) => {
        const currentChain = listChain?.find((chain) => chain?.chainId === value);
        return currentChain ? <img src={require(`resources/svg/${currentChain?.icon}`)} /> : "";
      },
    },
    {
      title: "Mechanism",
      dataIndex: "modelType",
      key: "mechanism",
      width: "120px",
      render: (value) => (value === STAKING_MECHANISM_TYPE.LINEAR ? "Linear" : "Allocation"),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      width: "120px",

      render: (value) => {
        let data = "Flexible";
        if (value) data = `${value} days`;
        return data;
      },
    },
    {
      title: "Users",
      dataIndex: "numberOfUser",
      key: "numberOfUser",
      width: "150px",

      sorter: true,
    },
    {
      title: "Rewards Fund",
      dataIndex: "totalRewardFundUsd",
      key: "totalRewardFundUsd",
      width: "150px",
      render: (value) => {
        return <NumberFormat thousandSeparator value={value} prefix={"$"} decimalScale={2} displayType="text" />;
      },
    },
    {
      title: "NFT Fund",
      dataIndex: "totalDeposited",
      key: "totalDeposited",
      width: "150px",
      sorter: true,
      render: (value, record) => {
        return (
          <NumberFormat
            thousandSeparator
            value={(record?.nfts?.totalDeposited || 0) - (record?.nfts?.totalWithdraw || 0)}
            decimalScale={2}
            displayType="text"
          />
        );
      },
    },
    {
      title: "Total Locked",
      dataIndex: "totalValueLockedUsd",
      key: "totalValueLockedUsd",
      width: "150px",
      render: (value) => (
        <NumberFormat thousandSeparator value={value} displayType="text" prefix={"$"} decimalScale={2} />
      ),
    },
    {
      title: "Total NFT Claimed",
      dataIndex: "totalClaim",
      key: "totalClaim",
      sorter: true,
      width: "150px",
      render: (value, record) => (
        <NumberFormat
          thousandSeparator
          value={(record?.nfts?.totalClaim || 0) + (record?.nfts?.totalUnclaim || 0)}
          displayType="text"
          decimalScale={2}
        />
      ),
    },
    {
      title: "Total Rewards Claimed",
      dataIndex: "totalRewardClaimedUsd",
      key: "totalRewardClaimedUsd",
      width: "180px",
      sorter: true,
      render: (value) => <NumberFormat thousandSeparator value={value} displayType="text" decimalScale={2} />,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "150px",

      render: (value, records) => {
        let text = "Draft";
        if (records.isDraft) {
          text = "Draft";
        } else if (records.isPublish && records.status === 1) {
          text = "Live";
        } else {
          if (records.status === 3) {
            text = "Finished";
          } else {
            text = "Waiting for public";
          }
        }
        return text;
      },
    },
    {
      title: "Action",
      dataIndex: "isShow",
      key: "isShow",
      width: "120px",

      render: (value, record, index) => {
        return (
          <div className="action-user">
            <Tooltip title={record.isDraft ? "Draft" : value ? "Public" : "Hidden"}>
              <Switch
                disabled={record.isDraft}
                checked={value}
                onChange={(e) => handleChangeShowPool(record?._id, e)}
              />
            </Tooltip>
            <div
              onClick={() => {
                history.push({ pathname: `/pool/${record?._id}`, state: { id: record?._id } });
              }}
            >
              <Tooltip title="Detail">
                <img src={IconEye} />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];
};

export const columnsPoolNftDetail = ({ toggleWithdrawModal, toggleDepositModal, handleApproveNft, isDraft }) => {
  return [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      width: 50,
    },
    {
      title: "NFT Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 200,
      render: (value, record) => (
        <Tooltip title={value}>
          <Space size={8} align="center">
            <img src={record.imageUrlM || record.imageUrl} className="image-thumbnail" alt="thumbnail" />
            <span className="textEllipsis name-nft">{value}</span>
          </Space>
        </Tooltip>
      ),
    },
    {
      title: "NFT Format",
      dataIndex: "type",
      key: "type",
      width: 200,
      render: (value) => NFT_FORMAT_BY_VALUE[value],
    },
    {
      title: "Token ID",
      dataIndex: "tokenId",
      key: "tokenId",
      width: 200,
      render: (value) => (
        <Paragraph
          style={{ margin: 0 }}
          copyable={{
            icon: [<IconCopy key="copy-icon" />],
            text: value,
          }}
        >
          {convertAddress(value)}
        </Paragraph>
      ),
    },
    {
      title: "Total Deposited",
      dataIndex: "totalDeposited",
      key: "totalDeposited",
      sorter: true,
      width: 150,
      render: (value) => value || 0,
    },
    {
      title: "Total Claimed",
      dataIndex: "totalClaim",
      key: "totalClaim",
      width: 150,
      sorter: true,
      render: (value) => value || 0,
    },
    {
      title: "Reward Rule",
      dataIndex: "tier",
      key: "tier",
      sorter: true,
      render: (value) => <span>Tier {value || 1}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: ({
        name,
        contractAddress,
        tokenId,
        imageUrl,
        imageUrlM,
        type,
        totalDeposited,
        isApproved,
        _id,
        detail,
      }) => (
        <Space size={2}>
          <Button
            type="text"
            className="withdraw-nft cursor-pointer"
            onClick={() =>
              toggleWithdrawModal({
                name,
                address: contractAddress,
                tokenId,
                imageUrl: imageUrlM || imageUrl,
                type,
                id: _id,
                balance: detail.numberOfCopies,
              })
            }
            disabled={totalDeposited === 0 || isDraft || !detail.numberOfCopies}
          >
            Withdraw NFT
          </Button>
          <Button
            type="text"
            className="deposit-nft cursor-pointer"
            disabled={isDraft}
            onClick={
              isApproved
                ? () =>
                    toggleDepositModal({
                      name,
                      address: contractAddress,
                      tokenId,
                      imageUrl: imageUrlM || imageUrl,
                      type,
                      id: _id,
                    })
                : () => handleApproveNft(contractAddress)
            }
          >
            {isApproved ? "Deposit NFT" : "Approve NFT"}
          </Button>
        </Space>
      ),
    },
  ];
};

export const columnsListSelectedNftRewards = ({
  onChangeTier,
  handleRemoveNftFromPool,
  isInPoolDetail,
  isInPoolDraft,
}) => {
  return [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      width: 50,
    },
    {
      title: "NFT Name",
      dataIndex: "name",
      key: "name",
      sorter: isInPoolDetail && !isInPoolDraft,
      width: 200,
      render: (value, record) => (
        <Space size={8} align="center">
          <img src={record.imageUrlM || record.imageUrl} className="image-thumbnail" alt="thumbnail" />
          <span className="textEllipsis name">{value}</span>
        </Space>
      ),
    },
    {
      title: "Token ID",
      dataIndex: "tokenId",
      key: "tokenId",
      width: 150,
      render: (value, record) => (
        <Paragraph
          style={{ margin: 0 }}
          copyable={{
            icon: [<IconCopy key="copy-icon" />],
            text: value,
          }}
        >
          {convertAddress(value)}
        </Paragraph>
      ),
    },
    {
      title: "NFT Format",
      dataIndex: "type",
      key: "type",
      render: (value) => NFT_FORMAT_BY_VALUE[value],
    },
    {
      title: "Total Deposited",
      dataIndex: "totalDeposited",
      key: "totalDeposited",
      sorter: isInPoolDetail && !isInPoolDraft,
      render: (value, record) => record?.detail?.totalDeposited || 0,
    },
    {
      title: "Total Claimed",
      dataIndex: "claimedCopies",
      key: "claimedCopies",
      sorter: isInPoolDetail && !isInPoolDraft,
      render: (value, record) => record?.detail?.totalClaim || 0,
    },
    {
      title: "Reward Rule",
      key: "tier",
      dataIndex: "tier",
      with: 200,
      render: (value, record) => {
        return (
          <Select
            defaultValue={1}
            value={value || NFT_TIER.TIER_1}
            className="select-tier"
            onChange={(value) => onChangeTier(value, record)}
            disabled={isInPoolDetail && !isInPoolDraft}
          >
            <Option value={NFT_TIER.TIER_1}>Tier 1</Option>
            <Option value={NFT_TIER.TIER_2}>Tier 2</Option>
            <Option value={NFT_TIER.TIER_3}>Tier 3</Option>
            <Option value={NFT_TIER.TIER_4}>Tier 4</Option>
          </Select>
        );
      },
    },
    ...(!isInPoolDetail || (isInPoolDetail && isInPoolDraft)
      ? [
          {
            title: "Action",
            key: "Action",
            render: (record) => (
              <IconDelete className="cursor-pointer" onClick={() => handleRemoveNftFromPool(record._id)} />
            ),
          },
        ]
      : []),
  ];
};

export const columnsSelectNft = ({
  toNftDetail,
  selectedRowKeys,
  pendingSelectedNftRewards,
  setPendingSelectedNftRewards,
  listNftRewards,
  setSelectedRowKeys,
  handleCheckAll,
  handleUnCheckAll,
  isAdded,
}) => {
  return [
    {
      title: (
        <Checkbox
          checked={handleCheckAll(selectedRowKeys, listNftRewards, pendingSelectedNftRewards)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedRowKeys([
                ...selectedRowKeys,
                ...listNftRewards.map((item) => {
                  return !selectedRowKeys.includes(item._id) ? item._id : null;
                }),
              ]);
              setPendingSelectedNftRewards([
                ...pendingSelectedNftRewards,
                ...listNftRewards.filter((item) => !selectedRowKeys.includes(item._id)),
              ]);
            } else {
              handleUnCheckAll(
                selectedRowKeys,
                pendingSelectedNftRewards,
                setPendingSelectedNftRewards,
                listNftRewards,
                setSelectedRowKeys
              );
            }
          }}
          disabled={isAdded && pendingSelectedNftRewards.length === 0}
        ></Checkbox>
      ),
      width: 50,
      dataIndex: "",
      key: "",
      render: (value, record) => (
        <Checkbox
          checked={selectedRowKeys.includes(record._id)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedRowKeys([...selectedRowKeys, record?._id]);
              setPendingSelectedNftRewards([...pendingSelectedNftRewards, record]);
            } else {
              setPendingSelectedNftRewards(pendingSelectedNftRewards.filter((item) => item._id !== record._id));
              setSelectedRowKeys(selectedRowKeys.filter((item) => item !== record._id));
            }
          }}
        ></Checkbox>
      ),
    },
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      width: 50,
    },
    {
      title: "NFT Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      sorter: true,
      render: (value, record) => (
        <Space size={8} align="center">
          <img src={record.imageUrlM || record.imageUrl} className="image-thumbnail" alt="thumbnail" />
          <Tooltip title={value}>
            <span className="textEllipsis" style={{ maxWidth: "100px", display: "block" }}>
              {value}
            </span>
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "Token ID",
      dataIndex: "tokenId",
      key: "tokenId",
      render: (value, record) => (
        <Paragraph
          style={{ margin: 0 }}
          copyable={{
            icon: [<IconCopy key="copy-icon" />],
            text: value,
          }}
        >
          {convertAddress(value)}
        </Paragraph>
      ),
    },
    {
      title: "NFT Format",
      dataIndex: "type",
      key: "type",
      render: (value) => NFT_FORMAT_BY_VALUE[value],
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <span className="cursor-pointer" onClick={() => toNftDetail(record._id)}>
          View Details
        </span>
      ),
    },
  ];
};

export const columnsRewardsActivity = () => [
  {
    title: "No",
    dataIndex: "index",
    key: "index",
  },
  {
    title: "Created Date",
    dataIndex: "createdAt",
    key: "createdAt",
    sorter: true,
    render: (value) => formatDateFull(value),
  },
  {
    title: "User Wallet Address",
    dataIndex: "userAddress",
    key: "userAddress",
    render: (value, record) => (
      <Paragraph
        style={{ margin: 0 }}
        copyable={{
          icon: [<IconCopy key="copy-icon" />],
          text: value,
        }}
      >
        {convertAddress(value)}
      </Paragraph>
    ),
  },
  {
    title: "Staked Amount",
    dataIndex: "amountStake",
    key: "amountStake",
    sorter: true,
    render: (value) => <NumberFormat value={value} displayType="text" thousandSeparator decimalScale={2} />,
  },
  {
    title: "NFT Name",
    dataIndex: "nftName",
    key: "nftName",
    sorter: true,
    width: 200,
    render: (value, record) => (
      <Tooltip title={value}>
        <Space size={8} align="center">
          <img src={record?.nft?.imageUrlM || record?.nft?.imageUrl} className="image-thumbnail" alt="thumbnail" />
          <span className="textEllipsis name-nft">{value}</span>
        </Space>
      </Tooltip>
    ),
  },
];
