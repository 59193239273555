import { call, put, takeLatest } from "redux-saga/effects";
import { dashboardAPI, listUserAPI, userDetailAPI, activitylogsAPI } from "../../services/user";
import { ResponseGenerator } from "../../types";
import {
  getListUserResponse,
  getListUserRequest,
  getUserDetailRequest,
  getUserDetailResponse,
  getDashboardRequest,
  getDashboardResponse,
  getActivitylogRequest,
  getActivitylogResponse,
} from "./slice";
import { deleteKeyNullOfObject } from "utils";

function* getListUserSaga({ payload }: any) {
  const { data } = payload;

  try {
    const response: ResponseGenerator = yield listUserAPI(deleteKeyNullOfObject(data));
    if (response.meta.code === 0) {
      yield put(getListUserResponse({ data: response.data, dataSearch: data }));
    }
  } catch (error) {
    console.error(error);
    yield put(getListUserResponse({ data: {}, dataSearch: data }));
  } finally {
    // yield call(callback);
  }
}

function* getUserDetailSaga({ payload }: any) {
  const { data } = payload;
  try {
    const response: ResponseGenerator = yield userDetailAPI(data);
    if (response.meta.code === 0) {
      yield put(getUserDetailResponse(response.data));
    }
  } catch (error) {
    console.error(error);
  } finally {
    // yield call(callback);
  }
}

function* getDashboardSaga({ payload }: any) {
  const { data } = payload;
  try {
    const response: ResponseGenerator = yield dashboardAPI(data);
    if (response.meta.code === 0) {
      yield put(getDashboardResponse(response.data));
    }
  } catch (error) {
    console.error(error);
    yield put(getDashboardResponse({}));
  } finally {
    // yield call(callback);
  }
}

function* getActivitylogSaga({ payload }: any) {
  const { data } = payload;
  try {
    const response: ResponseGenerator = yield activitylogsAPI(data);
    if (response.meta.code === 0) {
      yield put(getActivitylogResponse(response.data));
    }
  } catch (error) {
    console.error(error);
  } finally {
    // yield call(callback);
  }
}

function* watchUser() {
  yield takeLatest(getListUserRequest, getListUserSaga);
  yield takeLatest(getUserDetailRequest, getUserDetailSaga);
  yield takeLatest(getDashboardRequest, getDashboardSaga);
  yield takeLatest(getActivitylogRequest, getActivitylogSaga);
}

export default watchUser;
