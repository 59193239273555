import { Button, message } from "antd";
import Title from "antd/lib/typography/Title";
import { MAX_LENGTH_PASSWORD, REGEX_PASSWORD, STEP_FORGOT_PASSWORD, TYPE_INPUT } from "common/constant";
import FormItem from "components/FormItem";
import { Form, Formik } from "formik";
import { trim } from "lodash";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router';
import { resetPasswordStart, checkExpireLinkStart } from "redux/auth/slice";
import { handleBlurField } from 'utils';
import { useAppDispatch } from "utils/customHooks";
import * as Yup from "yup";

const StepSetNewPassword: FC<{ codeResetPassword: string; onChangeStep: any }> = ({
  codeResetPassword,
  onChangeStep,
}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    newPassword: "",
    confirmNewPassword: "",
  };

  useEffect(() => {
    dispatch(checkExpireLinkStart({ data: codeResetPassword, onResponse: onResponseCheckExpire }))
  }, [codeResetPassword])

  const onResponseCheckExpire = (data: boolean) => {
    if (!data) message.error('The link is unavailable at this time.');
  }

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .trim()
      .matches(REGEX_PASSWORD, t("message.E8"))
      .required(t("message.E2", { fieldName: t("forgot_password.set_new_password.text_new_password") })),
    confirmNewPassword: Yup.string()
      .trim()
      .required(t("message.E2", { fieldName: t("forgot_password.set_new_password.text_confirm_password") }))
      .oneOf(["newPassword", Yup.ref("newPassword")], t("message.E7")),
  });

  const onResponse = (status: boolean) => {
    setIsLoading(false);
    if (status) {
      onChangeStep(STEP_FORGOT_PASSWORD.SUCCESS);
    }
  };

  const handleSubmit = (data: any) => {
    const { newPassword } = data;
    dispatch(
      resetPasswordStart({
        data: {
          codeResetPassword: codeResetPassword,
          password: trim(newPassword),
        },
        onResponse,
      })
    );
    setIsLoading(true);
  };

  return (
    <div className="password-reset">
      {/* <img src={Logo} alt="add value" className="password-reset__logo" /> */}
      <Title level={5} className="password-reset__title">
        {t("forgot_password.set_new_password.title")}
      </Title>
      {/* <div
        className="password-reset__description"
        dangerouslySetInnerHTML={{
          __html: t("forgot_password.set_new_password.description", { email }),
        }}
      /> */}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnMount
        className="form-password-reset"
      >
        {({ setFieldValue, handleBlur }) => (
          <Form>
            <FormItem
              name="newPassword"
              className="form-item__input full-width"
              // label={t("forgot_password.set_new_password.text_new_password")}
              placeholder={t("forgot_password.set_new_password.text_new_password")}
              required
              typeInput={TYPE_INPUT.PASSWORD}
              onBlur={handleBlurField(setFieldValue, 'newPassword', handleBlur)}
              maxLength={MAX_LENGTH_PASSWORD}
            />
            <FormItem
              name="confirmNewPassword"
              className="form-item__input full-width"
              // label={t("forgot_password.set_new_password.text_confirm_password")}
              placeholder={t("forgot_password.set_new_password.text_confirm_password")}
              required
              typeInput={TYPE_INPUT.PASSWORD}
              onBlur={handleBlurField(setFieldValue, 'confirmNewPassword', handleBlur)}
              maxLength={MAX_LENGTH_PASSWORD}
            />
            <Button htmlType="submit" className="form-item__button-submit" loading={isLoading}>
              {t("forgot_password.set_new_password.button")}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default StepSetNewPassword;
