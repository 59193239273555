import { Button } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Logo from "resources/images/icon-password-set-successfully.svg";

const StepSuccess = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const goToLoginPage = () => {
    history.push("/login");
  };

  return (
    <div className="password-reset">
      <img src={Logo} alt="add value" className="password-reset__logo" />
      <Title level={5} className="password-reset__title">
        {t("forgot_password.password_reset_successfully.title")}
      </Title>
      <Paragraph className="password-reset__description">
        {t("forgot_password.password_reset_successfully.description")}
      </Paragraph>
      <Button className="form-item__button-submit" onClick={goToLoginPage}>
        {t("login.button_sign_in")}
      </Button>
    </div>
  );
};

export default StepSuccess;
