import { put, takeLatest } from "redux-saga/effects";
import { getCurrency } from "services/token";
import {
  createPoolAPI,
  listPoolAPI,
  poolDeleteAPI,
  poolDetailAPI,
  poolUpdateStatusAPI,
  poolUpdateTxidAPI,
  updatePoolAPI,
} from "../../services/pool";
import {
  createPoolRequest,
  getListPoolRequest,
  getListPoolResponse,
  getPoolTypeRequest,
  getPoolTypeSuccess,
  poolDeleteRequest,
  poolDetailRequest,
  poolDetailResponse,
  poolUpdateRequest,
  poolUpdateResponse,
  poolUpdateStatusRequest,
  poolUpdateStatusResponse,
  poolUpdateTxidRequest,
} from "./slice";
import { deleteKeyNullOfObject } from 'utils';

function* getListPoolSaga({ payload }) {
  const { data } = payload;

  try {
    const response = yield listPoolAPI(deleteKeyNullOfObject(data));
    if (response.meta.code === 0) {
      yield put(getListPoolResponse({ data: response.data }));
    }
  } catch (error) {
    console.error(error);
    yield put(getListPoolResponse({ data: {} }));
  } finally {
    // yield call(callback);
  }
}

function* createPoolSaga({ payload }) {
  const { data, callbackSuccess, callbackError } = payload;

  try {
    const response = yield createPoolAPI(data);
    if (response.meta.code === 0) {
      // yield put(createPoolResponse(response.data));
      callbackSuccess(response.data._id);
    }
  } catch (error) {
    console.error(error);
    callbackError();
  } finally {
    // yield put(createPoolResponse({ loadingCreate: false }));
  }
}

function* poolDetailSaga({ payload }) {
  const { data } = payload;
  try {
    const response = yield poolDetailAPI(data);
    if (response.meta.code === 0) {
      yield put(poolDetailResponse(response.data));
    }
  } catch (error) {
    console.error(error);
  } finally {
    // yield call(callback);
  }
}

function* poolUpdateSaga({ payload }) {
  const { data, callbackFinish, callbackSuccess } = payload;

  try {
    const response = yield updatePoolAPI(data);
    if (response.meta.code === 0) {
      yield put(poolUpdateResponse(response.data));
      callbackSuccess(response.data._id);
    }
  } catch (error) {
    console.error(error);
  } finally {
    callbackFinish();
    // yield call(callback);
  }
}

function* poolDeleteRequestSaga({ payload }) {
  const { id, callbackSuccess } = payload;

  try {
    const response = yield poolDeleteAPI({ id });
    if (response.meta.code === 0) {
      callbackSuccess();
    }
  } catch (error) {
    console.error(error);
  } finally {
    // callbackFinish();
    // yield call(callback);
  }
}

function* poolUpdateStatusSaga({ payload }) {
  const { id, callbackSuccess } = payload;

  try {
    const response = yield poolUpdateStatusAPI({ poolId: id });
    if (response.meta.code === 0) {
      callbackSuccess();
      yield put(poolUpdateStatusResponse({}));
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(poolUpdateStatusResponse({}));

    // callbackFinish();
    // yield call(callback);
  }
}

function* poolUpdateTxidSaga({ payload }) {
  const { data, callbackSuccess, callbackFinish } = payload;

  try {
    const response = yield poolUpdateTxidAPI(data);
    if (response.meta.code === 0) {
      callbackSuccess();
    }
  } catch (error) {
    console.error(error);
  } finally {
    callbackFinish();
    // yield call(callback);
  }
}

function* getPoolTypeSaga() {
  try {
    const response = yield getCurrency();
    if (response) {
      yield put(getPoolTypeSuccess(response.data.records));
    }
  } catch (error) {}
}

function* watchPool() {
  yield takeLatest(getListPoolRequest, getListPoolSaga);
  yield takeLatest(createPoolRequest, createPoolSaga);
  yield takeLatest(poolDetailRequest, poolDetailSaga);
  yield takeLatest(poolUpdateRequest, poolUpdateSaga);
  yield takeLatest(poolDeleteRequest, poolDeleteRequestSaga);
  yield takeLatest(poolUpdateStatusRequest, poolUpdateStatusSaga);
  yield takeLatest(poolUpdateTxidRequest, poolUpdateTxidSaga);
  yield takeLatest(getPoolTypeRequest, getPoolTypeSaga);
}

export default watchPool;
