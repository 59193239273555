import React from 'react';
import { Button } from 'antd';

const ButtonConnectWallet = ({ className, children, onSubmit, hanhdleOnPopup }) => {
  return (
    <Button onClick={onSubmit} className={className}>
      {children}
    </Button>
  );
};

export default ButtonConnectWallet;
