import * as React from "react";

function IconLogout(props: any) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.191 12.5a.833.833 0 01.864.802c.04 1.037.094 1.793.148 2.336.054.534.376.856.826.91.53.066 1.278.119 2.304.119a19.2 19.2 0 002.305-.118c.45-.055.772-.377.825-.911.1-1.007.204-2.747.204-5.638 0-2.89-.103-4.63-.204-5.638-.053-.534-.376-.856-.825-.91a19.218 19.218 0 00-2.305-.119c-1.026 0-1.774.053-2.304.118-.45.055-.772.377-.826.911-.054.543-.109 1.3-.148 2.336a.833.833 0 11-1.665-.063c.04-1.064.097-1.855.155-2.438.122-1.225.976-2.24 2.282-2.4.607-.074 1.424-.13 2.506-.13 1.083 0 1.9.056 2.507.13 1.306.16 2.16 1.175 2.282 2.4.107 1.079.211 2.877.211 5.803s-.104 4.725-.211 5.803c-.122 1.225-.976 2.24-2.282 2.4a20.85 20.85 0 01-2.507.13c-1.082 0-1.899-.055-2.506-.13-1.306-.16-2.16-1.175-2.282-2.4a40.23 40.23 0 01-.155-2.438.833.833 0 01.801-.864z"
        fill="#605F5F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.006 12.327a.833.833 0 11-1.179 1.179l-2.916-2.917a.833.833 0 010-1.178l2.916-2.917a.833.833 0 111.179 1.179L4.512 9.167H12.5a.833.833 0 010 1.666H4.512l1.494 1.494z"
        fill="#605F5F"
      />
    </svg>
  );
}

export default IconLogout;
