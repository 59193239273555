import api from "./api";

export const registerAPI = (payload: any) => api.post("/auth/admin/register", payload);

export const loginAPI = (payload: any) => api.post("/auth/admin/login", payload);

export const forgotPasswordAPI = (payload: any) => api.post("/auth/admin/forgot-password", payload);

export const verifyOTPCodeAPI = (payload: any) => api.post("/auth/admin/verify-account", payload);

export const changePasswordAPI = (payload: any) => api.post("/auth/change-password", payload);

export const resetPasswordAPI = (payload: any) => api.post("/auth/admin/reset-password", payload);

export const checkExpireLinkAPI = (payload: any) => api.get(`/auth/admin/check-code/${payload.code}`);

export const getChainsApi = (params: any) => api.get('/chain/list');
