import React from 'react'

const IconPoolManagement = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 11.25H16.5V12.75H7.5V11.25Z" fill="#757575" />
      <path d="M6.525 4.71375C6.67142 4.41347 6.74832 4.08407 6.75 3.75C6.75 3.30499 6.61804 2.86998 6.37081 2.49997C6.12357 2.12996 5.77217 1.84157 5.36104 1.67127C4.94991 1.50097 4.49751 1.45642 4.06105 1.54323C3.62459 1.63005 3.22368 1.84434 2.90901 2.15901C2.59434 2.47368 2.38005 2.87459 2.29323 3.31105C2.20642 3.74751 2.25097 4.19991 2.42127 4.61104C2.59157 5.02217 2.87996 5.37357 3.24997 5.62081C3.61998 5.86804 4.05499 6 4.5 6C4.83409 5.99848 5.16352 5.92156 5.46375 5.775L7.5 7.80975V9.75H9V7.1895L6.525 4.71375ZM4.5 4.5C4.35167 4.5 4.20666 4.45601 4.08332 4.3736C3.95999 4.29119 3.86386 4.17406 3.80709 4.03701C3.75033 3.89997 3.73547 3.74917 3.76441 3.60368C3.79335 3.4582 3.86478 3.32456 3.96967 3.21967C4.07456 3.11478 4.2082 3.04335 4.35368 3.01441C4.49917 2.98547 4.64997 3.00033 4.78701 3.05709C4.92406 3.11386 5.04119 3.20999 5.1236 3.33332C5.20601 3.45666 5.25 3.60167 5.25 3.75C5.25 3.94891 5.17098 4.13968 5.03033 4.28033C4.88968 4.42098 4.69891 4.5 4.5 4.5Z" fill="#757575" />
      <path d="M14.25 3.74999C14.2518 3.33707 14.14 2.93161 13.9268 2.57801C13.7135 2.2244 13.4071 1.9363 13.041 1.74525C12.675 1.5542 12.2634 1.46757 11.8514 1.49485C11.4393 1.52212 11.0428 1.66226 10.7051 1.89991C10.3674 2.13755 10.1016 2.46354 9.93687 2.84217C9.77211 3.2208 9.71472 3.63747 9.77099 4.04654C9.82726 4.45561 9.99501 4.84131 10.2559 5.16139C10.5168 5.48147 10.8607 5.7236 11.25 5.86124V9.74999H12.75V5.86199C13.1874 5.70781 13.5664 5.42204 13.835 5.0439C14.1035 4.66577 14.2485 4.21379 14.25 3.74999ZM12 4.49999C11.8516 4.49999 11.7066 4.45601 11.5833 4.37359C11.46 4.29118 11.3638 4.17405 11.3071 4.037C11.2503 3.89996 11.2355 3.74916 11.2644 3.60367C11.2933 3.45819 11.3648 3.32455 11.4696 3.21966C11.5745 3.11477 11.7082 3.04334 11.8537 3.0144C11.9991 2.98546 12.1499 3.00032 12.287 3.05708C12.424 3.11385 12.5412 3.20998 12.6236 3.33331C12.706 3.45665 12.75 3.60166 12.75 3.74999C12.75 3.9489 12.671 4.13967 12.5303 4.28032C12.3897 4.42097 12.1989 4.49999 12 4.49999Z" fill="#757575" />
      <path d="M19.5 1.5C18.9034 1.5006 18.3315 1.73784 17.9097 2.15967C17.4878 2.5815 17.2506 3.15345 17.25 3.75C17.2517 4.08407 17.3286 4.41347 17.475 4.71375L15 7.18875V9.75H16.5V7.8105L18.5363 5.775C18.8365 5.92156 19.1659 5.99847 19.5 6C20.0967 6 20.669 5.76295 21.091 5.34099C21.5129 4.91903 21.75 4.34674 21.75 3.75C21.75 3.15326 21.5129 2.58097 21.091 2.15901C20.669 1.73705 20.0967 1.5 19.5 1.5ZM19.5 4.5C19.3517 4.5 19.2067 4.45601 19.0833 4.3736C18.96 4.29119 18.8639 4.17406 18.8071 4.03701C18.7503 3.89997 18.7355 3.74917 18.7644 3.60368C18.7933 3.4582 18.8648 3.32456 18.9697 3.21967C19.0746 3.11478 19.2082 3.04335 19.3537 3.01441C19.4992 2.98547 19.65 3.00032 19.787 3.05709C19.9241 3.11386 20.0412 3.20999 20.1236 3.33332C20.206 3.45666 20.25 3.60166 20.25 3.75C20.25 3.94891 20.171 4.13968 20.0303 4.28033C19.8897 4.42098 19.6989 4.5 19.5 4.5Z" fill="#757575" />
      <path d="M9 14.25H7.5V16.1895L5.46375 18.225C5.16352 18.0784 4.83409 18.0015 4.5 18C4.05499 18 3.61998 18.132 3.24997 18.3792C2.87996 18.6264 2.59157 18.9778 2.42127 19.389C2.25097 19.8001 2.20642 20.2525 2.29323 20.689C2.38005 21.1254 2.59434 21.5263 2.90901 21.841C3.22368 22.1557 3.62459 22.37 4.06105 22.4568C4.49751 22.5436 4.94991 22.499 5.36104 22.3287C5.77217 22.1584 6.12357 21.87 6.37081 21.5C6.61804 21.13 6.75 20.695 6.75 20.25C6.74832 19.9159 6.67142 19.5865 6.525 19.2863L9 16.8113V14.25ZM4.5 21C4.35167 21 4.20666 20.956 4.08332 20.8736C3.95999 20.7912 3.86386 20.6741 3.80709 20.537C3.75033 20.4 3.73547 20.2492 3.76441 20.1037C3.79335 19.9582 3.86478 19.8246 3.96967 19.7197C4.07456 19.6148 4.2082 19.5434 4.35368 19.5144C4.49917 19.4855 4.64997 19.5003 4.78701 19.5571C4.92406 19.6139 5.04119 19.71 5.1236 19.8333C5.20601 19.9567 5.25 20.1017 5.25 20.25C5.25 20.4489 5.17098 20.6397 5.03033 20.7803C4.88968 20.921 4.69891 21 4.5 21Z" fill="#757575" />
      <path d="M12.75 18.138V14.25H11.25V18.138C10.7496 18.3149 10.3278 18.6631 10.0593 19.1209C9.79072 19.5787 9.69265 20.1167 9.78241 20.6398C9.87216 21.1629 10.144 21.6375 10.5498 21.9796C10.9556 22.3217 11.4692 22.5093 12 22.5093C12.5308 22.5093 13.0444 22.3217 13.4503 21.9796C13.8561 21.6375 14.1278 21.1629 14.2176 20.6398C14.3074 20.1167 14.2093 19.5787 13.9407 19.1209C13.6722 18.6631 13.2504 18.3149 12.75 18.138ZM12 21C11.8517 21 11.7067 20.956 11.5833 20.8736C11.46 20.7912 11.3639 20.6741 11.3071 20.537C11.2503 20.4 11.2355 20.2492 11.2644 20.1037C11.2934 19.9582 11.3648 19.8246 11.4697 19.7197C11.5746 19.6148 11.7082 19.5433 11.8537 19.5144C11.9992 19.4855 12.15 19.5003 12.287 19.5571C12.4241 19.6139 12.5412 19.71 12.6236 19.8333C12.706 19.9567 12.75 20.1017 12.75 20.25C12.75 20.4489 12.671 20.6397 12.5303 20.7803C12.3897 20.921 12.1989 21 12 21Z" fill="#757575" />
      <path d="M19.5 18C19.1659 18.0015 18.8365 18.0784 18.5363 18.225L16.5 16.1903V14.25H15V16.8105L17.475 19.2855C17.3285 19.586 17.2516 19.9157 17.25 20.25C17.25 20.695 17.382 21.13 17.6292 21.5C17.8764 21.87 18.2278 22.1584 18.639 22.3287C19.0501 22.499 19.5025 22.5436 19.939 22.4568C20.3754 22.37 20.7763 22.1557 21.091 21.841C21.4057 21.5263 21.62 21.1254 21.7068 20.689C21.7936 20.2525 21.749 19.8001 21.5787 19.389C21.4084 18.9778 21.12 18.6264 20.75 18.3792C20.38 18.132 19.945 18 19.5 18ZM19.5 21C19.3517 21 19.2067 20.956 19.0833 20.8736C18.96 20.7912 18.8639 20.6741 18.8071 20.537C18.7503 20.4 18.7355 20.2492 18.7644 20.1037C18.7934 19.9582 18.8648 19.8246 18.9697 19.7197C19.0746 19.6148 19.2082 19.5434 19.3537 19.5144C19.4992 19.4855 19.65 19.5003 19.787 19.5571C19.9241 19.6139 20.0412 19.71 20.1236 19.8333C20.206 19.9567 20.25 20.1017 20.25 20.25C20.25 20.4489 20.171 20.6397 20.0303 20.7803C19.8897 20.921 19.6989 21 19.5 21Z" fill="#757575" />
    </svg>

  )
}

export default IconPoolManagement
