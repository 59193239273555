import * as React from "react";
import { SVGProps } from "react";

const IconCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.6666 0.666992H2.66663C1.9333 0.666992 1.3333 1.26699 1.3333 2.00033V11.3337H2.66663V2.00033H10.6666V0.666992ZM12.6666 3.33366H5.3333C4.59996 3.33366 3.99996 3.93366 3.99996 4.66699V14.0003C3.99996 14.7337 4.59996 15.3337 5.3333 15.3337H12.6666C13.4 15.3337 14 14.7337 14 14.0003V4.66699C14 3.93366 13.4 3.33366 12.6666 3.33366ZM12.6666 14.0003H5.3333V4.66699H12.6666V14.0003Z"
      fill="#212121"
    />
  </svg>
);

export default IconCopy;
