import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { useDispatch } from "react-redux";

import ModalConfirm from "components/ModalConfirm";
import { Button, Space, Spin } from "antd";
import { selectedLoading, selectedTokenDetail } from "redux/token/selector";
import NumberFormat from "components/NumberFormat";
import { useAppSelector } from "utils/customHooks";
import { handelGetTokenDetailRequest, tokenDeleteRequest } from "redux/token/slice";
import moment from "moment";
import { FORMAT_DATE_TIME, TYPE_OF_ANT_DESIGN } from "common/constant";
import TextComponent from "components/Text";
import { convertAddress } from "utils";
import showMessage from "components/Message";
import IconPop from "resources/images/IconPop";

const TokenDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [visibleConfirmDelete, setVisibleConfirmDelete] = useState(false);
  const [visibleDeleteToken, setVisibleDeleteToken] = useState(false);

  const { id } = useParams();

  const tokenDetail = useAppSelector(selectedTokenDetail);
  const loadingTokenDetail = useAppSelector(selectedLoading);
  const listChain = useAppSelector((state) => state.auth.chains);

  useEffect(() => {
    dispatch(handelGetTokenDetailRequest({ data: { id } }));
  }, [id]);

  const handleRemoveToken = () => {
    setVisibleConfirmDelete(true);
  };

  const handleDelete = () => {
    setVisibleDeleteToken(true);
    dispatch(
      tokenDeleteRequest({
        id,
        callbackSuccess: () => {
          setVisibleConfirmDelete(false);
          history.push("/token");
          setVisibleDeleteToken(false);
          showMessage(TYPE_OF_ANT_DESIGN.SUCCESS, "message.S8");
        },
        callbackError: () => {
          setVisibleConfirmDelete(false);
          setVisibleDeleteToken(false);
          showMessage(TYPE_OF_ANT_DESIGN.ERROR, "message.E21_1");
        },
      })
    );
  };

  const renderNetwork = useMemo(() => {
    const currentNetwork = listChain?.find((chain) => chain?.chainId === tokenDetail?.chainId);
    return currentNetwork ? (
      <>
        <img src={require(`resources/svg/${currentNetwork?.icon}`)} />
        &nbsp;
        <b className="mgb-0px">{currentNetwork?.chainName}</b>
      </>
    ) : (
      ""
    );
  }, [listChain, tokenDetail]);

  const handleGoBack = () => {
    history.push("/token");
  };

  return (
    <div>
      <Space size={16} className="mgb-20">
        <IconPop className="nft-detail__pop" onClick={handleGoBack} />
        <h2 className="nft-detail__title">Token Detail</h2>
      </Space>{" "}
      <div className="token-detail">
        {loadingTokenDetail && (
          <div className="loading-pool-detail">
            <Spin size="large" />
          </div>
        )}
        <p className="token-name">{tokenDetail?.name}</p>
        <div className="token-added">
          <p className="added-date">
            Added Date: <span className="content">{moment(tokenDetail?.createdAt).format(FORMAT_DATE_TIME)}</span>
          </p>
        </div>
        <div className="token-info">
          <p className="token-address">
            <span className="title">Network</span>
            <span className="content">{renderNetwork}</span>
          </p>
          <p className="token-address">
            <span className="title">Token Address</span>
            <span className="content">
              <TextComponent copyable={{ text: tokenDetail?.tokenId }}>
                {convertAddress(tokenDetail?.tokenId)}
              </TextComponent>
            </span>
          </p>
          <p className="token-address">
            <span className="title">Total Supply</span>
            <span className="content">
              <NumberFormat thousandSeparator value={tokenDetail?.totalSupply} displayType="text" suffix={""} />
            </span>
          </p>
          <p className="token-address">
            <span className="title">Token Price</span>
            <span className="content">
              <NumberFormat thousandSeparator value={tokenDetail?.exchangeRate} displayType="text" suffix={" $"} />
            </span>
          </p>
        </div>
        <Button onClick={handleRemoveToken} className="btn-remove" disabled={!tokenDetail?.isDeletable}>
          Remove
        </Button>

        <ModalConfirm
          visible={visibleConfirmDelete}
          content={`Do you want to remove ${tokenDetail?.name} from TTX?`}
          title="Remove Confirmation"
          onCancel={() => setVisibleConfirmDelete(false)}
          onConfirm={handleDelete}
          cancelText={"Cancel"}
          confirmText={"Confirm"}
          loadingConfirm={visibleDeleteToken}
        />
      </div>
    </div>
  );
};

export default TokenDetail;
