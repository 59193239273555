import { createSlice } from "@reduxjs/toolkit";
import { NETWORK_DEFAULT } from 'common/constant';

const initialState = {
  currentUser: {
    token: "",
    avatarUrl: "",
    email: "",
    username: "",
    walletAddress: "",
    isConnectWallet: false,
  },
  chains: [],
  network: NETWORK_DEFAULT,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginStart: (state, action) => ({
      ...state,
    }),
    loginSuccess: (state, action) => {
      state.currentUser = action.payload;
    },
    forgotPasswordStart: (state, action) => ({
      ...state,
    }),
    verifyOTPCodeStart: (state, action) => ({
      ...state,
    }),
    changePasswordStart: (state, action) => ({
      ...state,
    }),
    resetPasswordStart: (state, action) => ({
      ...state,
    }),
    logout: (state) => {
      state.currentUser = initialState.currentUser;
    },
    connectWalletStart: (state, action) => {
      const { payload } = action;
      return { ...state, walletAddress: payload.address };
    },

    disconnectWalletStart: (state, action) => {
      return { ...state, walletAddress: "", isConnectWallet: false };
    },

    checkExpireLinkStart: (state, action) => {
      return { ...state };
    },
    handleGetChainsRequest: (state, action) => {
      const { payload } = action;
      return { ...state };
    },
    handleGetChainsResponse: (state, action) => {
      const { payload } = action;
      return { ...state, chains: payload.data };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loginStart,
  loginSuccess,
  forgotPasswordStart,
  verifyOTPCodeStart,
  changePasswordStart,
  logout,
  resetPasswordStart,
  connectWalletStart,
  checkExpireLinkStart,
  handleGetChainsRequest,
  handleGetChainsResponse,
} = authSlice.actions;

export default authSlice.reducer;
