import React from "react";

const IconReduced = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 12L-9.53674e-07 0L12 0L6 12Z" fill="#A22727" />
    </svg>
  );
};

export default IconReduced;
