import Layout, { Content } from "antd/lib/layout/layout";
import React, { Suspense } from "react";
import { Redirect, Route, Switch, useLocation, withRouter } from "react-router-dom";

import { publicRoutes } from "Routes";
import { getToken } from "services/api";
import { useAppSelector } from "utils/customHooks";
import LoadingComponent from "components/Loading";
import FooterComponent from "components/Footer";
import classnames from "classnames";

export function PublicLayout() {
  const token = useAppSelector((state) => state.auth.currentUser.token);
  const location = useLocation();

  const isPasswordResetPage = location.pathname === "/reset";

  if (token) {
    getToken(token);

    return <Redirect to="/" />;
  }

  return (
    <Layout className={classnames("public layout-public", { "public-layout-2": isPasswordResetPage })}>
      <Content>
        <div className="container-sm">
          <div className="circle-1"></div>
          <div className="circle-2"></div>
          <div className="circle-3"></div>
          <Suspense fallback={<LoadingComponent />}>
            <Switch>
              {publicRoutes.map((route: any) => (
                <Route key={route.path} component={route.component} {...route} />
              ))}
            </Switch>
          </Suspense>
        </div>
      </Content>
      {/* <FooterComponent /> */}
    </Layout>
  );
}

export default React.memo(withRouter(PublicLayout));
