import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  loadingListUser: false,
  userDetail: {},
  loadingUserDetail: false,
  dashboard: {},
  loadingDashboard: false,
  visibleConnectWallet: false,
  activitylog: {},
  dataSearchUser: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    handelResetSearchUser: (state, action) => {
      return { ...state, dataSearchUser: {} };
    },
    connectWalletModalRequest: (state, action) => {
      const { visibleConnectWallet } = current(state);
      const { payload } = action;
      const { data } = payload;

      return {
        ...state,
        visibleConnectWallet: data === null ? !visibleConnectWallet : data,
      };
    },
    getListUserRequest: (state, action) => ({
      ...state,
      loadingListUser: true,
    }),

    getListUserResponse: (state, action) => {
      const { payload } = action;
      const { data, dataSearch } = payload;

      return { ...state, user: { ...data }, dataSearchUser: dataSearch, loadingListUser: false };
    },
    getUserDetailRequest: (state, action) => ({
      ...state,
      loadingUserDetail: true,
    }),

    getUserDetailResponse: (state, action) => {
      const { payload } = action;

      return { ...state, userDetail: { ...payload }, loadingUserDetail: false };
    },
    getDashboardRequest: (state, action) => ({
      ...state,
      loadingDashboard: true,
    }),

    getDashboardResponse: (state, action) => {
      const { payload } = action;

      return { ...state, dashboard: { ...payload }, loadingDashboard: false };
    },
    getActivitylogRequest: (state, action) => ({
      ...state,
    }),

    getActivitylogResponse: (state, action) => {
      const { payload } = action;

      return { ...state, activitylog: { ...payload } };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  handelResetSearchUser,
  getListUserResponse,
  getListUserRequest,
  getUserDetailRequest,
  getUserDetailResponse,
  getDashboardRequest,
  getDashboardResponse,
  connectWalletModalRequest,
  getActivitylogRequest,
  getActivitylogResponse,
} = userSlice.actions;

export default userSlice.reducer;
